import { Theme } from '@material-ui/core';

export const userDetailStyles = (theme: Theme) => {
  return {
    subtitle: {
      fontWeight: 700,
      marginBottom: '1.5rem',
    },
    textField: {
      '& .MuiInputBase-root': {
        borderRadius: '8px',
      },
    },
  };
};
