import { ReactElement } from 'react';
import { GridSize } from '@material-ui/core';

export interface ListItemInterface {
  value?: string;
  title: string;
  flex: GridSize;
  hideTitle: boolean;
  element?: ReactElement;
  align?: string;
}

export enum ListItemSize {
  SMALL = 'small',
  LARGE = 'large',
}

export interface ListItemProps {
  items: ListItemInterface[];
  size?: ListItemSize;
  icon?: ReactElement;
  shadow?: boolean;
  disableHover?: boolean;
  onClick?: () => void;
  endIcon?: ReactElement;
}
