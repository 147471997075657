import React, { FC, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useAppState } from '../../state/state.context';
import { ActionType } from '../../state/types/actions';
import Container from '../../components/Container/Container';
import DashboardCard from '../../components/Layout/Dashboard/DashboardCard/DashboardCard';
import {
  Typography,
  makeStyles,
  Theme,
  Box,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import ListItemTable from '../../components/ListItemTable/ListItemTable';
import {
  CurrentAlarmsHeader,
  Workorder,
  FinishedHeader,
  InProgressHeader,
} from '../Workorder/workorder.interface';
import { CurrentAlarmsData, CurrentRepairsData } from '../../shared/utility';
import ErrorIcon from '@material-ui/icons/Error';
import workOrderStyles from '../Workorder/workorderStyles';
import { colors } from '../../theme';
import {
  Repair,
  WorkorderApiResponse,
  AssignedWorkordersResponse,
} from '../../api/api.interface';
import AlarmsApi from '../../api/alarms';
import { WorkorderState } from './WorkordersList.interface';
import { Status } from '../../constants/constants';
import { SnackbarSeverity } from '../../components/Layout/Snackbar/snackbar.interface';

const api = new AlarmsApi();
const useClasses = makeStyles((theme: Theme) => workOrderStyles(theme));

const WorkordersList: FC = () => {
  const {
    state: { authToken },
    dispatch,
  } = useAppState();
  const classes = useClasses();
  const history = useHistory();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const { state } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Workorder[] | Repair[]>([]);
  const iconColor =
    state === WorkorderState.ALARMS
      ? colors.mainRed
      : state === WorkorderState.IN_PROGRESS
      ? colors.mainWarning
      : colors.mainSuccess;
  const clickData = state === WorkorderState.ALARMS ? 'furnaceId' : 'number';

  useEffect(() => {
    const title =
      state === WorkorderState.ALARMS
        ? 'Vse aktualne napake'
        : state === WorkorderState.IN_PROGRESS
        ? 'Vsi nalogi v reševanju'
        : 'Vsi zaključeni nalogi';
    dispatch({ type: ActionType.SET_TITLE, title: title });
  }, [dispatch, state]);

  useEffect(() => {
    if (authToken) {
      if (state === WorkorderState.ALARMS) {
        setLoading(true);
        api
          .fetchAlarms(-1, authToken)
          .then((response: WorkorderApiResponse) => {
            if (response.data) {
              setData(response.data);
            }
          })
          .catch((err) => {
            if (err.message === 'Unauthorized') {
              dispatch({ type: ActionType.LOGOUT });
            }
            dispatch({
              type: ActionType.SET_NOTIFICATION,
              message: err.message,
              severity: SnackbarSeverity.ERROR,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(true);
        api
          .fetchAssignedAlarms(-1, authToken)
          .then((data: AssignedWorkordersResponse) => {
            if (data.repairs) {
              setData(
                data.repairs.filter(
                  (repair: Repair) =>
                    (repair.status === Status.IN_PROGRESS &&
                      state === WorkorderState.IN_PROGRESS) ||
                    (repair.status === Status.DONE &&
                      state === WorkorderState.FINISHED)
                )
              );
            }
          })
          .catch((err) => {
            if (err.message === 'Unauthorized') {
              dispatch({ type: ActionType.LOGOUT });
            }
            dispatch({
              type: ActionType.SET_NOTIFICATION,
              message: err.message,
              severity: SnackbarSeverity.ERROR,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [authToken, dispatch, state]);

  const unatendedDetailsHandler = (deviceId: string) => {
    const workorder = (data as Workorder[]).find(
      (workorder: Workorder) => workorder.device === deviceId
    );
    history.push({
      pathname: `/workorders/unattended/details/${deviceId}`,
      state: { workorder },
    });
  };

  const detailsHandler = (repairNumber: string) => {
    const repair = (data as Repair[]).find(
      (repair: Repair) => repair.number === repairNumber
    );
    if (repair) {
      history.push({
        pathname: `/workorders/details/${repair.id}`,
        state: { repair },
      });
    }
  };

  return (
    <>
      <Container>
        <DashboardCard loading={false}>
          <Grid
            container
            direction="row"
            className={classes.subtitleWrapper}
            alignItems="center"
          >
            <Box mr={'.75rem'}>
              <Typography variant="h5" className={classes.subtitle}>
                {state === WorkorderState.ALARMS
                  ? 'Aktualne napake'
                  : state === WorkorderState.IN_PROGRESS
                  ? 'Nalogi v reševanju'
                  : 'Zaključeni nalogi'}
              </Typography>
            </Box>
            {!isDesktop && <ErrorIcon htmlColor={iconColor} />}
          </Grid>
          <ListItemTable
            headers={
              state === WorkorderState.ALARMS
                ? CurrentAlarmsHeader
                : state === WorkorderState.IN_PROGRESS
                ? InProgressHeader
                : FinishedHeader
            }
            data={
              state === WorkorderState.ALARMS
                ? CurrentAlarmsData(data as Workorder[])
                : CurrentRepairsData(data as Repair[])
            }
            icon={<ErrorIcon htmlColor={iconColor} />}
            onClick={
              state === WorkorderState.ALARMS
                ? unatendedDetailsHandler
                : detailsHandler
            }
            clickData={clickData}
            loading={loading}
          />
        </DashboardCard>
      </Container>
    </>
  );
};

export default WorkordersList;
