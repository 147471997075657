import React, { useEffect, useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { globalLoadingStyles } from './globalLoadingStyles';
import Loading from '../../components/Loading/Loading';
import { useAppState } from '../../state/state.context';

const useClasses = makeStyles((theme: Theme) => globalLoadingStyles(theme));

export default function GlobalLoading() {
  const classes = useClasses();
  const {
    state: { globalLoading },
  } = useAppState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(globalLoading);
  }, [globalLoading]);

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <Loading />
    </Backdrop>
  );
}
