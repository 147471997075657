import React, { FC, useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';
import snackbarStyles from './snackbarStyles';
import { useAppState } from '../../../state/state.context';

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme: Theme) => snackbarStyles(theme));

const SnackbarMessage: FC = () => {
  const {
    state: { notification },
  } = useAppState();
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (notification) {
      setOpen(true);
    }
  }, [notification]);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      {notification && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          className={classes.snackbarPosition}
        >
          <Alert
            onClose={handleClose}
            severity={notification?.severity}
            className={classes.alert}
          >
            {notification?.message}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default SnackbarMessage;
