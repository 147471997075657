import { AlarmToAssign } from '../workorder.interface';

export enum WorkOrderTypes {
  PRVI_ZAGON = 'prvi-zagon',
  NALOG_IZ_NAPAKE = 'nalog-iz-napake',
  REDNI_LETNI_SERVIS = 'redni-letni-servis',
  SERVIS_IZVEN_GARANCIJE = 'servis-izven-garancije',
  REKLAMACIJA = 'reklamacija',
  DODATNI_NALOG = 'dodatni-nalog',
}

export const typeValues = {
  [WorkOrderTypes.PRVI_ZAGON]: 'Prvi zagon',
  [WorkOrderTypes.NALOG_IZ_NAPAKE]: 'Nalog iz napake',
  [WorkOrderTypes.REDNI_LETNI_SERVIS]: 'Redni letni servis',
  [WorkOrderTypes.SERVIS_IZVEN_GARANCIJE]: 'Servis izven garancije',
  [WorkOrderTypes.REKLAMACIJA]: 'Reklamacija',
  [WorkOrderTypes.DODATNI_NALOG]: 'Dodatni nalog',
};

export interface CreateWorkorderBody {
  type: string;
  repairman: string;
  deviceId: string;
  receivedTimeStr: string;
  note: string;
  alarms?: AlarmToAssign[];
}
