import React, { FC } from 'react';
import {
  makeStyles,
  Theme,
  Grid,
  useMediaQuery,
  Typography,
  GridSize,
  GridJustification,
  Box,
} from '@material-ui/core';
import { listItemStyles } from './listItemStyles';
import {
  ListItemProps,
  ListItemInterface,
  ListItemSize,
} from './ListItem.interface';
import classNames from 'classnames';

const useClasses = makeStyles((theme: Theme) => listItemStyles(theme));

const ListItem: FC<ListItemProps> = ({
  items,
  icon,
  onClick,
  size = ListItemSize.LARGE,
  shadow = false,
  disableHover = false,
  endIcon,
}) => {
  const classes = useClasses();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Grid
      container
      direction="row"
      className={classNames({
        [classes.itemWrapper]: size === ListItemSize.LARGE,
        [classes.itemWrapperSmall]: size === ListItemSize.SMALL,
        [classes.smallHover]: size === ListItemSize.SMALL,
        [classes.itemShadow]: shadow,
        [classes.disableHover]: disableHover,
      })}
      onClick={onClick ?? undefined}
      wrap="nowrap"
    >
      <Grid container direction="row">
        {isDesktop && icon && (
          <Grid item xs={1} className={classes.value}>
            {icon}
          </Grid>
        )}
        <Grid item xs={12} md={icon ? 11 : 12} className={classes.value}>
          <Grid container direction="row" alignItems="center">
            {items.map((item: ListItemInterface, i: number) => (
              <Grid item xs={12} md={item.flex as GridSize} key={i}>
                <Grid
                  container
                  direction="row"
                  {...(item.align &&
                    (isDesktop || item.element) && {
                      justify: item.align as GridJustification,
                    })}
                  alignItems="center"
                >
                  {item.element || (
                    <>
                      {(!isDesktop || !item.hideTitle) && (
                        <Typography
                          variant="body1"
                          className={classes.itemHeader}
                        >
                          {`${item.title}:`}
                        </Typography>
                      )}
                      <Typography variant="body1" className={classes.text}>
                        {item.value || '/'}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      {endIcon && (
        <Box display="flex" alignItems="center">
          {endIcon}
        </Box>
      )}
    </Grid>
  );
};

export default ListItem;
