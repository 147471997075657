import React, { useState, useEffect, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { ActionType } from '../../../state/types/actions';
import { useAppState } from '../../../state/state.context';
import { SelectFurnaceProps } from './SelectFurnace.interface';
import { FurnaceItem, FurnacesApiResponse } from '../../../api/api.interface';
import FurnacesApi from '../../../api/furnaces';
import SelectDropdown from '../../SelectDropdown/SelectDropdown';
import {
  DropdownSelect,
  SelectType,
} from '../../SelectDropdown/SelectDropdown.interface';
import { SnackbarSeverity } from '../../Layout/Snackbar/snackbar.interface';

const api = new FurnacesApi();

const SelectFurnace: React.FC<SelectFurnaceProps> = ({
  selectFurnace,
  size,
  withoutOwner = false,
  withoutUser = false,
  required = true,
  disabled = false,
  ownerRequired = false,
  userRequired = false,
  refreshInput,
}) => {
  const {
    state: { authToken },
    dispatch,
  } = useAppState();

  const [furnaces, setFurnaces] = useState<FurnaceItem[]>([]);
  const [loading, setLoading] = useState(false);

  const filterFurnace = useCallback(
    (furnace: FurnaceItem): boolean => {
      if (withoutOwner) {
        return !furnace.owner;
      } else if (withoutUser) {
        return !furnace.renter;
      } else if (ownerRequired) {
        return !!furnace.owner;
      } else if (userRequired) {
        return !!furnace.renter;
      } else {
        return true;
      }
    },
    [withoutOwner, withoutUser, userRequired, ownerRequired]
  );

  useEffect(() => {
    if (authToken) {
      setLoading(true);
      api
        .fetchFurnaces(authToken, { limit: -1 })
        .then((data: FurnacesApiResponse) => {
          setFurnaces(
            data.furnaces.filter((furnace: FurnaceItem) =>
              filterFurnace(furnace)
            )
          );
        })
        .catch((err) => {
          setFurnaces([]);
          if (err.message === 'Unauthorized') {
            dispatch({ type: ActionType.LOGOUT });
          } else {
            dispatch({
              type: ActionType.SET_NOTIFICATION,
              message: err.message,
              severity: SnackbarSeverity.ERROR,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [authToken, dispatch, filterFurnace]);

  return (
    <>
      <Grid container direction="column" justify="space-between">
        <SelectDropdown
          selectDropdown={
            selectFurnace as (select: DropdownSelect | null) => void
          }
          placeholder="Izberi peč"
          focusPlaceholder="Išči po id-ju, tipu ali uporabniku"
          type={SelectType.FURNACE}
          items={furnaces}
          size={size}
          required={required}
          loading={loading}
          disabled={disabled}
          refreshInput={refreshInput}
        />
      </Grid>
    </>
  );
};

export default SelectFurnace;
