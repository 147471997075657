import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { PageHeaderProps } from './header.interface';
import { headerStyles } from './headerStyles';

const useClasses = makeStyles((theme: Theme) => headerStyles(theme));

const PageHeader: React.FC<PageHeaderProps> = ({ title, description }) => {
  const classes = useClasses();
  return (
    <Grid container direction="row" alignItems="flex-end">
      <Grid item>
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1">{description}</Typography>
      </Grid>
    </Grid>
  );
};

export default PageHeader;
