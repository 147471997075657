import { Alarm } from '../../api/api.interface';
import { GridSize } from '@material-ui/core';

export interface AlarmState extends Alarm {
  deviceId: string;
}

export interface AlarmToAssign {
  id: string;
  name: string;
}

export interface AssignAlarm {
  note: string;
  alarms: AlarmToAssign[];
  email: string;
  receivedTimeStr: string;
  deviceId: string;
}

export interface Workorder {
  alarms: Alarm[];
  device: string;
  contactPerson: string;
  furnaceAddress: string;
  furnaceRegion?: string;
}

export interface ListHeader {
  label: string;
  flex: GridSize;
  value: string;
}

export const CurrentAlarmsHeader: ListHeader[] = [
  {
    label: 'ID peči',
    flex: 2,
    value: 'furnaceId',
  },
  {
    label: 'Št. napak',
    flex: 2,
    value: 'alarmsCount',
  },
  {
    label: 'Kontaktna oseba',
    flex: 2,
    value: 'contactPerson',
  },
];

export const InProgressHeader: ListHeader[] = [
  {
    label: 'ID naloga',
    flex: 3,
    value: 'number',
  },
  {
    label: 'Oddano v reševanje',
    flex: 3,
    value: 'date',
  },
  {
    label: 'ID peči',
    flex: 3,
    value: 'furnaceId',
  },
  {
    label: 'Dodeljen serviser',
    flex: 3,
    value: 'repairman',
  },
];

export const FinishedHeader: ListHeader[] = [
  {
    label: 'ID naloga',
    flex: 3,
    value: 'number',
  },
  {
    label: 'Nalog zaključen',
    flex: 3,
    value: 'fixedDate',
  },
  {
    label: 'ID peči',
    flex: 3,
    value: 'furnaceId',
  },
  {
    label: 'Dodeljen serviser',
    flex: 3,
    value: 'repairman',
  },
];
