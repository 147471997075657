import { Theme } from '@material-ui/core';

export const paginationStyles = (theme: Theme) => {
  return {
    pagination: {
      margin: '2rem auto 0 auto',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '2rem',
      },
    },
    page: {
      justifyContent: 'center' as const,
    },
  };
};
