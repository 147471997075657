export enum SnackbarSeverity {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export interface Snackbar {
  message: string;
  severity: SnackbarSeverity;
}
