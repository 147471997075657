import { Theme } from '@material-ui/core/styles';

const drawerStyles = (theme: Theme) => {
  return {
    toolbar: theme.mixins.toolbar,
    list: {
      flexGrow: 1,
      display: 'flex' as const,
      flexDirection: 'column' as const,
    },
    listItem: {
      '&:last-child': {
        marginTop: 'auto',
      },
    },
    subListItem: {
      paddingLeft: '2.5rem',
    },
  };
};

export default drawerStyles;
