import { Theme } from '@material-ui/core';

export const containerStyles = (theme: Theme) => {
  return {
    container: {
      maxWidth: theme.breakpoints.values.lg,
    },
    wrapper: {
      marginTop: '2rem',
      [theme.breakpoints.down('xs')]: {
        marginTop: '1rem',
      },
    },
  };
};
