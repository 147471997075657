import { Theme } from '@material-ui/core';

const footNoteStyles = (theme: Theme) => {
  return {
    textarea: {
      width: '100%',
      wordBreak: 'break-word' as const,
      backgroundColor: '#2D2D35',
      padding: '1em',
      borderRadius: '8px',
      fontSize: '16px',
      marginTop: '.5em',
    },
    label: {
      color: 'rgba(250, 250, 250, 0.9)',
      opacity: 0.5,
      marginBottom: '.5em',
      marginRight: '.5em'
    },
  };
};

export default footNoteStyles;
