import { Theme } from '@material-ui/core';
import sharedStyles from '../../shared/styles';

export const selectDropdownStyles = (theme: Theme) => {
  const common = sharedStyles(theme);
  return {
    redWarning: common.redWarning,
    yellowWarning: common.yellowWarning,
    greenSuccess: common.greenSuccess,
    option: {
      fontSize: '1rem',
      '& > span': {
        marginRight: '.75rem',
        fontSize: '1.25rem',
      },
    },
    root: {
      fontSize: '1rem',
      '& .MuiInputBase-root': {
        borderRadius: '8px',
      },
    },
    underline: {
      '&:before': {
        borderBottom: 'none',
      },
      '&:after': {
        borderBottom: 'none',
      },
    },
  };
};
