import React, { FC, useState } from 'react';
import { UserFurnacesProps } from './UserFurnaces.interface';
import {
  makeStyles,
  Theme,
  Box,
  Typography,
  Button,
  Grid,
} from '@material-ui/core';
import { userFurnacesStyles } from './userFurnacesStyles';
import DashboardCard from '../../Layout/Dashboard/DashboardCard/DashboardCard';
import { UserType, FurnaceItem } from '../../../api/api.interface';
import { ListItemInterface } from '../../ListItem/ListItem.interface';
import { ReactComponent as Flame } from '../../../assets/flame.svg';
import ListItem from '../../ListItem/ListItem';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { colors } from '../../../theme';
import { useHistory } from 'react-router';
import SelectFurnace from '../SelectFurnace/SelectFurnace';
import { isEmpty } from 'lodash';
import { DropdownSize } from '../../SelectDropdown/SelectDropdown.interface';
import { v4 as uuidv4 } from 'uuid';

const useClasses = makeStyles((theme: Theme) => userFurnacesStyles(theme));

const UserFurnaces: FC<UserFurnacesProps> = ({
  furnaces,
  userType,
  setFurnaces,
}) => {
  const classes = useClasses();
  const history = useHistory();
  const isRegular = userType === UserType.REGULAR;

  const [selectedFurnace, setSelectedFurnace] = useState({} as FurnaceItem);
  const [refreshInputId, setRefreshInputId] = useState(uuidv4());

  const handleSelectFurnace = (furnace: FurnaceItem | null) => {
    setSelectedFurnace(furnace ?? ({} as FurnaceItem));
  };

  const removeFurnace = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ) => {
    e.stopPropagation();
    const newFurnaces = furnaces.filter(
      (furnace: FurnaceItem) => furnace.furnaceId !== id
    );
    setFurnaces(newFurnaces);
    setRefreshInputId(uuidv4());
  };

  const addFurnace = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    furnace: FurnaceItem
  ) => {
    e.stopPropagation();
    const sameFurnace = furnaces.some(
      (fur: FurnaceItem) => fur.furnaceId === furnace.furnaceId
    );
    if (!sameFurnace) {
      setFurnaces([...furnaces, furnace]);
      setSelectedFurnace({} as FurnaceItem);
      setRefreshInputId(uuidv4());
    }
  };

  const replaceFurnace = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    furnace: FurnaceItem
  ) => {
    e.stopPropagation();
    setFurnaces([furnace]);
    setSelectedFurnace({} as FurnaceItem);
    setRefreshInputId(uuidv4());
  };

  const furnaceItem = (furnace: FurnaceItem, key: number) => {
    const items: ListItemInterface[] = [
      {
        value: furnace.furnaceId,
        title: 'ID peči',
        flex: 3,
        hideTitle: false,
      },
      {
        value: furnace.name,
        title: 'Tip peči',
        flex: 3,
        hideTitle: false,
      },
      {
        value: isRegular
          ? furnace.owner ?? ''
          : furnace.renter ?? furnace.owner,
        title: isRegular ? 'Investitor' : 'Uporabnik',
        flex: 3,
        hideTitle: false,
      },
      {
        value: '',
        title: '',
        flex: 3,
        hideTitle: false,
        align: 'flex-end',
        element: (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            disableRipple
            className={classes.deleteButton}
            onClick={(e) => removeFurnace(e, furnace.furnaceId)}
            endIcon={<HighlightOffIcon htmlColor={colors.mainRed} />}
          >
            Odstrani
          </Button>
        ),
      },
    ];
    return (
      <ListItem
        onClick={() => history.push(`/furnace/${furnace.furnaceId}`)}
        shadow
        items={items}
        icon={<Flame />}
        key={key}
      />
    );
  };

  return (
    <DashboardCard loading={false}>
      <Typography variant="h6" className={classes.subtitle}>
        {`Podatki o ${isRegular ? 'peči' : 'pečeh'}`}
      </Typography>
      <Grid container direction="column">
        {furnaces?.map((furnace: FurnaceItem, i: number) =>
          furnaceItem(furnace, i)
        )}
      </Grid>
      <Box mt={'1.5rem'}>
        <Typography variant="h6" className={classes.subtitle}>
          {`${isRegular && furnaces.length ? 'Zamenjaj' : 'Dodaj'} peč`}
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={5}>
            <SelectFurnace
              {...(!isRegular ? { withoutOwner: true } : { withoutUser: true })}
              size={DropdownSize.SMALL}
              selectFurnace={handleSelectFurnace}
              refreshInput={refreshInputId}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              size="medium"
              onClick={(e) =>
                isRegular
                  ? replaceFurnace(e, selectedFurnace)
                  : addFurnace(e, selectedFurnace)
              }
              disabled={isEmpty(selectedFurnace)}
            >
              {`${isRegular && furnaces.length ? 'Zamenjaj' : 'Dodaj'}`}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </DashboardCard>
  );
};

export default UserFurnaces;
