import BaseApi from './base';
import {
  Method,
  FurnacesApiResponse,
  FurnaceDetailResponse,
  FurnaceType,
  PaginationParams,
  AddFurnaceBody,
  FurnacePower,
  UpdateFurnaceBody,
  UpdateFurnaceResponse,
} from './api.interface';
import { cleanQueryParams } from '../shared/utility';
import { CreateWorkorderBody } from '../containers/Workorder/CreateWorkOrder/CreateWorkOrder.interface';

export default class FurnacesApi extends BaseApi {
  async fetchFurnaces(
    token: string,
    pagination: PaginationParams
  ): Promise<FurnacesApiResponse> {
    return this.makeRequest(
      Method.GET,
      `furnace/all${cleanQueryParams(pagination)}`,
      null,
      {
        Authorization: token ? `Bearer ${token}` : '',
      }
    );
  }

  async fetchFurnace(
    token: string,
    id: string
  ): Promise<FurnaceDetailResponse> {
    return this.makeRequest(Method.GET, `furnace/full/${id}`, null, {
      Authorization: token ? `Bearer ${token}` : '',
    });
  }

  async fetchFurnaceTypes(token: string): Promise<FurnaceType[]> {
    return this.makeRequest(Method.GET, `furnace/types`, null, {
      Authorization: token ? `Bearer ${token}` : '',
    });
  }

  async addFurnace(body: AddFurnaceBody, token: string): Promise<void> {
    return this.makeRequest(Method.POST, `furnace`, JSON.stringify(body), {
      Authorization: token ? `Bearer ${token}` : '',
    });
  }

  async setFurnacePower(
    furnaceId: string,
    mode: FurnacePower,
    token: string
  ): Promise<void> {
    return this.makeRequest(
      Method.POST,
      `furnace/power/${mode}/${furnaceId}`,
      null,
      {
        Authorization: token ? `Bearer ${token}` : '',
      }
    );
  }

  async assignRepair(body: CreateWorkorderBody, token: string): Promise<void> {
    return this.makeRequest(
      Method.POST,
      `furnace/repairs/assign`,
      JSON.stringify(body),
      {
        Authorization: token ? `Bearer ${token}` : '',
      }
    );
  }

  async updateFurnace(
    furnaceId: string,
    body: UpdateFurnaceBody,
    token: string
  ): Promise<UpdateFurnaceResponse> {
    return this.makeRequest(
      Method.PUT,
      `furnace/update/${furnaceId}`,
      JSON.stringify(body),
      {
        Authorization: token ? `Bearer ${token}` : '',
      }
    );
  }
}
