import { Theme } from '@material-ui/core';
import { colors } from '../../theme';

export const labeledCheckboxStyles = (theme: Theme) => {
  return {
    wrapper: {
      backgroundColor: colors.dark,
      padding: '.5rem 1rem',
      borderRadius: '16px',
    },
  };
};
