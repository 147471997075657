import React, { FormEvent, useCallback, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import Footer from '../../components/Layout/Footer/Footer';
import { capitalize } from '../../shared/utility';
import loginStyles from './loginStyles';
import { useAppState } from '../../state/state.context';
import { useApiState } from '../../hooks/useApiState';
import AuthApi from '../../api/loginOrRegister';
import { useControllerInput } from '../../hooks/useControllerInput';
import { ActionType } from '../../state/types/actions';
import Loading from '../../components/Loading/Loading';
import ErrorMessage from '../../components/Layout/LoginErrorMessage/Error';

const api = new AuthApi();

const useClasses = makeStyles((theme: Theme) => loginStyles(theme));

const Login: React.FC = () => {
  const classes = useClasses();
  const history = useHistory();
  const {
    state: { isLoggedIn },
    dispatch,
  } = useAppState();
  const { loading, setLoading, error, setError } = useApiState();
  const [email, setEmail] = useControllerInput();
  const [password, setPassword] = useControllerInput();
  const [firstName, setFirstName] = useControllerInput();
  const [lastName, setLastName] = useControllerInput();
  const [isRegister, setIsRegister] = useState<boolean>(false);

  const onLoginAttempt = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      setError('');
      try {
        let token: string;
        if (isRegister) {
          token = await api.loginOrRegister(
            {
              firstName,
              lastName,
              email,
              password,
            },
            isRegister
          );
        } else {
          token = await api.loginOrRegister({ email, password }, isRegister);
        }
        dispatch({ type: ActionType.LOGIN, jwtToken: token });
        history.push('/workorders');
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    },
    [
      firstName,
      lastName,
      isRegister,
      email,
      password,
      setError,
      setLoading,
      dispatch,
      history,
    ]
  );

  if (isLoggedIn) {
    return <Redirect to="/workorders" />;
  }

  const label = isRegister ? 'Registracija' : 'Prijava';

  const register = () => (
    <>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="firstName"
        name="firstName"
        label={capitalize('ime')}
        value={firstName}
        autoFocus
        onChange={setFirstName}
      />
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="lastName"
        name="lastName"
        label={capitalize('priimek')}
        value={lastName}
        onChange={setLastName}
      />
    </>
  );

  return (
    <Grid container direction="column" className={classes.main}>
      <Grid item className={classes.loginBox}>
        <Grid container className={classes.loginContainer}>
          <Grid item className={classes.paper}>
            <Box className={classes.opacity} />
            <Box fontSize={60} className={classes.zIndex}>
              {label}
            </Box>
            <Box mt={5} fontWeight="fontWeightLight" className={classes.zIndex}>
              <Box
                className={classes.link}
                onClick={() => setIsRegister((prevState) => !prevState)}
              >
                {isRegister
                  ? 'Imate račun? Prijava'
                  : 'Nimate računa? Registracija'}
              </Box>
            </Box>
            <form className={classes.form} onSubmit={onLoginAttempt}>
              {isRegister && register()}
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                name="email"
                label={capitalize('email')}
                type="email"
                autoFocus={!isRegister}
                autoComplete="email"
                value={email}
                onChange={setEmail}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="password"
                name="password"
                label={capitalize('geslo')}
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={setPassword}
              />
              {error && <ErrorMessage message={error} />}
              {loading && <Loading />}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={loading}
                className={classes.submit}
              >
                {label}
              </Button>
              <Box mt={2}>
                <Divider />
              </Box>
            </form>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.footer}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default Login;
