import { Theme } from '@material-ui/core';
import { colors } from '../../../../theme';

const finishedWordorderDetailsStyles = (theme: Theme) => {
  return {
    label: {
      color: colors.lightGray,
      opacity: 0.6,
      marginBottom: '.5em',
      marginRight: '.5em',
      fontSize: '1rem',
    },
    data: {
      color: colors.mainText,
      marginBottom: '.5em',
      fontSize: '1rem',
    },
    dataMargin: {
      margin: '.5em 1em .5em 0',
    },
    sectionMargin: {
      margin: '2em 0 0 0',
    },
    subtitle: {
      fontWeight: 700,
      marginBottom: '1rem',
    },
    text: {
      color: colors.lightGray,
    },
    alarmName: {
      color: colors.lightGray,
      opacity: 0.6,
    },
    buttonWrapper: {
      paddingTop: '2rem',
    },
  };
};

export default finishedWordorderDetailsStyles;
