import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { ActionType } from '../../../state/types/actions';
import { useAppState } from '../../../state/state.context';
import { SelectFurnaceTypeProps } from './SelectFurnaceType.interface';
import { FurnaceType } from '../../../api/api.interface';
import FurnacesApi from '../../../api/furnaces';
import SelectDropdown from '../../SelectDropdown/SelectDropdown';
import {
  DropdownSelect,
  SelectType,
} from '../../SelectDropdown/SelectDropdown.interface';
import { SnackbarSeverity } from '../../Layout/Snackbar/snackbar.interface';

const api = new FurnacesApi();

const SelectFurnaceType: React.FC<SelectFurnaceTypeProps> = ({
  selectFurnaceType,
  required = true,
  disabled = false,
  refreshInput,
}) => {
  const {
    state: { authToken },
    dispatch,
  } = useAppState();

  const [furnaceTypes, setFurnaceTypes] = useState<FurnaceType[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (authToken) {
      setLoading(true);
      api
        .fetchFurnaceTypes(authToken)
        .then((data: FurnaceType[]) => {
          setFurnaceTypes(data);
        })
        .catch((err) => {
          setFurnaceTypes([]);
          if (err.message === 'Unauthorized') {
            dispatch({ type: ActionType.LOGOUT });
          } else {
            dispatch({
              type: ActionType.SET_NOTIFICATION,
              message: err.message,
              severity: SnackbarSeverity.ERROR,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [authToken, dispatch]);

  return (
    <>
      <Grid container direction="column" justify="space-between">
        <SelectDropdown
          selectDropdown={
            selectFurnaceType as (select: DropdownSelect | null) => void
          }
          placeholder="Izberi tip peči"
          type={SelectType.FURNACE_TYPE}
          items={furnaceTypes}
          required={required}
          loading={loading}
          disabled={disabled}
          refreshInput={refreshInput}
        />
      </Grid>
    </>
  );
};

export default SelectFurnaceType;
