import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { ActionType } from '../../state/types/actions';
import { useAppState } from '../../state/state.context';
import AlarmsApi from '../../api/alarms';
import { SelectAlarmProps } from './SelectAlarm.interface';
import { WorkorderApiResponse } from '../../api/api.interface';
import { Workorder } from '../../containers/Workorder/workorder.interface';
import SelectDropdown from '../SelectDropdown/SelectDropdown';
import {
  DropdownSelect,
  SelectType,
} from '../SelectDropdown/SelectDropdown.interface';
import { SnackbarSeverity } from '../Layout/Snackbar/snackbar.interface';

const api = new AlarmsApi();

const SelectAlarm: React.FC<SelectAlarmProps> = ({
  selectAlarm,
  required = true,
  disabled = false,
}) => {
  const {
    state: { authToken },
    dispatch,
  } = useAppState();

  const [alarms, setAlarms] = useState<Workorder[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (authToken) {
      setLoading(true);
      api
        .fetchAlarms(-1, authToken)
        .then((response: WorkorderApiResponse) => {
          setAlarms(response.data);
        })
        .catch((err) => {
          setAlarms([]);
          if (err.message === 'Unauthorized') {
            dispatch({ type: ActionType.LOGOUT });
          } else {
            dispatch({
              type: ActionType.SET_NOTIFICATION,
              message: err.message,
              severity: SnackbarSeverity.ERROR,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [authToken, dispatch]);

  return (
    <>
      <Grid container direction="column" justify="space-between">
        <SelectDropdown
          selectDropdown={
            selectAlarm as (select: DropdownSelect | null) => void
          }
          placeholder="Izberi napako"
          type={SelectType.WORKORDER}
          items={alarms}
          required={required}
          loading={loading}
          disabled={disabled}
        />
      </Grid>
    </>
  );
};

export default SelectAlarm;
