import { Theme } from '@material-ui/core';

const drawerWidth = 240;

const layoutStyles = (theme: Theme) => {
  return {
    root: {
      display: 'flex' as const,
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      boxShadow: 'none',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none' as const,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      borderRight: 'none',
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      backgroundColor: theme.palette.background.default,
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
    },
    contentLoggedIn: {
      flexGrow: 1,
      margin: '40px',
      [theme.breakpoints.down('xs')]: {
        margin: '.5rem 1rem 1rem',
      },
    },
  };
};

export default layoutStyles;
