import BaseApi from './base';
import { Method, UserType, LoginBody, RegisterBody } from './api.interface';

export default class AuthApi extends BaseApi {
  async loginOrRegister(
    body: LoginBody | RegisterBody,
    isRegister: boolean,
    isRepairman?: boolean
  ): Promise<string> {
    const response = await this.makeRequest(
      Method.POST,
      isRegister ? 'auth/register' : 'auth/login',
      JSON.stringify({
        ...body,
        type: isRepairman ? UserType.REPAIRMAN : UserType.ADMIN,
        lang: /^sl\b/.test(navigator.language) ? 'sl' : 'en',
      })
    );
    const { token } = response;
    return token;
  }
}
