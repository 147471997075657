import React, {
  FC,
  useEffect,
  useState,
  ChangeEvent,
  ReactElement,
} from 'react';
import { useAppState } from '../../../state/state.context';
import { ActionType } from '../../../state/types/actions';
import {
  FurnaceType,
  InvestorListItem,
  UserType,
  RegularListItem,
  Repairman,
} from '../../../api/api.interface';
import {
  Box,
  Grid,
  makeStyles,
  Theme,
  Typography,
  TextField,
  useMediaQuery,
  Button,
} from '@material-ui/core';
import { addFurnaceStyles } from './addFurnaceStyles';
import DashboardCard from '../../../components/Layout/Dashboard/DashboardCard/DashboardCard';
import Container from '../../../components/Container/Container';
import SelectFurnaceType from '../../../components/Furnace/SelectFurnaceType/SelectFurnaceType';
import SelectDropdown from '../../../components/SelectDropdown/SelectDropdown';
import {
  SelectType,
  DropdownSelect,
} from '../../../components/SelectDropdown/SelectDropdown.interface';
import { repairmanRegions } from '../../../components/Repairman/RepairmanDetails/repairmanDetails.interface';
import SelectUser from '../../../components/Furnace/SelectUser/SelectUser';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import SelectRepairman from '../../../components/SelectRepairman/SelectRepairman';
import FurnacesApi from '../../../api/furnaces';
import { handleSelectChange } from '../../../shared/utility';
import { v4 as uuidv4 } from 'uuid';
import sharedStyles from '../../../shared/styles';
import { SnackbarSeverity } from '../../../components/Layout/Snackbar/snackbar.interface';

const useClasses = makeStyles((theme: Theme) => {
  return { ...addFurnaceStyles(theme), ...sharedStyles(theme) };
});
const api = new FurnacesApi();

const AddFurnace: FC = () => {
  const {
    state: { authToken, globalLoading },
    dispatch,
  } = useAppState();
  const classes = useClasses();
  const history = useHistory();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [furnaceType, setFurnaceType] = useState({} as FurnaceType);
  const [furnaceId, setFurnaceId] = useState('');
  const [furnaceDate, setFurnaceDate] = useState('');
  const [region, setRegion] = useState('');
  const [repairman, setRepairman] = useState({} as Repairman);
  const [user, setUser] = useState({} as RegularListItem);
  const [investor, setInvestor] = useState({} as InvestorListItem);
  const [refreshId, setRefreshId] = useState(uuidv4());

  useEffect(() => {
    dispatch({ type: ActionType.SET_TITLE, title: 'Dodaj novo peč' });
  }, [dispatch]);

  const handleTextFieldChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    setValue: (text: string) => void
  ) => {
    setValue(e.target.value);
  };

  const textField = (
    label: string,
    value: string,
    type: string,
    setValue: (text: string) => void
  ) => (
    <Grid item xs={12} md={4}>
      <TextField
        key={label}
        variant="outlined"
        fullWidth
        required
        type={type}
        label={label}
        value={value}
        {...(type === 'date' && { InputLabelProps: { shrink: true } })}
        className={classes.textField}
        onChange={(e) => handleTextFieldChange(e, setValue)}
      />
    </Grid>
  );

  const disableSave =
    isEmpty(furnaceType) ||
    !furnaceId ||
    !furnaceDate ||
    !region ||
    globalLoading;

  const selectWithTitle = (title: string, select: ReactElement) => (
    <DashboardCard loading={false}>
      <Typography variant="h6" className={classes.subtitle}>
        {title}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Box m={'.5rem 0'}>{select}</Box>
        </Grid>
      </Grid>
    </DashboardCard>
  );

  const resetState = () => {
    setFurnaceType({} as FurnaceType);
    setFurnaceId('');
    setFurnaceDate('');
    setRegion('');
    setRepairman({} as Repairman);
    setUser({} as RegularListItem);
    setInvestor({} as InvestorListItem);
    setRefreshId(uuidv4());
  };

  const addFurnace = () => {
    if (authToken) {
      dispatch({ type: ActionType.START_LOADING });
      api
        .addFurnace(
          {
            type: furnaceType.type,
            id: furnaceId,
            manufactureDate: furnaceDate,
            region,
            ...(!isEmpty(user) ? { renter: user.email } : {}),
            ...(!isEmpty(investor) ? { owner: investor.email } : {}),
            ...(!isEmpty(repairman) ? { repairman: repairman.email } : {}),
          },
          authToken
        )
        .then(() => {
          resetState();
          dispatch({
            type: ActionType.SET_NOTIFICATION,
            message: 'Peč uspešno dodana',
            severity: SnackbarSeverity.SUCCESS,
          });
        })
        .catch((err) => {
          if (err.message === 'Unauthorized') {
            dispatch({ type: ActionType.LOGOUT });
          } else {
            dispatch({
              type: ActionType.SET_NOTIFICATION,
              message: err.message,
              severity: SnackbarSeverity.ERROR,
            });
          }
        })
        .finally(() => {
          dispatch({ type: ActionType.END_LOADING });
        });
    }
  };

  return (
    <>
      <Container>
        <DashboardCard loading={false}>
          <Typography variant="h6" className={classes.subtitle}>
            Podatki o peči
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <SelectFurnaceType
                selectFurnaceType={(select: DropdownSelect | null) =>
                  handleSelectChange(
                    select,
                    setFurnaceType as (value: DropdownSelect) => void,
                    SelectType.FURNACE_TYPE
                  )
                }
                disabled={globalLoading}
                refreshInput={refreshId}
              />
            </Grid>
            {isDesktop && <Grid item xs={12} md={7} />}
            {textField('ID peči', furnaceId, 'text', setFurnaceId)}
            {textField('Datum izdelave', furnaceDate, 'date', setFurnaceDate)}
            {isDesktop && <Grid item xs={12} md={4} />}
            <Grid item xs={12} md={5}>
              <SelectDropdown
                selectDropdown={(select: DropdownSelect | null) =>
                  handleSelectChange(
                    select,
                    setRegion as (value: DropdownSelect) => void,
                    SelectType.REGION
                  )
                }
                required
                placeholder="Izberi regijo"
                type={SelectType.REGION}
                items={repairmanRegions}
                disabled={globalLoading}
                refreshInput={refreshId}
              />
            </Grid>
          </Grid>
        </DashboardCard>
        {selectWithTitle(
          'Podatki o serviserju skrbniku',
          <SelectRepairman
            selectRepairman={(select: DropdownSelect | null) =>
              handleSelectChange(
                select,
                setRepairman as (value: DropdownSelect) => void,
                SelectType.REPAIRMAN
              )
            }
            hideTitle
            required={false}
            disabled={globalLoading}
            isCaretaker
          />
        )}
        {selectWithTitle(
          'Podatki o uporabniku',
          <SelectUser
            selectUser={(select: DropdownSelect | null) =>
              handleSelectChange(
                select,
                setUser as (value: DropdownSelect) => void,
                SelectType.USER
              )
            }
            userType={UserType.REGULAR}
            placeholder="Izberi uporabnika"
            disabled={globalLoading}
            required={false}
            withoutFurnace
            refreshInput={refreshId}
          />
        )}
        {selectWithTitle(
          'Podatki o investitorju',
          <SelectUser
            selectUser={(select: DropdownSelect | null) =>
              handleSelectChange(
                select,
                setInvestor as (value: DropdownSelect) => void,
                SelectType.INVESTOR
              )
            }
            userType={UserType.INVESTOR}
            placeholder="Izberi investitorja"
            disabled={globalLoading}
            required={false}
            refreshInput={refreshId}
          />
        )}
        <Box mt={'2.5rem'}>
          <Grid container spacing={2}>
            {isDesktop && <Grid item md={8} />}
            <Grid item xs={12} md={2}>
              <Button
                variant="contained"
                className={classes.whiteButton}
                fullWidth
                size="medium"
                disabled={globalLoading}
                onClick={() => history.goBack()}
              >
                Nazaj
              </Button>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                size="medium"
                disabled={disableSave}
                onClick={() => addFurnace()}
              >
                Shrani
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default AddFurnace;
