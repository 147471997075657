import React, { useEffect, useReducer } from 'react';

import { StateContext } from './state.context';
import { stateReducer } from './state.reducer';
import { ActionType } from './types/actions';

export const StateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(stateReducer, {
    isLoggedIn: !!localStorage.getItem('jwt'),
    title: '',
    subTitle: '',
    globalLoading: false,
    notification: null,
  });

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    if (token) {
      dispatch({ type: ActionType.LOGIN, jwtToken: token });
    } else {
      dispatch({ type: ActionType.LOGOUT });
    }
    return function cleanup() {};
  }, [dispatch]);

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
};
