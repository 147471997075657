import { REACT_APP_BASE_API_URL, REACT_APP_ENV } from '../constants/constants';
import { Header, Method, ErrorMessage } from './api.interface';

export default class BaseApi {
  protected async makeRequest(
    method: Method,
    path: string,
    body?: any,
    header?: Header
  ): Promise<any> {
    try {
      const response = await fetch(
        `${REACT_APP_BASE_API_URL}/${REACT_APP_ENV}/${path}`,
        {
          method,
          headers: {
            'content-type': 'application/json',
            ...header,
          },
          body,
        }
      );
      const json = await response.json();
      if (response.status === 200 || response.status === 201) {
        return json;
      }
      const { error, message } = json;
      throw error ?? message ?? ErrorMessage.GENERIC;
    } catch (e) {
      switch (e) {
        case 'User already exists':
          throw new Error(ErrorMessage.USER_EXISTS);
        case 'Furnace already exists':
          throw new Error(ErrorMessage.FURNACE_EXISTS);
        default:
          throw new Error(ErrorMessage.GENERIC);
      }
    }
  }

  protected async makeRequestForBinaryData(
    method: Method,
    path: string,
    header?: Header
  ): Promise<any> {
    try {
      const response = await fetch(
        `${REACT_APP_BASE_API_URL}/${REACT_APP_ENV}/${path}`,
        {
          method,
          headers: {
            'content-type': 'application/pdf',
            ...header,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        return await response.arrayBuffer();
      }
      const { error } = await response.json();
      throw error;
    } catch (e) {
      throw new Error(ErrorMessage.GENERIC);
    }
  }
}
