import { Theme } from '@material-ui/core';
import { colors } from '../../../theme';

export const furnaceRepairsStyles = (theme: Theme) => {
  return {
    divider: {
      opacity: 0.6,
      color: 'white',
    },
    repair: {
      padding: '.75rem 0 .75rem 1.5rem',
      wordBreak: 'break-all' as const,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '0rem',
      },
    },
    repairHeader: {
      padding: '.5rem 0 .5rem 1.5rem',
      color: colors.lightGray,
      opacity: 0.6,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '0rem',
        margin: '0',
      },
    },
    icon: {
      marginLeft: '.5rem',
    },
    wrapper: {
      '&:hover': {
        backgroundColor: colors.hoverBackground,
        cursor: 'pointer',
      },
    },
  };
};
