import { SnackbarSeverity } from '../../components/Layout/Snackbar/snackbar.interface';

export enum ActionType {
  LOGIN,
  LOGOUT,
  SET_TITLE,
  START_LOADING,
  END_LOADING,
  SET_NOTIFICATION,
}

interface BaseAction {
  type: ActionType;
}

interface LoginAction extends BaseAction {
  type: ActionType.LOGIN;
  jwtToken: string;
}

interface LogoutAction extends BaseAction {
  type: ActionType.LOGOUT;
}

interface StartLoadingtAction extends BaseAction {
  type: ActionType.START_LOADING;
}

interface EndLoadingAction extends BaseAction {
  type: ActionType.END_LOADING;
}

interface SetNotificationAction extends BaseAction {
  type: ActionType.SET_NOTIFICATION;
  message: string;
  severity: SnackbarSeverity;
}

interface TitleAction extends BaseAction {
  type: ActionType.SET_TITLE;
  title: string;
  subTitle?: string;
}

export type StateAction = Readonly<
  | LoginAction
  | LogoutAction
  | TitleAction
  | StartLoadingtAction
  | EndLoadingAction
  | SetNotificationAction
>;
