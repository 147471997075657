import BaseApi from './base';
import {
  AssignAlarmResponse,
  AssignedWorkordersResponse,
  FinishAlarmResponse,
  Method,
  WorkorderApiResponse,
} from './api.interface';
import { CreateWorkorderBody } from '../containers/Workorder/CreateWorkOrder/CreateWorkOrder.interface';

export default class AlarmsApi extends BaseApi {
  async fetchAlarms(
    limit: number,
    token?: string
  ): Promise<WorkorderApiResponse> {
    return this.makeRequest(Method.GET, `furnace/alarms/all/${limit}`, null, {
      Authorization: token ? `Bearer ${token}` : '',
    });
  }

  async fetchAssignedAlarms(
    limit: number,
    token?: string,
    furnaceId?: string
  ): Promise<AssignedWorkordersResponse> {
    const path = `furnace/repairs/assign/${limit}${
      furnaceId ? `/${furnaceId}` : ''
    }`;
    return this.makeRequest(Method.GET, path, null, {
      Authorization: token ? `Bearer ${token}` : '',
    });
  }

  async finishAlarm(id: string, token?: string): Promise<FinishAlarmResponse> {
    return this.makeRequest(Method.PATCH, `furnace/alarms/status/${id}`, null, {
      Authorization: token ? `Bearer ${token}` : '',
    });
  }

  async assignAlarm(
    body: CreateWorkorderBody,
    token?: string
  ): Promise<AssignAlarmResponse> {
    return this.makeRequest(
      Method.POST,
      `furnace/repairs/assign`,
      JSON.stringify(body),
      {
        Authorization: token ? `Bearer ${token}` : '',
      }
    );
  }
}
