import {
  Repairman,
  Alarm,
  Repair,
  MappedUnattendedWorkorderData,
  MappedWorkorderData,
  FurnaceType,
  RegularListItem,
  InvestorListItem,
  CurrentAlarmsInterface,
  CurrentRepairsInterface,
} from '../api/api.interface';
import { Status, DisplayedStatus, DateFormat } from '../constants/constants';
import {
  Workorder,
  AlarmToAssign,
  AssignAlarm,
} from '../containers/Workorder/workorder.interface';
import { format } from 'date-fns';
import { omitBy, isEmpty } from 'lodash';
import queryString from 'query-string';
import {
  DropdownSelect,
  SelectType,
} from '../components/SelectDropdown/SelectDropdown.interface';

export const capitalize = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const formatDate = (date: string, dateFormat: string) => {
  return date ? format(new Date(date), dateFormat) : '';
};

export const displayStatus = (status: string) => {
  switch (status) {
    case Status.IN_PROGRESS:
      return DisplayedStatus.IN_PROGRESS;
    case Status.DONE:
      return DisplayedStatus.DONE;

    default:
      return;
  }
};

export const newWorkorderObject = (
  note: string,
  workorder: Workorder,
  repairman: Repairman
) => {
  return {
    note: note,
    alarms: workorder.alarms.map((alarm: Alarm) => {
      return {
        id: alarm.id,
        name: alarm.name,
      } as AlarmToAssign;
    }),
    deviceId: workorder.device,
    email: repairman.email,
    receivedTimeStr: new Date().toISOString(),
  } as AssignAlarm;
};

export const mapWorkordersData = (finishedRepairs: Repair[]) => {
  return finishedRepairs.map((repair: Repair) => {
    return {
      id: repair.number ?? '/',
      date: formatDate(repair.date, DateFormat.DD_MM_YYYY),
      deviceId: repair.deviceId,
      repairman: `${repair.repairman.firstName} ${repair.repairman.lastName}`,
      fixedDate: formatDate(repair.fixedDate, DateFormat.DD_MM_YYYY),
      updatedAt: formatDate(repair.updatedAt, DateFormat.DD_MM_YYYY),
    } as MappedWorkorderData;
  });
};

export const unatendedWorkordersData = (workorders: Workorder[]) => {
  return workorders.map((workorder: Workorder) => {
    return {
      deviceId: workorder.device,
      alarmCount: workorder.alarms.length,
      contactPerson: workorder.contactPerson,
    } as MappedUnattendedWorkorderData;
  });
};

export const cleanQueryParams = <T>(params: T): string => {
  const cleanedParams = omitBy(params as never, (value) => {
    return value === '' || value === null;
  });
  return !isEmpty(cleanedParams)
    ? `?${queryString.stringify(cleanedParams as never)}`
    : '';
};

export const handleSelectChange = (
  select: DropdownSelect | null,
  setValue: (value: DropdownSelect) => void,
  type: SelectType
) => {
  if (select) {
    setValue(select);
  } else {
    switch (type) {
      case SelectType.FURNACE_TYPE:
        setValue({} as FurnaceType);
        break;
      case SelectType.REGION:
        setValue('');
        break;
      case SelectType.USER:
        setValue({} as RegularListItem);
        break;
      case SelectType.INVESTOR:
        setValue({} as InvestorListItem);
        break;
      case SelectType.REPAIRMAN:
        setValue({} as Repairman);
        break;
    }
  }
};

export const CurrentAlarmsData = (
  workorders: Workorder[]
): CurrentAlarmsInterface[] => {
  return workorders.map((workorder: Workorder) => {
    return {
      furnaceId: workorder.device,
      alarmsCount: workorder.alarms.length.toString(),
      contactPerson: workorder.contactPerson,
    };
  });
};

export const CurrentRepairsData = (
  repairs: Repair[]
): CurrentRepairsInterface[] => {
  return repairs.map((repair: Repair) => {
    return {
      number: repair.number ?? '/',
      furnaceId: repair.deviceId,
      date: formatDate(repair.date, DateFormat.DD_MM_YYYY),
      repairman: `${repair.repairman.firstName} ${repair.repairman.lastName}`,
      fixedDate: formatDate(repair.fixedDate, DateFormat.DD_MM_YYYY) || '',
    };
  });
};
