import React, { FC } from 'react';
import {
  makeStyles,
  Theme,
  Grid,
  Divider,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { alarmItemStyles } from './alarmItemStyles';
import { AlarmItemProps } from './AlarmItem.interface';
import { colors } from '../../../theme';
import ErrorIcon from '@material-ui/icons/Error';

const useClasses = makeStyles((theme: Theme) => alarmItemStyles(theme));

const AlarmItem: FC<AlarmItemProps> = ({ alarm }) => {
  const classes = useClasses();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={1}
        alignItems="center"
        className={classes.item}
      >
        {isDesktop && (
          <Grid item xs={1}>
            <ErrorIcon htmlColor={colors.mainRed} />
          </Grid>
        )}
        <Grid item xs={12} md={2}>
          {!isDesktop && (
            <Typography variant="body2" className={classes.header}>
              ID peči:
            </Typography>
          )}
          <Typography variant="body1">{alarm.device}</Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          {!isDesktop && (
            <Typography variant="body2" className={classes.header}>
              Št. napak:
            </Typography>
          )}
          <Typography variant="body1">{alarm.alarms?.length}</Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          {!isDesktop && (
            <Typography variant="body2" className={classes.header}>
              Kontaktna oseba:
            </Typography>
          )}
          <Typography variant="body1">{alarm.contactPerson}</Typography>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

export default AlarmItem;
