import BaseApi from './base';
import {
  Method,
  RepairmanResponse,
  UserType,
  PaginationParams,
  RepairmenApiResponse,
} from './api.interface';
import { cleanQueryParams } from '../shared/utility';

export default class AlarmsApi extends BaseApi {
  async fetchRepairman(
    pagination: PaginationParams,
    token?: string
  ): Promise<RepairmanResponse> {
    return this.makeRequest(
      Method.GET,
      `users${cleanQueryParams({
        ...pagination,
        userType: UserType.REPAIRMAN,
      })}`,
      null,
      {
        Authorization: token ? `Bearer ${token}` : '',
      }
    );
  }

  async fetchRepairmanDetail(
    email: string,
    token: string
  ): Promise<RepairmenApiResponse> {
    return this.makeRequest(Method.GET, `users/${email}`, null, {
      Authorization: token ? `Bearer ${token}` : '',
    });
  }
}
