import { Theme } from '@material-ui/core/styles';

import backgroundImage from '../../assets/background-bioenergy-unit.png';
import { colors } from '../../theme';

const loginStyles = (theme: Theme) => {
  return {
    main: {
      minHeight: '100vh',
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    paper: {
      display: 'flex' as const,
      flexDirection: 'column' as const,
      alignItems: 'center' as const,
      borderRadius: '15px',
      padding: '40px',
      position: 'relative' as const,
    },
    opacity: {
      position: 'absolute' as const,
      backgroundColor: theme.palette.background.default,
      opacity: 0.7,
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      borderRadius: '25px',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    link: {
      color: theme.palette.text.primary,
      cursor: 'pointer',
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      backgroundColor: colors.lightGray,
      color: colors.dark,
    },
    loginBox: {
      alignItems: 'center',
      display: 'flex',
      flexGrow: 1,
    },
    loginContainer: {
      maxWidth: '450px',
      [theme.breakpoints.up('xs')]: {
        margin: '1rem',
      },
      [theme.breakpoints.up('sm')]: {
        marginLeft: '5rem',
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: '15rem',
      },
    },
    footer: {
      justifyContent: 'center',
      display: 'flex',
      padding: '5px',
    },
    zIndex: {
      zIndex: 1,
    },
  };
};

export default loginStyles;
