import { ChangeEvent, InputHTMLAttributes, useState } from 'react';

export function useControllerInput(): [
  string,
  InputHTMLAttributes<HTMLInputElement>['onChange']
] {
  const [state, setState] = useState<string>('');

  const handler = (event: ChangeEvent<HTMLInputElement>): void => {
    setState(event.target.value);
  };

  return [state, handler];
}
