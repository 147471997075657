import React, { useEffect, useState } from 'react';
import { FinishedWorkorderDetailsProps } from './FinishedWorkorderDetails.interface';
import { useAppState } from '../../../../state/state.context';
import {
  AssignedWorkordersResponse,
  Repair,
  MappedRepairWithUser,
  RepairAlarm,
} from '../../../../api/api.interface';
import { ActionType } from '../../../../state/types/actions';
import AlarmsApi from '../../../../api/alarms';
import RepairsApi from '../../../../api/repairs';
import {
  makeStyles,
  Theme,
  Grid,
  Button,
  Box,
  Typography,
} from '@material-ui/core';
import finishedWordorderDetailsStyles from './finishedWordorderDetailsStyles';
import DashboardCard from '../../../../components/Layout/Dashboard/DashboardCard/DashboardCard';
import Footnote from '../../../../components/Footnote/Footnote';
import { useHistory } from 'react-router';
import MaterialItems from '../../../../components/MaterialItems/MaterialItems';
import Container from '../../../../components/Container/Container';
import Loading from '../../../../components/Loading/Loading';
import FurnaceRepairs from '../../../../components/Furnace/FurnaceRepairs/FurnaceRepairs';
import ErrorIcon from '@material-ui/icons/Error';
import { colors } from '../../../../theme';
import { formatDate } from '../../../../shared/utility';
import { DateFormat } from '../../../../constants/constants';
import sharedStyles from '../../../../shared/styles';
import { SnackbarSeverity } from '../../../../components/Layout/Snackbar/snackbar.interface';

const alarmsApi = new AlarmsApi();
const repairsApi = new RepairsApi();
const useStyles = makeStyles((theme: Theme) => {
  return { ...finishedWordorderDetailsStyles(theme), ...sharedStyles(theme) };
});

const FinishedWorkorderDetails: React.FC<FinishedWorkorderDetailsProps> = ({
  match,
  location,
}) => {
  const {
    state: { authToken },
    dispatch,
  } = useAppState();
  const classes = useStyles();
  const history = useHistory();

  const [repairs, setRepairs] = useState<Repair[]>([]);
  const [loading, setLoading] = useState(false);
  const [fullRepair, setFullRepair] = useState<MappedRepairWithUser>(
    {} as MappedRepairWithUser
  );

  const { repair } = location.state as { repair: Repair };

  useEffect(() => {
    dispatch({
      type: ActionType.SET_TITLE,
      title: `Podrobnosti naloga ${repair.number}`,
    });
  }, [dispatch, repair.number]);

  useEffect(() => {
    if (authToken) {
      setLoading(true);
      Promise.all([
        alarmsApi
          .fetchAssignedAlarms(-1, authToken, repair.deviceId)
          .then((data: AssignedWorkordersResponse) => {
            setRepairs(data.repairs as Repair[]);
          })
          .catch((err) => {
            if (err.message === 'Unauthorized') {
              dispatch({ type: ActionType.LOGOUT });
            }
            dispatch({
              type: ActionType.SET_NOTIFICATION,
              message: err.message,
              severity: SnackbarSeverity.ERROR,
            });
          }),

        // get full repair
        repairsApi
          .fetchRepairById(repair.id, authToken)
          .then((data: MappedRepairWithUser) => {
            setFullRepair(data as MappedRepairWithUser);
          }),
      ])
        .catch((err) => {
          if (err.message === 'Unauthorized') {
            dispatch({ type: ActionType.LOGOUT });
          }
          dispatch({
            type: ActionType.SET_NOTIFICATION,
            message: err.message,
            severity: SnackbarSeverity.ERROR,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    repair.deviceId,
    dispatch,
    authToken,
    match,
    setRepairs,
    setLoading,
    repair.id,
  ]);

  const getPdf = () => {
    dispatch({ type: ActionType.START_LOADING });
    repairsApi
      .getRepairPdfById(fullRepair.id, authToken)
      .then((data) => {
        const blob = new Blob([data], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `Delovni_nalog_${fullRepair.id}.pdf`;
        link.click();
      })
      .catch((err) => {
        if (err.message === 'Unauthorized') {
          dispatch({ type: ActionType.LOGOUT });
        }
        dispatch({
          type: ActionType.SET_NOTIFICATION,
          message: err.message,
          severity: SnackbarSeverity.ERROR,
        });
      })
      .finally(() => {
        dispatch({ type: ActionType.END_LOADING });
      });
  };

  const openRepairDetails = (repair: Repair) => {
    history.push({
      pathname: `/workorders/details/${repair.id}`,
      state: { repair },
    });
  };

  const showAlarms = fullRepair?.alarms?.map(
    (alarm: RepairAlarm, i: number) => (
      <Grid container direction="row" wrap="nowrap" key={i}>
        <Box mr={'1rem'}>
          <ErrorIcon htmlColor={colors.mainRed} />
        </Box>
        <Typography variant="body1" className={classes.alarmName}>
          {`${i + 1}. ${alarm.name}`}
        </Typography>
      </Grid>
    )
  );

  return (
    <>
      {loading ? (
        <Box mt={'2rem'}>
          <Loading />
        </Box>
      ) : (
        <Container>
          <DashboardCard loading={loading}>
            <Grid container direction="row">
              {/* Podatki o nalogu */}
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.subtitle}>
                  Podatki o nalogu
                </Typography>
                <Grid className={classes.dataMargin}>
                  <span className={classes.label}>Oddano v reševanje:</span>
                  <span className={classes.data}>
                    {formatDate(fullRepair?.date, DateFormat.DD_MM_YYYY)}
                  </span>
                </Grid>
                <Grid className={classes.dataMargin}>
                  <span className={classes.label}>Tip:</span>
                  <span className={classes.data}>{fullRepair?.type}</span>
                </Grid>
                <Grid className={classes.dataMargin}>
                  <span className={classes.label}>Serviser:</span>
                  <span className={classes.data}>
                    {`${fullRepair?.repairman?.firstName} 
                      ${fullRepair?.repairman?.lastName}
                      <${fullRepair?.repairman?.email}>`}
                  </span>
                </Grid>
              </Grid>

              {/* Podatki o naročniki */}
              <Grid item xs={12} className={classes.sectionMargin}>
                <Typography variant="h6" className={classes.subtitle}>
                  Podatki o naročniku
                </Typography>
                <Grid className={classes.dataMargin}>
                  <span className={classes.label}>Naročnik:</span>
                  <span className={classes.data}>{fullRepair?.userName}</span>
                </Grid>
                <Grid className={classes.dataMargin}>
                  <span className={classes.label}>Naslov:</span>
                  <span className={classes.data}>{fullRepair?.address}</span>
                </Grid>
                <span className={classes.dataMargin}>
                  <span className={classes.label}>Pošta:</span>
                  <span className={classes.data}>{fullRepair?.post}</span>
                </span>
                <span className={classes.dataMargin}>
                  <span className={classes.label}>Kraj:</span>
                  <span className={classes.data}>{fullRepair?.city}</span>
                </span>
                <span className={classes.dataMargin}>
                  <span className={classes.label}>Tel:</span>
                  <span className={classes.data}>{fullRepair?.phone}</span>
                </span>
              </Grid>

              {/* Podatki o peči (tip peči, id peči) */}
              <Grid item xs={12} className={classes.sectionMargin}>
                <Typography variant="h6" className={classes.subtitle}>
                  Podatki o peči
                </Typography>
                <Grid className={classes.dataMargin}>
                  <span className={classes.label}>Tip peči:</span>
                  <span className={classes.data}>
                    {fullRepair?.furnaceType}
                  </span>
                </Grid>
                <Grid className={classes.dataMargin}>
                  <span className={classes.label}>ID peči:</span>
                  <span className={classes.data}>{fullRepair?.deviceId}</span>
                </Grid>
                <span className={classes.dataMargin}>
                  <span className={classes.label}>Datum izdelave:</span>
                  <span className={classes.data}>/</span>
                </span>
                <span className={classes.dataMargin}>
                  <span className={classes.label}>Datum zagona:</span>
                  <span className={classes.data}>/</span>
                </span>
              </Grid>

              {/* Napake na peci */}
              <Grid item xs={12} className={classes.sectionMargin}>
                <Typography variant="h6" className={classes.subtitle}>
                  Napake na peči
                </Typography>
                {fullRepair?.alarms?.length ? (
                  showAlarms
                ) : (
                  <Typography variant="body1" className={classes.text}>
                    Brez napak na peči
                  </Typography>
                )}
              </Grid>

              {/* Opomba */}
              <Grid item xs={12} className={classes.sectionMargin}>
                <Footnote footnote={fullRepair?.note} label="Opomba" />
              </Grid>

              {/* Opravljeno delo */}
              {fullRepair.status === 'DONE' && (
                <Grid item xs={12} container className={classes.sectionMargin}>
                  <Typography variant="h6" className={classes.subtitle}>
                    Opravljeno delo
                  </Typography>
                  <Grid item xs={12} className={classes.dataMargin}>
                    <span className={classes.label}>Datum zaključka:</span>
                    <span className={classes.data}>
                      {formatDate(fullRepair?.fixedDate, DateFormat.DD_MM_YYYY)}
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mt={'.5rem'}>
                      <Footnote
                        footnote={fullRepair?.workDescription}
                        label="Opis dela"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} className={classes.sectionMargin}>
                    <span className={classes.dataMargin}>
                      <span className={classes.label}>Vrsta pelet:</span>
                      <span className={classes.data}>
                        {fullRepair?.fuelType}
                      </span>
                    </span>
                    <span className={classes.dataMargin}>
                      <span className={classes.label}>
                        Končni test z zagonom:
                      </span>
                      <span className={classes.data}>
                        {fullRepair?.finalTestWithLaunch ? 'DA' : 'NE'}
                      </span>
                    </span>
                  </Grid>

                  <Grid item xs={12} className={classes.sectionMargin}>
                    <Typography variant="h6" className={classes.subtitle}>
                      Materiali
                    </Typography>
                    {fullRepair?.materials?.length ? (
                      <MaterialItems materials={fullRepair.materials} />
                    ) : null}
                  </Grid>
                  <Grid item xs={12} className={classes.sectionMargin}>
                    <Typography variant="h6" className={classes.subtitle}>
                      Podatki o plačilu
                    </Typography>
                    <span className={classes.label}>Tip plačila:</span>
                    <span className={classes.data}>
                      {fullRepair.paymentMethod ?? '/'}
                    </span>
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-end"
                className={classes.buttonWrapper}
              >
                <Grid item xs={12} md={'auto'}>
                  <Button
                    type="button"
                    variant="contained"
                    fullWidth
                    size="medium"
                    className={classes.whiteButton}
                    onClick={() => history.goBack()}
                  >
                    Nazaj
                  </Button>
                </Grid>
                {fullRepair?.status === 'DONE' && (
                  <Grid item xs={12} md={'auto'}>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      size="medium"
                      onClick={getPdf}
                    >
                      Prenesi pdf
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </DashboardCard>
          <DashboardCard loading={false}>
            <Typography variant="h6" className={classes.subtitle}>
              Pretekli servisi na objektu
            </Typography>
            {loading ? (
              <Box mt={'2rem'}>
                <Loading />
              </Box>
            ) : repairs.length ? (
              <FurnaceRepairs onClick={openRepairDetails} repairs={repairs} />
            ) : (
              <Typography variant="body1" className={classes.text}>
                Ni preteklih servisov
              </Typography>
            )}
          </DashboardCard>
        </Container>
      )}
    </>
  );
};

export default FinishedWorkorderDetails;
