import { Theme } from '@material-ui/core';

export const investorsStyles = (theme: Theme) => {
  return {
    buttonWrapper: {
      [theme.breakpoints.down('sm')]: {
        marginTop: '1rem',
      },
    },
  };
};
