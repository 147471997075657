import React, { ChangeEvent, useEffect, useState } from 'react';
import { NewWorkOrderProps } from './NewWorkOrder.interface';
import { useAppState } from '../../../state/state.context';
import { Workorder, AlarmToAssign } from '../workorder.interface';
import { ActionType } from '../../../state/types/actions';
import DashboardCard from '../../../components/Layout/Dashboard/DashboardCard/DashboardCard';
import {
  Grid,
  makeStyles,
  Theme,
  Box,
  Button,
  TextField,
  Typography,
} from '@material-ui/core';
import newWorkOrderStyles from './newWorkOrderStyles';
import SelectRepairman from '../../../components/SelectRepairman/SelectRepairman';
import ErrorIcon from '@material-ui/icons/Error';
import { Alarm, Repairman } from '../../../api/api.interface';
import { useHistory } from 'react-router';
import AlarmsApi from '../../../api/alarms';
import {
  WorkOrderTypes,
  CreateWorkorderBody,
  typeValues,
} from '../CreateWorkOrder/CreateWorkOrder.interface';
import Container from '../../../components/Container/Container';
import { colors } from '../../../theme';
import {
  SelectType,
  DropdownSelect,
} from '../../../components/SelectDropdown/SelectDropdown.interface';
import { handleSelectChange } from '../../../shared/utility';
import { isEmpty } from 'lodash';
import { SnackbarSeverity } from '../../../components/Layout/Snackbar/snackbar.interface';

const useStyles = makeStyles((theme: Theme) => newWorkOrderStyles(theme));
const api = new AlarmsApi();

const NewWorkOrder: React.FC<NewWorkOrderProps> = ({ location }) => {
  const { workorder } = location.state as { workorder: Workorder };
  const {
    state: { authToken, globalLoading },
    dispatch,
  } = useAppState();

  const classes = useStyles();
  const history = useHistory();

  const [repairman, setRepairman] = useState({} as Repairman);
  const [note, setNote] = useState('');

  useEffect(() => {
    dispatch({
      type: ActionType.SET_TITLE,
      title: `Oddaj nalog ${workorder.device} v reševanje`,
    });
  }, [workorder.device, dispatch]);

  const handleRepairmanChange = (repairman: Repairman) => {
    setRepairman(repairman);
  };

  const handleFinish = () => {
    const body: CreateWorkorderBody = {
      type: typeValues[WorkOrderTypes.NALOG_IZ_NAPAKE],
      repairman: repairman.email,
      receivedTimeStr: new Date().toISOString(),
      deviceId: workorder.device,
      note: note,
      alarms: workorder.alarms.map((alarm: Alarm) => {
        return {
          id: alarm.id,
          name: alarm.name,
        } as AlarmToAssign;
      }),
    };

    dispatch({ type: ActionType.START_LOADING });
    api
      .assignAlarm(body, authToken)
      .then(() => {
        dispatch({ type: ActionType.END_LOADING });
        dispatch({
          type: ActionType.SET_NOTIFICATION,
          message: `Nalog oddan v reševanje serviserju ${repairman.firstName} ${repairman.lastName}`,
          severity: SnackbarSeverity.SUCCESS,
        });
        setTimeout(() => {
          history.push('/workorders');
        }, 700);
      })
      .catch((err) => {
        dispatch({ type: ActionType.END_LOADING });
        dispatch({
          type: ActionType.SET_NOTIFICATION,
          message: err.message,
          severity: SnackbarSeverity.ERROR,
        });
      });
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  const showAlarms = workorder?.alarms?.map((alarm: Alarm, i: number) => (
    <Grid container direction="row" wrap="nowrap" key={i}>
      <Box mr={'1rem'}>
        <ErrorIcon htmlColor={colors.mainRed} />
      </Box>
      <Typography variant="body1" className={classes.alarmName}>
        {`${i + 1}. ${alarm.name}`}
      </Typography>
    </Grid>
  ));

  return (
    <>
      <Container>
        <DashboardCard loading={false}>
          <Typography variant="h6" className={classes.subtitle}>
            Podatki o napakah
          </Typography>
          {workorder?.alarms.length ? (
            showAlarms
          ) : (
            <Typography variant="body1" className={classes.text}>
              Brez napak na peči
            </Typography>
          )}
          <Box m={'1rem 0 2.5rem'}>
            <Typography variant="body1">
              Naslov peči: {workorder.furnaceAddress}
            </Typography>
          </Box>
          <Typography variant="h6" className={classes.subtitle}>
            Izberi serviserja
          </Typography>
          <Grid container>
            <Grid item xs={12} md={4}>
              <SelectRepairman
                selectRepairman={(select: DropdownSelect | null) =>
                  handleSelectChange(
                    select,
                    (value: DropdownSelect) =>
                      handleRepairmanChange(value as Repairman),
                    SelectType.REPAIRMAN
                  )
                }
                hideTitle
                required
                region={workorder.furnaceRegion}
                disabled={globalLoading}
              />
            </Grid>
          </Grid>
          <Box m={'2rem 0 .5rem'} pl={'.5rem'}>
            <Typography variant="body1" className={classes.alarmName}>
              Opomba
            </Typography>
          </Box>
          <Grid container>
            <Grid item xs={12} md={6}>
              <TextField
                multiline
                rows={5}
                fullWidth
                label=""
                helperText="Obvezno"
                disabled={globalLoading}
                variant="standard"
                value={note}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  handleNoteChange(e)
                }
                InputProps={{
                  disableUnderline: true,
                }}
                className={classes.note}
              />
            </Grid>
          </Grid>
          <Box mt={'2rem'}>
            <Grid container justify="flex-end">
              <Grid item xs={12} md={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  size="medium"
                  disabled={globalLoading || isEmpty(repairman) || !note}
                  onClick={() => handleFinish()}
                >
                  Oddaj
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DashboardCard>
      </Container>
    </>
  );
};

export default NewWorkOrder;
