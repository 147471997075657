import { Theme } from '@material-ui/core';
import { colors } from '../../theme';

export const furnaceListStyles = (theme: Theme) => {
  return {
    header: {
      color: colors.lightGray,
      opacity: 0.6,
      wordWrap: 'break-word' as const,
      padding: '0 .25rem',
    },
    headerContainer: {
      padding: '0 1.5rem',
    },
    buttonWrapper: {
      [theme.breakpoints.down('sm')]: {
        marginTop: '1rem',
      },
    },
  };
};
