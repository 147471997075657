import BaseApi from './base';
import {
  MappedRepairWithUser,
  Method,
} from './api.interface';

export default class RepairsApi extends BaseApi {

  async fetchRepairById(id: string, token?: string): Promise<MappedRepairWithUser> {
    return this.makeRequest(Method.GET, `furnace/repairs/${id}`, null, {
      Authorization: token ? `Bearer ${token}` : '',
    })
  }

  async getRepairPdfById(id: string, token?: string): Promise<Buffer> {
    return this.makeRequestForBinaryData(Method.GET,  `furnace/repairs/pdf/${id}`,
      {
      Authorization: token ? `Bearer ${token}` : '',
      }
    )
  }
}
