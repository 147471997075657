import { useState } from 'react';

interface ApiState {
  loading: boolean;
  setLoading: (value: boolean) => void;
  setError: (value: string) => void;
  setSuccess: (value: string) => void;
  error?: string;
  success?: string;
}

export function useApiState(): ApiState {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');

  return { loading, error, setLoading, setError, success, setSuccess };
}
