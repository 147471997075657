import React, { FC, useEffect, useState, useCallback } from 'react';
import { ActionType } from '../../state/types/actions';
import { useAppState } from '../../state/state.context';
import Loading from '../../components/Loading/Loading';
import FurnacesApi from '../../api/furnaces';
import { useHistory } from 'react-router-dom';
import {
  FurnacesApiResponse,
  FurnaceItem as Furnace,
} from '../../api/api.interface';
import {
  Grid,
  Typography,
  Box,
  makeStyles,
  Theme,
  useMediaQuery,
  Button,
} from '@material-ui/core';
import { furnaceListStyles } from './furnaceListStyles';
import FurnaceItem from '../../components/Furnace/FurnaceItem/FurnaceItem';
import Container from '../../components/Container/Container';
import SearchInput from '../../components/SearchInput/SearchInput';
import Pagination from '../../components/Pagination/Pagination';
import { SnackbarSeverity } from '../../components/Layout/Snackbar/snackbar.interface';

const api = new FurnacesApi();

const useClasses = makeStyles((theme: Theme) => furnaceListStyles(theme));

const FurnaceList: FC = () => {
  const {
    state: { authToken },
    dispatch,
  } = useAppState();
  const classes = useClasses();
  const history = useHistory();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [page, setPage] = useState('');
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [furnaces, setFurnaces] = useState<Furnace[]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    setPage(queryParams.get('page') ?? '');
    setSearch(queryParams.get('search') ?? '');
  }, [history.location.search]);

  useEffect(() => {
    dispatch({ type: ActionType.SET_TITLE, title: 'Seznam peči' });
  }, [dispatch]);

  const fetchFurnacesApi = useCallback(
    (token: string | undefined, page: string, search: string) => {
      if (token) {
        setLoading(true);
        api
          .fetchFurnaces(token, { page, search, limit: 10 })
          .then((data: FurnacesApiResponse) => {
            setFurnaces(data.furnaces);
            setTotalPages(data.totalPages);
            setCurrentPage(data.currentPage);
          })
          .catch((err) => {
            setFurnaces([]);
            if (err.message === 'Unauthorized') {
              dispatch({ type: ActionType.LOGOUT });
            } else {
              dispatch({
                type: ActionType.SET_NOTIFICATION,
                message: err.message,
                severity: SnackbarSeverity.ERROR,
              });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    fetchFurnacesApi(authToken, page ?? '1', search ?? '');
  }, [authToken, page, search, fetchFurnacesApi]);

  const header = (label: string) => (
    <Typography className={classes.header} variant="body2">
      {label}
    </Typography>
  );

  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={12} md={4}>
            <SearchInput
              label="Išči peči"
              initialValue={search ?? ''}
              pathname="/furnaces"
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={5} />
          <Grid item xs={12} md={4} lg={3} className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              size="medium"
              disabled={loading}
              onClick={() => history.push('/furnaces/add')}
            >
              Dodaj peč
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Container>
        {loading ? (
          <Box mt={'2rem'}>
            <Loading />
          </Box>
        ) : (
          <>
            {isDesktop && (
              <Grid container className={classes.headerContainer}>
                <Grid item xs={1}></Grid>
                <Grid item xs={2}>
                  {header('ID peči')}
                </Grid>
                <Grid item xs={2}>
                  {header('Tip peči')}
                </Grid>
                <Grid item xs={2}>
                  {header('Naročnik')}
                </Grid>
                <Grid item xs={3}>
                  {header('Uporabnik')}
                </Grid>
                <Grid item xs={2}>
                  {header('Status')}
                </Grid>
              </Grid>
            )}
            <Grid container direction="column">
              {furnaces.map((furnace: Furnace, i: number) => (
                <FurnaceItem furnace={furnace} key={i} />
              ))}
            </Grid>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              disabled={loading}
              pathname="/furnaces"
              search={search ?? ''}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default FurnaceList;
