import React, { FC } from 'react';
import { ContainerProps, Grid, makeStyles, Theme } from '@material-ui/core';
import { containerStyles } from './containerStyles';

const useClasses = makeStyles((theme: Theme) => containerStyles(theme));

const Container: FC<ContainerProps> = ({ children }) => {
  const classes = useClasses();
  return (
    <Grid container className={classes.wrapper}>
      <Grid item xs={12} lg={10} className={classes.container}>
        {children}
      </Grid>
    </Grid>
  );
};

export default Container;
