import { Theme } from '@material-ui/core';
import { colors } from '../../../theme';

const workOrderDetailStyles = (theme: Theme) => {
  return {
    subtitle: {
      fontWeight: 700,
      marginBottom: '1.5rem',
    },
    text: {
      color: colors.lightGray,
    },
    alarmName: {
      color: colors.lightGray,
      opacity: 0.6,
    },
  };
};

export default workOrderDetailStyles;
