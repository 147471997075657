import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Grid,
  Button,
  Typography,
  Box,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ActionType } from '../../state/types/actions';
import { useAppState } from '../../state/state.context';
import AlarmsApi from '../../api/alarms';
import {
  Workorder,
  CurrentAlarmsHeader,
  InProgressHeader,
  FinishedHeader,
} from './workorder.interface';
import {
  AssignedWorkordersResponse,
  Repair,
  WorkorderApiResponse,
} from '../../api/api.interface';
import { Status } from '../../constants/constants';
import workOrderStyles from './workorderStyles';
import { CurrentAlarmsData, CurrentRepairsData } from '../../shared/utility';
import Container from '../../components/Container/Container';
import DashboardCard from '../../components/Layout/Dashboard/DashboardCard/DashboardCard';
import ErrorIcon from '@material-ui/icons/Error';
import { colors } from '../../theme';
import ListItemTable from '../../components/ListItemTable/ListItemTable';
import sharedStyles from '../../shared/styles';
import { SnackbarSeverity } from '../../components/Layout/Snackbar/snackbar.interface';

const api = new AlarmsApi();

const useStyles = makeStyles((theme: Theme) => {
  return { ...workOrderStyles(theme), ...sharedStyles(theme) };
});

const WorkOrder: FC = () => {
  const {
    state: { authToken },
    dispatch,
  } = useAppState();
  const classes = useStyles();
  const history = useHistory();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [loadingFinishedRepairs, setLoadingFinishedRepairs] = useState(false);
  const [loadingRepairsInProgress, setLoadingRepairsInProgress] = useState(
    false
  );
  const [loadingWorkOrders, setLoadingWorkOrders] = useState(false);

  const [finishedRepairs, setFinishedRepairs] = useState<Repair[]>([]);
  const [repairsInProgress, setRepairsInProgress] = useState<Repair[]>([]);
  const [workOrders, setWorkOrders] = useState<Workorder[]>([]);

  useEffect(() => {
    if (authToken) {
      setLoadingWorkOrders(true);
      api
        .fetchAlarms(-1, authToken)
        .then((response: WorkorderApiResponse) => {
          if (response.data) {
            setWorkOrders(response.data.splice(0, 3));
          }
        })
        .catch((err) => {
          if (err.message === 'Unauthorized') {
            dispatch({ type: ActionType.LOGOUT });
          }
          dispatch({
            type: ActionType.SET_NOTIFICATION,
            message: err.message,
            severity: SnackbarSeverity.ERROR,
          });
        })
        .finally(() => {
          setLoadingWorkOrders(false);
        });
    }
    dispatch({ type: ActionType.SET_TITLE, title: 'Delovni nalogi' });
  }, [authToken, dispatch]);

  useEffect(() => {
    if (authToken) {
      setLoadingRepairsInProgress(true);
      setLoadingFinishedRepairs(true);
      api
        .fetchAssignedAlarms(-1, authToken)
        .then((data: AssignedWorkordersResponse) => {
          if (data.repairs) {
            setRepairsInProgress(
              data.repairs
                .filter(
                  (repair: Repair) => repair.status === Status.IN_PROGRESS
                )
                .splice(0, 3)
            );
            setFinishedRepairs(
              data.repairs
                .filter((repair: Repair) => repair.status === Status.DONE)
                .splice(0, 3)
            );
          }
        })
        .catch((err) => {
          if (err.message === 'Unauthorized') {
            dispatch({ type: ActionType.LOGOUT });
          }
          dispatch({
            type: ActionType.SET_NOTIFICATION,
            message: err.message,
            severity: SnackbarSeverity.ERROR,
          });
        })
        .finally(() => {
          setLoadingRepairsInProgress(false);
          setLoadingFinishedRepairs(false);
        });
    }
  }, [authToken, dispatch]);

  const unatendedDetailsHandler = (deviceId: string) => {
    const workorder = workOrders.find(
      (workorder: Workorder) => workorder.device === deviceId
    );
    history.push({
      pathname: `${history.location.pathname}/unattended/details/${deviceId}`,
      state: { workorder },
    });
  };

  const detailsHandler = (repairNumber: string) => {
    const repair = [...repairsInProgress, ...finishedRepairs].find(
      (repair: Repair) => repair.number === repairNumber
    );
    if (repair) {
      history.push({
        pathname: `${history.location.pathname}/details/${repair.id}`,
        state: { repair },
      });
    }
  };

  const allButton = (
    label: string,
    onClick: () => void,
    disabled: boolean = false
  ) => (
    <Grid container direction="row" justify="flex-end">
      <Grid item xs={12} sm={'auto'}>
        <Box mt={'1.5rem'}>
          <Button
            type="button"
            variant="contained"
            fullWidth
            size="medium"
            disabled={disabled}
            className={classes.whiteButton}
            onClick={onClick}
          >
            {label}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );

  const subtitle = (label: string, color: string) => (
    <Grid
      container
      direction="row"
      className={classes.subtitleWrapper}
      alignItems="center"
    >
      <Box mr={'.75rem'}>
        <Typography variant="h5" className={classes.subtitle}>
          {label}
        </Typography>
      </Box>
      {!isDesktop && <ErrorIcon htmlColor={color} />}
    </Grid>
  );

  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} lg={9} xl={10} />
          <Grid
            item
            xs={12}
            md={4}
            lg={3}
            xl={2}
            className={classes.buttonWrapper}
          >
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              size="medium"
              onClick={() => history.push('/new-workorder')}
            >
              Nov nalog
            </Button>
          </Grid>
        </Grid>
        <DashboardCard loading={false}>
          {subtitle('Aktualne napake', colors.mainRed)}
          <ListItemTable
            headers={CurrentAlarmsHeader}
            data={CurrentAlarmsData(workOrders)}
            icon={<ErrorIcon htmlColor={colors.mainRed} />}
            onClick={unatendedDetailsHandler}
            clickData="furnaceId"
            loading={loadingWorkOrders}
          />
          {allButton(
            'Vse napake',
            () => history.push(`/workorders/alarms`),
            loadingWorkOrders
          )}
        </DashboardCard>
        <DashboardCard loading={false}>
          {subtitle('Nalogi v reševanju', colors.mainWarning)}
          <ListItemTable
            headers={InProgressHeader}
            data={CurrentRepairsData(repairsInProgress)}
            icon={<ErrorIcon htmlColor={colors.mainWarning} />}
            onClick={detailsHandler}
            clickData="number"
            loading={loadingRepairsInProgress}
          />
          {allButton(
            'Vsi nalogi',
            () => history.push(`/workorders/in-progress`),
            loadingRepairsInProgress
          )}
        </DashboardCard>
        <DashboardCard loading={false}>
          {subtitle('Zaključeni nalogi', colors.mainSuccess)}
          <ListItemTable
            headers={FinishedHeader}
            data={CurrentRepairsData(finishedRepairs)}
            icon={<ErrorIcon htmlColor={colors.mainSuccess} />}
            onClick={detailsHandler}
            clickData="number"
            loading={loadingFinishedRepairs}
          />
          {allButton(
            'Vsi nalogi',
            () => history.push(`/workorders/finished`),
            loadingFinishedRepairs
          )}
        </DashboardCard>
      </Container>
    </>
  );
};

export default WorkOrder;
