import { Theme } from '@material-ui/core';

const snackbarStyles = (theme: Theme) => {
  return {
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    alert: {
      borderRadius: '15px',
      [theme.breakpoints.down('xs')]: {
        width: '100vw',
      },
      width: '50vw',
      height: '4em',
      display: 'flex',
      alignItems: 'center',
    },
    snackbarPosition: {
      top: '3em',
    },
  };
};

export default snackbarStyles;
