import React, { FC } from 'react';
import { makeStyles, Theme, Switch, Grid } from '@material-ui/core';
import { labeledCheckboxStyles } from './labeledCheckboxStyles';
import { LabeledCheckboxProps } from './LabeledCheckbox.interface';

const useClasses = makeStyles((theme: Theme) => labeledCheckboxStyles(theme));

const LabeledCheckbox: FC<LabeledCheckboxProps> = ({
  label,
  checked,
  name,
  disabled = false,
  onChange,
}) => {
  const classes = useClasses();

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="space-between"
      className={classes.wrapper}
    >
      {label}
      <Switch
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        name={name}
        color="primary"
      />
    </Grid>
  );
};

export default LabeledCheckbox;
