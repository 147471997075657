import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import {
  makeStyles,
  Theme,
  Grid,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  useMediaQuery,
  Button,
} from '@material-ui/core';
import { createWorkOrderStyles } from './createWorkOrderStyles';
import { useAppState } from '../../../state/state.context';
import { ActionType } from '../../../state/types/actions';
import DashboardCard from '../../../components/Layout/Dashboard/DashboardCard/DashboardCard';
import {
  WorkOrderTypes,
  CreateWorkorderBody,
  typeValues,
} from './CreateWorkOrder.interface';
import {
  FurnaceItem,
  FurnaceDetailResponse,
  Repairman,
  Alarm,
  UserDetailResponse,
} from '../../../api/api.interface';
import SelectFurnace from '../../../components/Furnace/SelectFurnace/SelectFurnace';
import FurnacesApi from '../../../api/furnaces';
import Loading from '../../../components/Loading/Loading';
import SelectRepairman from '../../../components/SelectRepairman/SelectRepairman';
import { formatDate } from '../../../shared/utility';
import SelectAlarm from '../../../components/SelectAlarm/SelectAlarm';
import { Workorder, AlarmToAssign } from '../workorder.interface';
import AlarmItemHeader from '../../../components/Alarm/AlarmItemHeader/AlarmItemHeader';
import AlarmItem from '../../../components/Alarm/AlarmItem/AlarmItem';
import { DateFormat } from '../../../constants/constants';
import Container from '../../../components/Container/Container';
import { SnackbarSeverity } from '../../../components/Layout/Snackbar/snackbar.interface';

const useClasses = makeStyles((theme: Theme) => createWorkOrderStyles(theme));
const api = new FurnacesApi();

const CreateWorkOrder: FC = () => {
  const classes = useClasses();
  const {
    state: { authToken },
    dispatch,
  } = useAppState();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [selectedType, setSelectedType] = useState('');
  const [furnace, setFurnace] = useState({} as FurnaceDetailResponse);
  const [loadingFurnace, setLoadingFurnace] = useState(false);
  const [renter, setRenter] = useState<UserDetailResponse | undefined>(
    {} as UserDetailResponse
  );
  const [owner, setOwner] = useState<UserDetailResponse | undefined>(
    {} as UserDetailResponse
  );
  const [repairman, setRepairman] = useState({} as Repairman);
  const [note, setNote] = useState('');
  const [alarm, setAlarm] = useState({} as Workorder);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_TITLE,
      title: `Ustvari nov nalog`,
    });
  }, [dispatch]);

  const resetState = () => {
    setAlarm({} as Workorder);
    setFurnace({} as FurnaceDetailResponse);
    setRenter({} as UserDetailResponse);
    setOwner({} as UserDetailResponse);
    setRepairman({} as Repairman);
    setNote('');
  };

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedType(event.target.value);
    resetState();
  };

  const textField = (
    label: string,
    name: string,
    value: string | undefined
  ) => (
    <Grid item xs={12} md={4}>
      <TextField
        variant="outlined"
        fullWidth
        name={name}
        label={label}
        value={value ?? ''}
        disabled
        className={classes.textField}
      />
    </Grid>
  );

  const radioButton = (value: WorkOrderTypes) => (
    <FormControlLabel
      value={value}
      control={<Radio color="primary" />}
      label={typeValues[value]}
      disabled={loadingFurnace}
    />
  );

  const getFurnaceData = (furnaceId: string, token: string) => {
    setLoadingFurnace(true);
    api
      .fetchFurnace(token, furnaceId)
      .then((furnaceDetail: FurnaceDetailResponse) => {
        setFurnace(furnaceDetail);
        setRenter(furnaceDetail.renter);
        setOwner(furnaceDetail.owner);
        setRepairman(furnaceDetail.repairman ?? ({} as Repairman));
      })
      .catch((err) => {
        if (err.message === 'Unauthorized') {
          dispatch({ type: ActionType.LOGOUT });
        } else {
          dispatch({
            type: ActionType.SET_NOTIFICATION,
            message: err.message,
            severity: SnackbarSeverity.ERROR,
          });
        }
      })
      .finally(() => {
        setLoadingFurnace(false);
      });
  };

  const handleFurnaceChange = (furnace: FurnaceItem | null) => {
    if (furnace && authToken) {
      getFurnaceData(furnace.furnaceId, authToken);
    } else {
      resetState();
    }
  };

  const handleAlarmChange = (alarm: Workorder | null) => {
    if (alarm && authToken) {
      setAlarm(alarm);
      getFurnaceData(alarm.device, authToken);
    } else {
      resetState();
    }
  };

  const handleRepairmanChange = (repairman: Repairman | null) => {
    if (repairman) {
      setRepairman(repairman);
    } else {
      setRepairman({} as Repairman);
    }
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  const addWorkorder = () => {
    const alarms =
      selectedType === WorkOrderTypes.NALOG_IZ_NAPAKE
        ? {
            alarms: furnace.alarms.map((alarm: Alarm) => {
              return {
                id: alarm.id,
                name: alarm.name,
              } as AlarmToAssign;
            }),
          }
        : {};

    const body: CreateWorkorderBody = {
      type: typeValues[selectedType as WorkOrderTypes],
      repairman: repairman.email,
      receivedTimeStr: new Date().toISOString(),
      deviceId: furnace.furnaceId,
      note: note,
      ...alarms,
    };
    if (authToken) {
      dispatch({ type: ActionType.START_LOADING });
      api
        .assignRepair(body, authToken)
        .then(() => {
          resetState();
          dispatch({
            type: ActionType.SET_NOTIFICATION,
            message: 'Nalog uspešno oddan',
            severity: SnackbarSeverity.SUCCESS,
          });
          setSelectedType('');
        })
        .catch((err) => {
          if (err.message === 'Unauthorized') {
            dispatch({ type: ActionType.LOGOUT });
          } else {
            dispatch({
              type: ActionType.SET_NOTIFICATION,
              message: err.message,
              severity: SnackbarSeverity.ERROR,
            });
          }
        })
        .finally(() => {
          dispatch({ type: ActionType.END_LOADING });
        });
    }
  };

  return (
    <>
      <Container>
        <DashboardCard loading={false}>
          <Typography variant="h6">Tip naloga *</Typography>
          <RadioGroup
            row
            aria-label="workorder-type"
            name="workorder-type"
            value={selectedType}
            onChange={handleRadioChange}
          >
            {radioButton(WorkOrderTypes.PRVI_ZAGON)}
            {radioButton(WorkOrderTypes.NALOG_IZ_NAPAKE)}
            {radioButton(WorkOrderTypes.REDNI_LETNI_SERVIS)}
            {radioButton(WorkOrderTypes.SERVIS_IZVEN_GARANCIJE)}
            {radioButton(WorkOrderTypes.REKLAMACIJA)}
            {radioButton(WorkOrderTypes.DODATNI_NALOG)}
          </RadioGroup>
          {selectedType === WorkOrderTypes.NALOG_IZ_NAPAKE && (
            <>
              <Box m={'1rem 0'}>
                <Typography variant="h6">Podatki o napaki</Typography>
              </Box>
              <Grid container>
                <Grid item xs={12} md={5}>
                  <Box m={'.5rem 0 1rem'}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item className={classes.selectWrapper}>
                        <SelectAlarm
                          selectAlarm={handleAlarmChange}
                          disabled={loadingFurnace}
                        />
                      </Grid>
                      <Grid item>{loadingFurnace && <Loading />}</Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              {Object.keys(alarm).length ? (
                <Box pb={'2rem'}>
                  {isDesktop && <AlarmItemHeader />}
                  <AlarmItem alarm={alarm} />
                </Box>
              ) : null}
            </>
          )}
          <Box m={'1rem 0'}>
            <Typography variant="h6">Podatki o peči</Typography>
          </Box>
          {selectedType !== WorkOrderTypes.NALOG_IZ_NAPAKE && (
            <Grid container>
              <Grid item xs={12} md={5}>
                <Box mt={'.5rem'}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item className={classes.selectWrapper}>
                      <SelectFurnace
                        selectFurnace={handleFurnaceChange}
                        disabled={loadingFurnace || !selectedType}
                        refreshInput={selectedType}
                        ownerRequired
                      />
                    </Grid>
                    <Grid item>{loadingFurnace && <Loading />}</Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}
          {Object.keys(furnace).length ? (
            <Box mt={'1.5rem'}>
              <Grid container spacing={2}>
                {textField('Tip peči', 'furnaceType', furnace.name)}
                {isDesktop && <Grid item xs={12} md={8} />}
                {textField('ID peči', 'furnaceId', furnace.furnaceId)}
                {textField(
                  'Datum izdelave',
                  'dateCreated',
                  formatDate(furnace.createdAt, DateFormat.DD_MM_YYYY)
                )}
                {textField(
                  'Datum zagona',
                  'dateStart',
                  formatDate(furnace.updatedAt, DateFormat.DD_MM_YYYY)
                )}
              </Grid>
            </Box>
          ) : null}
          <Box m={'2rem 0 1rem'}>
            <Typography variant="h6">Podatki o investitorju</Typography>
          </Box>
          <Grid container spacing={2}>
            {textField('Ime', 'firstName', owner?.firstName)}
            {textField('Priimek', 'lastName', owner?.lastName)}
            {isDesktop && <Grid item xs={12} md={4} />}
            {textField('Podjetje', 'company', owner?.company)}
            {textField('Davčna', 'taxNumber', owner?.taxNumber)}
            {isDesktop && <Grid item xs={12} md={4} />}
            {textField('Naslov', 'address', owner?.address)}
            {textField('Pošta', 'post', owner?.post)}
            {textField('Kraj', 'location', owner?.location)}
            {textField('Telefonska številka', 'phone', owner?.phone)}
            {textField('E-pošta', 'email', owner?.email)}
          </Grid>
          <Box m={'2rem 0 1rem'}>
            <Typography variant="h6">Podatki o uporabniku</Typography>
          </Box>
          {furnace.renter ? (
            <Grid container spacing={2}>
              {textField('Ime', 'firstName', renter?.firstName)}
              {textField('Priimek', 'lastName', renter?.lastName)}
              {isDesktop && <Grid item xs={12} md={4} />}
              {textField('Naslov', 'address', renter?.address)}
              {textField('Pošta', 'post', renter?.post)}
              {textField('Kraj', 'location', renter?.location)}
              {textField('Telefonska številka', 'phone', renter?.phone)}
              {textField('E-pošta', 'email', renter?.email)}
            </Grid>
          ) : (
            <Typography variant="body1" className={classes.text}>
              {Object.keys(furnace).length ? 'Investitor je uporabnik' : ''}
            </Typography>
          )}
          <Box m={'2rem 0 1rem'}>
            <Typography variant="h6">Podatki o serviserju</Typography>
          </Box>
          <Grid container>
            <Grid item xs={12} md={5}>
              <Box mt={'.5rem'}>
                <SelectRepairman
                  selectRepairman={handleRepairmanChange}
                  disabled={loadingFurnace || !selectedType}
                  hideTitle
                  refreshInput={selectedType}
                  initialValue={repairman}
                />
              </Box>
            </Grid>
          </Grid>
          <Box m={'2rem 0 1rem'}>
            <Typography variant="body1">Opis dela in opombe</Typography>
          </Box>
          <Grid container>
            <Grid item xs={12} md={8}>
              <TextField
                multiline
                rows={5}
                fullWidth
                label=""
                helperText="Obvezno"
                disabled={loadingFurnace || !selectedType}
                variant="standard"
                value={note}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  handleNoteChange(e)
                }
                InputProps={{
                  disableUnderline: true,
                }}
                className={classes.note}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.buttonWrapper} justify="flex-end">
            <Grid item xs={12} md={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                size="large"
                disabled={
                  loadingFurnace ||
                  !Object.keys(furnace).length ||
                  !Object.keys(repairman).length ||
                  !note
                }
                onClick={() => addWorkorder()}
              >
                Oddaj
              </Button>
            </Grid>
          </Grid>
        </DashboardCard>
      </Container>
    </>
  );
};

export default CreateWorkOrder;
