import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAppState } from '../../../state/state.context';
import FurnacesApi from '../../../api/furnaces';
import { ActionType } from '../../../state/types/actions';
import {
  FurnaceDetailResponse,
  Alarm,
  AssignedWorkordersResponse,
  Repair,
  FurnacePower,
  Repairman,
  UserDetailResponse,
  UserType,
  UpdateFurnaceBody,
  RegularListItem,
  UpdateFurnaceResponse,
} from '../../../api/api.interface';
import {
  Box,
  Grid,
  makeStyles,
  Theme,
  Typography,
  TextField,
  useMediaQuery,
  Button,
  Collapse,
  Divider,
} from '@material-ui/core';
import Loading from '../../../components/Loading/Loading';
import { furnaceDetailStyles } from './furnaceDetailStyles';
import DashboardCard from '../../../components/Layout/Dashboard/DashboardCard/DashboardCard';
import ErrorIcon from '@material-ui/icons/Error';
import { colors } from '../../../theme';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AlarmsApi from '../../../api/alarms';
import FurnaceRepairs from '../../../components/Furnace/FurnaceRepairs/FurnaceRepairs';
import Container from '../../../components/Container/Container';
import LabeledCheckbox from '../../../components/LabeledCheckbox/LabeledCheckbox';
import SelectRepairman from '../../../components/SelectRepairman/SelectRepairman';
import { handleSelectChange } from '../../../shared/utility';
import {
  DropdownSelect,
  SelectType,
} from '../../../components/SelectDropdown/SelectDropdown.interface';
import { isEmpty } from 'lodash';
import SelectUser from '../../../components/Furnace/SelectUser/SelectUser';
import { FurnacePowerText } from '../../../constants/constants';
import { Workorder } from '../../Workorder/workorder.interface';
import { DashboardSize } from '../../../components/Layout/Dashboard/DashboardCard/dashboardCard.interface';
import { SnackbarSeverity } from '../../../components/Layout/Snackbar/snackbar.interface';

const api = new FurnacesApi();
const alarmsApi = new AlarmsApi();
const useClasses = makeStyles((theme: Theme) => furnaceDetailStyles(theme));

const FurnaceDetail: FC = () => {
  const {
    state: { authToken },
    dispatch,
  } = useAppState();
  const { id } = useParams();
  const classes = useClasses();
  const history = useHistory();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [loading, setLoading] = useState(true);
  const [furnace, setFurnace] = useState({} as FurnaceDetailResponse);
  const [renter, setRenter] = useState<UserDetailResponse | undefined>(
    {} as UserDetailResponse
  );
  const [owner, setOwner] = useState<UserDetailResponse | undefined>(
    {} as UserDetailResponse
  );
  const [status, setStatus] = useState(false);
  const [repairs, setRepairs] = useState<Repair[]>([]);
  const [repairman, setRepairman] = useState({} as Repairman);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showAddInvestor, setShowAddInvestor] = useState(false);

  useEffect(() => {
    if (authToken) {
      dispatch({ type: ActionType.SET_TITLE, title: '' });
      setLoading(true);
      Promise.all([
        api
          .fetchFurnace(authToken, id)
          .then((furnace: FurnaceDetailResponse) => {
            setFurnace(furnace);
            setRenter(furnace.renter);
            setOwner(furnace.owner);
            setStatus(furnace.lastSeenStatus);
            setRepairman(furnace.repairman ?? ({} as Repairman));
            dispatch({
              type: ActionType.SET_TITLE,
              title: `Podrobnosti peči ${furnace.name} - ${furnace.furnaceId}`,
            });
          }),

        alarmsApi
          .fetchAssignedAlarms(-1, authToken, id)
          .then((data: AssignedWorkordersResponse) => {
            setRepairs(
              data.repairs.filter((rep: Repair) => rep.status === 'DONE')
            );
          }),
      ])
        .catch((err) => {
          dispatch({ type: ActionType.SET_TITLE, title: '' });
          if (err.message === 'Unauthorized') {
            dispatch({ type: ActionType.LOGOUT });
          } else {
            dispatch({
              type: ActionType.SET_NOTIFICATION,
              message: err.message,
              severity: SnackbarSeverity.ERROR,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [dispatch, authToken, setLoading, id]);

  const showAlarms = furnace.alarms?.map((alarm: Alarm, i: number) => (
    <Grid container direction="row" wrap="nowrap" key={i}>
      <Box mr={'1rem'}>
        <ErrorIcon htmlColor={colors.mainRed} />
      </Box>
      <Typography variant="body1" className={classes.alarmName}>
        {`${i + 1}. ${alarm.name}`}
      </Typography>
    </Grid>
  ));

  const textField = (
    label: string,
    name: string,
    value: string | undefined
  ) => (
    <Grid item xs={12} md={4}>
      <TextField
        variant="outlined"
        fullWidth
        name={name}
        label={label}
        value={value ?? ''}
        disabled
        className={classes.textField}
      />
    </Grid>
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (authToken) {
      dispatch({ type: ActionType.START_LOADING });
      api
        .setFurnacePower(
          furnace.furnaceId,
          event.target.checked ? FurnacePower.ON : FurnacePower.OFF,
          authToken
        )
        .then(() => {
          dispatch({
            type: ActionType.SET_NOTIFICATION,
            message: `Peč uspešno ${status ? 'izklopljena' : 'vklopljena'}`,
            severity: SnackbarSeverity.SUCCESS,
          });
          setStatus(!status);
        })
        .catch((err) => {
          if (err.message === 'Unauthorized') {
            dispatch({ type: ActionType.LOGOUT });
          } else {
            dispatch({
              type: ActionType.SET_NOTIFICATION,
              message: err.message,
              severity: SnackbarSeverity.ERROR,
            });
          }
        })
        .finally(() => {
          dispatch({ type: ActionType.END_LOADING });
        });
    }
  };

  const changeFurnaceData = (value: UpdateFurnaceBody, successMsg: string) => {
    if (authToken && !isEmpty(value)) {
      dispatch({ type: ActionType.START_LOADING });
      api
        .updateFurnace(furnace.furnaceId, value, authToken)
        .then((data: UpdateFurnaceResponse) => {
          dispatch({
            type: ActionType.SET_NOTIFICATION,
            message: successMsg,
            severity: SnackbarSeverity.SUCCESS,
          });
          setOwner(data.owner);
          setRenter(data.renter);
          setRepairman(data.repairman ?? ({} as Repairman));
          setShowAddUser(false);
          setShowAddInvestor(false);
        })
        .catch((err) => {
          if (err.message === 'Unauthorized') {
            dispatch({ type: ActionType.LOGOUT });
          } else {
            dispatch({
              type: ActionType.SET_NOTIFICATION,
              message: err.message,
              severity: SnackbarSeverity.ERROR,
            });
          }
        })
        .finally(() => {
          dispatch({ type: ActionType.END_LOADING });
        });
    }
  };

  const openRepairDetails = (repair: Repair) => {
    history.push({
      pathname: `/workorders/details/${repair.id}`,
      state: { repair },
    });
  };

  const assignHandler = () => {
    history.push({
      pathname: `/workorders/new/${furnace.furnaceId}`,
      state: {
        workorder: {
          alarms: furnace.alarms,
          device: furnace.furnaceId,
          contactPerson: '',
          furnaceAddress:
            furnace.address ?? furnace.location ?? furnace.region ?? '',
          furnaceRegion: furnace.region,
        } as Workorder,
      },
    });
  };

  const editUserButton = (
    setState: (state: boolean) => void,
    currState: boolean,
    value: UserDetailResponse | undefined
  ) => (
    <Button
      fullWidth
      variant="contained"
      color="secondary"
      size="small"
      onClick={() => setState(!currState)}
    >
      {!currState ? (isEmpty(value) ? 'Dodaj' : 'Zamenjaj') : 'Prekliči'}
    </Button>
  );

  const userDropdown = (
    type: UserType,
    selectType: SelectType,
    state: boolean,
    successMsg: string,
    placeholder: string,
    withoutFurnace: boolean = false
  ) => (
    <Grid container>
      <Grid item xs={12} md={8}>
        <Collapse in={state} unmountOnExit>
          <Box mb={'1rem'}>
            <SelectUser
              selectUser={(select: DropdownSelect | null) =>
                handleSelectChange(
                  select,
                  (value: DropdownSelect) =>
                    changeFurnaceData(
                      type === UserType.REGULAR
                        ? {
                            renter: (value as RegularListItem).email,
                          }
                        : {
                            owner: (value as RegularListItem).email,
                          },
                      successMsg
                    ),
                  selectType
                )
              }
              required
              userType={type}
              placeholder={placeholder}
              withoutFurnace={withoutFurnace}
            />
          </Box>
          <Box mb={'1rem'}>
            <Divider />
          </Box>
        </Collapse>
      </Grid>
    </Grid>
  );

  return (
    <>
      {loading ? (
        <Box mt={'2rem'}>
          <Loading />
        </Box>
      ) : (
        <Container>
          <DashboardCard loading={false} size={DashboardSize.SMALL}>
            <Typography variant="h5" className={classes.subtitle}>
              Zadnje napake na peči
            </Typography>
            {furnace.alarms?.length ? (
              showAlarms
            ) : (
              <Typography variant="body1" className={classes.text}>
                Brez napak na peči
              </Typography>
            )}
            {furnace.alarms?.length ? (
              <Box mt={'1.5rem'}>
                <Grid container direction="row" justify="flex-end">
                  <Grid item xs={12} md={'auto'}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      fullWidth
                      onClick={() => assignHandler()}
                    >
                      Oddaj v reševanje
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ) : null}
          </DashboardCard>
          <DashboardCard loading={false}>
            <Typography variant="h5" className={classes.subtitle}>
              Pretekli zaključeni nalogi na objektu
            </Typography>
            {repairs.length ? (
              <FurnaceRepairs onClick={openRepairDetails} repairs={repairs} />
            ) : (
              <Typography variant="body1" className={classes.text}>
                Ni preteklih nalogov
              </Typography>
            )}
          </DashboardCard>
          <DashboardCard loading={false} size={DashboardSize.MEDIUM}>
            <Grid
              container
              spacing={2}
              justify="space-between"
              className={classes.subtitle}
            >
              <Grid item xs={12} md={'auto'}>
                <Typography variant="h5">Podatki o uporabniku</Typography>
              </Grid>
              <Grid item xs={12} md={3} lg={2}>
                {editUserButton(setShowAddUser, showAddUser, renter)}
              </Grid>
            </Grid>
            {userDropdown(
              UserType.REGULAR,
              SelectType.USER,
              showAddUser,
              'Podatki o uporabniku posodobljeni',
              'Izberi uporabnika',
              true
            )}
            {renter ? (
              <Grid container spacing={2}>
                {textField('Ime', 'firstName', renter?.firstName)}
                {textField('Priimek', 'lastName', renter?.lastName)}
                {isDesktop && <Grid item xs={12} md={4} />}
                {textField('Naslov', 'address', renter?.address)}
                {textField('Pošta', 'post', renter?.post)}
                {textField('Kraj', 'location', renter?.location)}
                {textField('Telefonska številka', 'phone', renter?.phone)}
                {textField('E-pošta', 'email', renter?.email)}
              </Grid>
            ) : owner ? (
              <Typography variant="body1" className={classes.text}>
                Investitor je uporabnik
              </Typography>
            ) : null}
          </DashboardCard>
          <DashboardCard loading={false} size={DashboardSize.MEDIUM}>
            <Grid
              container
              spacing={2}
              justify="space-between"
              className={classes.subtitle}
            >
              <Grid item xs={12} md={'auto'}>
                <Typography variant="h5">Podatki o investitorju</Typography>
              </Grid>
              <Grid item xs={12} md={3} lg={2}>
                {editUserButton(setShowAddInvestor, showAddInvestor, owner)}
              </Grid>
            </Grid>
            {userDropdown(
              UserType.INVESTOR,
              SelectType.USER,
              showAddInvestor,
              'Podatki o investitorju posodobljeni',
              'Izberi investitorja'
            )}
            {owner ? (
              <Grid container spacing={2}>
                {textField('Ime', 'firstName', owner?.firstName)}
                {textField('Priimek', 'lastName', owner?.lastName)}
                {isDesktop && <Grid item xs={12} md={4} />}
                {textField('Podjetje', 'company', owner?.company)}
                {textField('Davčna', 'taxNumber', owner?.taxNumber)}
                {isDesktop && <Grid item xs={12} md={4} />}
                {textField('Naslov', 'address', owner?.address)}
                {textField('Pošta', 'post', owner?.post)}
                {textField('Kraj', 'location', owner?.location)}
                {textField('Telefonska številka', 'phone', owner?.phone)}
                {textField('E-pošta', 'email', owner?.email)}
              </Grid>
            ) : null}
          </DashboardCard>
          <DashboardCard loading={false} size={DashboardSize.SMALL}>
            <Typography variant="h5" className={classes.subtitle}>
              Upravljanje peči
            </Typography>
            <Grid container className={classes.repairmanWrapper}>
              <Grid item xs={12}>
                <Box mb={'1rem'}>
                  <Typography variant="body1" className={classes.text}>
                    Serviser skrbnik
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} lg={9} xl={6}>
                <SelectRepairman
                  selectRepairman={(select: DropdownSelect | null) =>
                    handleSelectChange(
                      select,
                      (value: DropdownSelect) =>
                        changeFurnaceData(
                          {
                            repairman: !isEmpty(value)
                              ? (value as Repairman).email
                              : null,
                          },
                          'Podatki o serviserju skrbniku posodobljeni'
                        ),
                      SelectType.REPAIRMAN
                    )
                  }
                  hideTitle
                  initialValue={repairman as Repairman}
                  required={false}
                  isCaretaker
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.switchWrapper}
            >
              <Grid item xs={12}>
                <Box mb={'1rem'}>
                  <Typography variant="body1" className={classes.text}>
                    Vklop / Izklop peči
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} lg={9} xl={6}>
                <LabeledCheckbox
                  checked={status}
                  onChange={handleChange}
                  name="furnaceStatus"
                  label={
                    <Box display="flex">
                      <Typography variant="body1">
                        {status ? FurnacePowerText.ON : FurnacePowerText.OFF}
                      </Typography>
                      {status ? (
                        <CheckCircleIcon htmlColor={colors.mainSuccess} />
                      ) : (
                        <HighlightOffIcon htmlColor={colors.mainRed} />
                      )}
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          </DashboardCard>
        </Container>
      )}
    </>
  );
};

export default FurnaceDetail;
