import { Theme } from '@material-ui/core';

const repairmanDetailsStyles = (theme: Theme) => {
  return {
    subtitle: {
      fontWeight: 700,
      marginBottom: '1rem',
    },
    regionTitle: {
      fontWeight: 'bold' as const,
      marginTop: '2rem',
    },
  };
};

export default repairmanDetailsStyles;
