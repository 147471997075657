import React, { useEffect } from 'react';

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import layoutStyles from './layoutStyles';
import PageHeader from '../../components/Layout/Header/Header';
import DrawerItems from '../../components/Layout/Drawer/Drawer';
import { useAppState } from '../../state/state.context';
import { LayoutProps } from './layout.interface';
import { useLocation } from 'react-router';

const useClasses = makeStyles((theme: Theme) => layoutStyles(theme));

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const classes = useClasses();
  const theme = useTheme();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const {
    state: { isLoggedIn, title, subTitle = '' },
  } = useAppState();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    setMobileOpen(false);
  }, [location.pathname]);

  return (
    <Box className={classes.root}>
      <CssBaseline />
      {isLoggedIn && (
        <>
          <AppBar position="fixed" color="inherit" className={classes.appBar}>
            <Hidden smUp>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap>
                  {title}
                </Typography>
              </Toolbar>
            </Hidden>
          </AppBar>
          <nav className={classes.drawer} aria-label="mailbox folders">
            <Hidden smUp implementation="css">
              <Drawer
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                <DrawerItems />
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                <DrawerItems />
              </Drawer>
            </Hidden>
          </nav>
        </>
      )}
      <main className={isLoggedIn ? classes.contentLoggedIn : classes.content}>
        {isLoggedIn && (
          <>
            <Hidden smUp>
              <Box className={classes.toolbar} />
            </Hidden>
            <Hidden xsDown>
              <PageHeader title={title} description={subTitle} />
            </Hidden>
          </>
        )}
        {children}
      </main>
    </Box>
  );
};

export default Layout;
