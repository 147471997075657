import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const Privacy: FC = () => {
  return (
    <Grid container direction="column" justify="center">
      <Grid item>
        <Typography>some text</Typography>
      </Grid>
    </Grid>
  );
};

export default Privacy;
