export const { REACT_APP_BASE_API_URL } = process.env;
export const { REACT_APP_ENV } = process.env;

export const unAssignedAlarmsTitle: string[] = [
  'Ime peči',
  'Št. napak',
  'Kontaktna oseba',
];

export const assignedAlarmsTitle: string[] = [
  'ID naloga',
  'Nalog oddan v reševanje',
  'ID peči',
  'Dodeljen serviser',
];

export const statusTitle: string[] = [
  'Servis',
  'Datum in čas servisa',
  'Status',
];

export enum Status {
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export enum DisplayedStatus {
  IN_PROGRESS = 'V OBDELAVI',
  DONE = 'ZAKLJUČENO',
}

export const defaultTableFilterOptions = {
  display: true,
  filter: true,
  sort: true,
};

export enum DateFormat {
  DD_MM_YYYY = 'dd.MM.yyyy',
}

export enum FurnacePowerText {
  ON = 'PEČ JE VKLOPLJENA',
  OFF = 'PEČ JE IZKLOPLJENA',
}
