import React, { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useParams } from 'react-router';
import DashboardCard from '../../Layout/Dashboard/DashboardCard/DashboardCard';
import { ActionType } from '../../../state/types/actions';
import { useAppState } from '../../../state/state.context';
import { capitalize } from '../../../shared/utility';
import { RepairmenApiResponse, UserType } from '../../../api/api.interface';
import {
  RepairmanDetailsProps,
  repairmanRegions,
} from './repairmanDetails.interface';
import repairmanDetailsStyles from './repairmanDetailsStyles';
import UsersApi from '../../../api/users';
import Container from '../../Container/Container';
import LabeledCheckbox from '../../LabeledCheckbox/LabeledCheckbox';
import RepairmanApi from '../../../api/repairmans';
import { SnackbarSeverity } from '../../Layout/Snackbar/snackbar.interface';

const userApi = new UsersApi();
const repairmanApi = new RepairmanApi();

const useStyles = makeStyles((theme: Theme) => repairmanDetailsStyles(theme));

const RepairmanDetails: FC<RepairmanDetailsProps> = ({ match, location }) => {
  const classes = useStyles();
  const {
    state: { authToken },
    dispatch,
  } = useAppState();

  const { userEmail } = useParams();
  const [company, setCompany] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [checkedRegions, setCheckedRegions] = useState<string[]>([]);
  const [isCaretaker, setIsCaretaker] = useState(false);
  const [changed, setChanged] = useState(false);

  const desktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const resetState = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setCheckedRegions([]);
    setCompany('');
    setIsCaretaker(false);
    setChanged(false);
  };

  useEffect(() => {
    if (userEmail) {
      dispatch({ type: ActionType.SET_TITLE, title: '' });
      if (authToken) {
        dispatch({ type: ActionType.START_LOADING });
        repairmanApi
          .fetchRepairmanDetail(userEmail, authToken)
          .then((data: RepairmenApiResponse) => {
            setFirstName(data.firstName);
            setLastName(data.lastName);
            setEmail(data.email);
            setCompany(data.company ?? '');
            setCheckedRegions(data.regions ? data.regions : []);
            setIsCaretaker(data.isCaretaker ?? false);
            dispatch({ type: ActionType.SET_TITLE, title: `${data.email}` });
          })
          .catch((err) => {
            if (err.message === 'Unauthorized') {
              dispatch({ type: ActionType.LOGOUT });
            } else {
              dispatch({
                type: ActionType.SET_NOTIFICATION,
                message: err.message,
                severity: SnackbarSeverity.ERROR,
              });
            }
          })
          .finally(() => {
            dispatch({ type: ActionType.END_LOADING });
          });
      }
    } else {
      dispatch({
        type: ActionType.SET_TITLE,
        title: `Dodaj serviserja`,
      });
    }
  }, [authToken, dispatch, userEmail]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCaretaker(!isCaretaker);
    setChanged(true);
  };

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    setChanged(true);
    if (event.target.checked) {
      setCheckedRegions((prevRegions) => [...prevRegions, name]);
    } else {
      setCheckedRegions((prevRegions) =>
        prevRegions.filter((region) => region !== name)
      );
    }
  };

  const setTextFieldHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setStateHandler: (param: string) => void
  ): void => {
    setChanged(true);
    setStateHandler(event.target.value);
  };

  const onSaveHandler = (e: FormEvent) => {
    e.preventDefault();

    if (authToken) {
      dispatch({
        type: ActionType.START_LOADING,
      });
      const repairman = {
        firstName,
        lastName,
        email,
        isCaretaker,
        ...(company ? { company } : {}),
        regions: checkedRegions,
        type: UserType.REPAIRMAN,
      };
      Promise.resolve(
        !userEmail
          ? userApi.addUser(UserType.REPAIRMAN, repairman, authToken)
          : userApi.updateRepairman(repairman, userEmail, authToken)
      )
        .then(() => {
          dispatch({
            type: ActionType.SET_NOTIFICATION,
            message: userEmail ? `Serviser posodobljen` : `Serviser dodan`,
            severity: SnackbarSeverity.SUCCESS,
          });
          setChanged(false);
          if (!userEmail) {
            resetState();
          }
        })
        .catch((err) => {
          if (err.message === 'Unauthorized') {
            dispatch({ type: ActionType.LOGOUT });
          } else {
            dispatch({
              type: ActionType.SET_NOTIFICATION,
              message: err.message,
              severity: SnackbarSeverity.ERROR,
            });
          }
        })
        .finally(() => {
          dispatch({
            type: ActionType.END_LOADING,
          });
        });
    }
  };

  return (
    <>
      <Container>
        <DashboardCard loading={false}>
          <form onSubmit={onSaveHandler}>
            <Typography variant="h6" className={classes.subtitle}>
              Pravice serviserja
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="company"
                  name="company"
                  label={'Podjetje'}
                  value={company}
                  onChange={(e) => setTextFieldHandler(e, setCompany)}
                />
              </Grid>
              {desktop && <Grid item xs={12} md={8} />}
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="firstName"
                  name="firstName"
                  label={capitalize('ime')}
                  value={firstName}
                  required
                  onChange={(e) => setTextFieldHandler(e, setFirstName)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="lastName"
                  name="lastName"
                  label={capitalize('priimek')}
                  value={lastName}
                  required
                  onChange={(e) => setTextFieldHandler(e, setLastName)}
                />
              </Grid>
              {desktop && <Grid item xs={12} md={4} />}
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="email"
                  name="email"
                  label={'E-pošta'}
                  type="email"
                  autoComplete="email"
                  value={email}
                  required
                  disabled={!!userEmail}
                  onChange={(e) => setTextFieldHandler(e, setEmail)}
                />
              </Grid>
              <Grid item xs={12} md={8} />
              <Grid item xs={12} md={6}>
                <Box pt={'1rem'}>
                  <LabeledCheckbox
                    checked={isCaretaker}
                    onChange={handleChange}
                    name="isAdmin"
                    label={
                      <Typography variant="body1">Serviser skrbnik</Typography>
                    }
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="subtitle1" className={classes.regionTitle}>
                  Regije v katerih serviser upravlja svoje storitve: *
                </Typography>
              </Grid>
              <Grid item>
                {repairmanRegions.map((region: string, i: number) => (
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        checked={checkedRegions.includes(region)}
                        onChange={handleCheckbox}
                        name={region}
                        color="primary"
                      />
                    }
                    label={region}
                  />
                ))}
              </Grid>
            </Grid>
            <Box pt={'2rem'}>
              <Grid container spacing={2}>
                {desktop && <Grid item md={9} />}
                <Grid item xs={12} md={3}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    size="medium"
                    disabled={!checkedRegions.length || !changed}
                  >
                    Shrani
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </DashboardCard>
      </Container>
    </>
  );
};

export default RepairmanDetails;
