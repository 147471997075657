import { Theme } from '@material-ui/core';
import { colors } from '../theme';

const sharedStyles = (theme: Theme) => {
  return {
    downXsNo: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    downXsYes: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
      },
    },
    upSmNo: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    upSmYes: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    redWarning: { color: theme.palette.error.main },
    yellowWarning: { color: theme.palette.warning.main },
    greenSuccess: { color: theme.palette.success.main },
    subLabel: {
      color: theme.palette.text.secondary,
    },
    listItem: {
      backgroundColor: colors.lightDark,
      padding: '1.5rem',
      borderRadius: '17px',
      margin: '.5rem 0',
      cursor: 'pointer',
      border: `1px solid ${colors.lightDark}`,
      transition: 'all 200ms',
      '&:hover': {
        borderColor: colors.mainText,
      },
      [theme.breakpoints.down('sm')]: {
        padding: '1rem',
        margin: '.35rem 0',
      },
    },
    listItemSmall: {
      backgroundColor: colors.lightDark,
      padding: '.85rem',
      cursor: 'pointer',
      border: `1px solid ${colors.lightDark}`,
      transition: 'all 200ms',
      '&:hover': {
        borderColor: colors.mainText,
      },
    },
    whiteButton: {
      backgroundColor: colors.lightGray,
      color: colors.dark,
    },
  };
};

export default sharedStyles;
