import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { ActionType } from '../../../state/types/actions';
import { useAppState } from '../../../state/state.context';
import {
  UsersApiResponse,
  RegularListItem,
  InvestorListItem,
  UserType,
} from '../../../api/api.interface';
import SelectDropdown from '../../SelectDropdown/SelectDropdown';
import {
  DropdownSelect,
  SelectType,
} from '../../SelectDropdown/SelectDropdown.interface';
import UsersApi from '../../../api/users';
import { SelectUserProps } from './SelectUser.interface';
import { SnackbarSeverity } from '../../Layout/Snackbar/snackbar.interface';

const api = new UsersApi();

const SelectUser: React.FC<SelectUserProps> = ({
  selectUser,
  userType,
  placeholder,
  required = true,
  disabled = false,
  withoutFurnace = false,
  refreshInput,
}) => {
  const {
    state: { authToken },
    dispatch,
  } = useAppState();

  const [users, setUsers] = useState<RegularListItem[] | InvestorListItem[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (authToken) {
      setLoading(true);
      api
        .fetchUsers(userType, { limit: -1 }, authToken)
        .then((data: UsersApiResponse) => {
          if (withoutFurnace && userType === UserType.REGULAR) {
            setUsers(
              (data.users as RegularListItem[]).filter(
                (user: RegularListItem) => !user.furnaceId
              )
            );
          } else {
            setUsers(data.users);
          }
        })
        .catch((err) => {
          setUsers([]);
          if (err.message === 'Unauthorized') {
            dispatch({ type: ActionType.LOGOUT });
          } else {
            dispatch({
              type: ActionType.SET_NOTIFICATION,
              message: err.message,
              severity: SnackbarSeverity.ERROR,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [authToken, dispatch, userType, withoutFurnace]);

  return (
    <>
      <Grid container direction="column" justify="space-between">
        <SelectDropdown
          selectDropdown={selectUser as (select: DropdownSelect | null) => void}
          placeholder={placeholder}
          type={SelectType.USER}
          items={users}
          required={required}
          loading={loading}
          disabled={disabled}
          refreshInput={refreshInput}
        />
      </Grid>
    </>
  );
};

export default SelectUser;
