import { Theme } from '@material-ui/core';
import { colors } from '../../theme';

const workOrderStyles = (theme: Theme) => {
  return {
    wrapper: {
      padding: '0 2em 2em 2em',
    },
    alarm: {
      color: '#F57865',
    },
    desktopWidth: {
      width: '250px',
    },
    fullWidth: {
      width: '100%',
    },
    marginTop: {
      marginTop: theme.spacing(2),
    },
    dark: {
      backgroundColor: colors.dark,
    },
    menuContainer: {
      position: 'relative' as const,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    popper: {
      width: '100%',
      zIndex: 999,
    },
    buttonWrapper: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: '1rem',
      },
    },
    subtitle: {
      fontWeight: 700,
    },
    subtitleWrapper: {
      marginBottom: '1.5rem',
    },
    repairHeader: {
      padding: '.5rem .85rem',
      color: colors.lightGray,
      opacity: 0.6,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '0rem',
        margin: '0',
      },
    },
  };
};

export default workOrderStyles;
