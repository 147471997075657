import React, { FC } from 'react';
import {
  makeStyles,
  Theme,
  Grid,
  Typography,
  Box,
  Divider,
  useMediaQuery,
} from '@material-ui/core';
import { furnaceRepairsStyles } from './furnaceRepairsStyles';
import { FurnaceRepairsInterface } from './FurnaceRepairs.interface';
import { Repair } from '../../../api/api.interface';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { colors } from '../../../theme';
import { formatDate } from '../../../shared/utility';
import { DateFormat, Status } from '../../../constants/constants';
import CachedIcon from '@material-ui/icons/Cached';
import classNames from 'classnames';

const useClasses = makeStyles((theme: Theme) => furnaceRepairsStyles(theme));

const FurnaceRepairs: FC<FurnaceRepairsInterface> = ({ repairs, onClick }) => {
  const classes = useClasses();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const showDoneRepairs = repairs.map((repair: Repair, i: number) => (
    <Grid
      container
      direction="column"
      key={i}
      className={classNames({ [classes.wrapper]: onClick })}
      onClick={onClick ? () => onClick(repair) : undefined}
    >
      <Divider className={classes.divider} />
      <Grid container direction="row" className={classes.repair} spacing={1}>
        <Grid item xs={12} md={4}>
          {!isDesktop && (
            <Typography variant="body2" className={classes.repairHeader}>
              Servis:
            </Typography>
          )}
          <Typography variant="body1">{repair.number ?? '/'}</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          {!isDesktop && (
            <Typography variant="body2" className={classes.repairHeader}>
              Datum servisa:
            </Typography>
          )}
          <Typography variant="body1">
            {repair.date ? formatDate(repair.date, DateFormat.DD_MM_YYYY) : '/'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          {!isDesktop && (
            <Typography variant="body2" className={classes.repairHeader}>
              Status:
            </Typography>
          )}
          <Box display="inline-flex">
            <Typography variant="body1">
              {repair.status === Status.DONE ? 'ZAKLJUČENO' : 'V OBDELAVI'}
            </Typography>
            {repair.status === Status.DONE ? (
              <CheckCircleIcon
                className={classes.icon}
                htmlColor={colors.mainSuccess}
              />
            ) : (
              <CachedIcon className={classes.icon} htmlColor={colors.mainRed} />
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <>
      {isDesktop && (
        <Grid
          container
          direction="row"
          className={classes.repairHeader}
          spacing={1}
        >
          <Grid item xs={4}>
            <Typography variant="body2">Servis</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2">Datum servisa</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2">Status</Typography>
          </Grid>
        </Grid>
      )}
      {showDoneRepairs}
    </>
  );
};

export default FurnaceRepairs;
