import React from 'react';
import { makeStyles, Theme, TextField } from '@material-ui/core';
import { selectDropdownStyles } from './selectDropdownStyles';
import {
  SelectDropdownProps,
  DropdownSelect,
  SelectType,
  DropdownSize,
} from './SelectDropdown.interface';
import {
  Repairman,
  FurnaceItem,
  FurnaceType,
  UserListItem,
} from '../../api/api.interface';
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import { Workorder } from '../../containers/Workorder/workorder.interface';
import { isEmpty } from 'lodash';

const useClasses = makeStyles((theme: Theme) => selectDropdownStyles(theme));

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  selectDropdown,
  items,
  placeholder,
  type,
  focusPlaceholder,
  size = DropdownSize.MEDIUM,
  required = true,
  loading = false,
  disabled = false,
  refreshInput = '',
  initialValue,
}) => {
  const classes = useClasses();

  const defaultPlaceholder = `${placeholder} ${required ? '*' : '(neobvezno)'}`;

  const getLabel = (item: DropdownSelect) => {
    switch (type) {
      case SelectType.FURNACE:
        const furnace = item as FurnaceItem;
        return `${furnace.furnaceId} - ${furnace.name} - ${
          (furnace.renter || furnace.owner) ?? ''
        }`;
      case SelectType.REPAIRMAN:
        const repairman = item as Repairman;
        return `${repairman.firstName} ${repairman.lastName}`;
      case SelectType.WORKORDER:
        const alarm = item as Workorder;
        return `${alarm.device} - ${alarm.contactPerson} - ${alarm.furnaceAddress}`;
      case SelectType.FURNACE_TYPE:
        const furnaceType = item as FurnaceType;
        return `${furnaceType.type}`;
      case SelectType.REGION:
        const region = item as string;
        return region;
      case SelectType.USER:
        const user = item as UserListItem;
        return user.name;
      default:
        return '';
    }
  };

  const optionSelected = (option: DropdownSelect, value: DropdownSelect) => {
    switch (type) {
      case SelectType.REPAIRMAN:
      case SelectType.USER:
        return (option as UserListItem).email === (value as UserListItem).email;
      default:
        return option === value;
    }
  };

  return (
    <Autocomplete<DropdownSelect>
      options={items}
      classes={{
        option: classes.option,
      }}
      getOptionSelected={(option, value) => optionSelected(option, value)}
      {...(initialValue && {
        value: !isEmpty(initialValue) ? initialValue : null,
      })}
      key={refreshInput}
      loading={loading}
      loadingText={'Nalaganje'}
      disabled={disabled}
      size={size}
      autoHighlight
      getOptionLabel={(item: DropdownSelect) => getLabel(item)}
      renderOption={(item: DropdownSelect) => (
        <React.Fragment>{getLabel(item)}</React.Fragment>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          placeholder={defaultPlaceholder}
          onFocus={(e) =>
            (e.target.placeholder = focusPlaceholder || defaultPlaceholder)
          }
          onBlur={(e) => (e.target.placeholder = defaultPlaceholder)}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            autoComplete: 'off', // disable autocomplete and autofill,
          }}
          className={classes.root}
        />
      )}
      onChange={(event, value) => selectDropdown(value)}
    />
  );
};

export default SelectDropdown;
