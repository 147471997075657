import React, { FC, Fragment } from 'react';
import {
  makeStyles,
  Theme,
  Grid,
  useMediaQuery,
  Typography,
  Divider,
  Box,
} from '@material-ui/core';
import { ListHeader } from '../../containers/Workorder/workorder.interface';
import { ListItemTableProps } from './ListItemTable.interface';
import { listItemTableStyles } from './listItemTableStyles';
import ListItem from '../ListItem/ListItem';
import { ListItemTableData } from '../../api/api.interface';
import {
  ListItemInterface,
  ListItemSize,
} from '../ListItem/ListItem.interface';
import Loading from '../Loading/Loading';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useClasses = makeStyles((theme: Theme) => listItemTableStyles(theme));

const ListItemTable: FC<ListItemTableProps> = ({
  headers,
  data,
  icon,
  loading = false,
  onClick,
  clickData,
}) => {
  const classes = useClasses();
  const desktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const header = (item: ListHeader, key: number) => (
    <Grid item key={key} xs={item.flex}>
      <Typography variant="body2">{item.label}</Typography>
    </Grid>
  );

  const listItem = (
    data: ListItemTableData,
    headers: ListHeader[],
    key: number
  ) => {
    const items: ListItemInterface[] = headers.map((header: ListHeader) => {
      return {
        value: data[header.value] ?? '/',
        title: header.label,
        flex: header.flex,
        hideTitle: true,
      };
    });
    return (
      <Fragment key={key}>
        <Divider />
        <ListItem
          icon={icon}
          items={items}
          size={ListItemSize.SMALL}
          onClick={
            onClick && clickData ? () => onClick(data[clickData]) : undefined
          }
          endIcon={<ChevronRightIcon />}
        />
      </Fragment>
    );
  };

  return (
    <>
      {desktop && (
        <Grid
          container
          direction="row"
          wrap="nowrap"
          className={classes.repairHeader}
        >
          <Grid container direction="row">
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <Grid container direction="row" className={classes.padding}>
                {headers.map((item: ListHeader, i: number) => header(item, i))}
              </Grid>
            </Grid>
          </Grid>
          <Box display="flex" color="transparent" alignItems="center">
            <ChevronRightIcon />
          </Box>
        </Grid>
      )}
      {loading ? (
        <Box mt={'1.5rem'}>
          <Loading />
        </Box>
      ) : (
        data.map((listitem: ListItemTableData, i: number) =>
          listItem(listitem, headers, i)
        )
      )}
    </>
  );
};

export default ListItemTable;
