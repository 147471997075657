import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { SelectRepairmanProps } from './SelectRepairman.interface';
import { RepairmanResponse, Repairman } from '../../api/api.interface';
import { ActionType } from '../../state/types/actions';
import { useAppState } from '../../state/state.context';
import Api from '../../api/repairmans';
import SelectDropdown from '../SelectDropdown/SelectDropdown';
import {
  DropdownSelect,
  SelectType,
} from '../SelectDropdown/SelectDropdown.interface';
import { SnackbarSeverity } from '../Layout/Snackbar/snackbar.interface';

const api = new Api();

const SelectRepairman: React.FC<SelectRepairmanProps> = ({
  selectRepairman,
  refreshInput,
  required = true,
  disabled = false,
  hideTitle = false,
  isCaretaker = false,
  initialValue,
  region,
}) => {
  const {
    state: { authToken },
    dispatch,
  } = useAppState();

  const [repairmen, setRepairmen] = useState<Repairman[]>([]);

  useEffect(() => {
    if (authToken) {
      api
        .fetchRepairman({ limit: -1 }, authToken)
        .then((data: RepairmanResponse) => {
          if (data?.users) {
            if (isCaretaker) {
              setRepairmen(
                data.users.filter((rep: Repairman) => rep.isCaretaker)
              );
            } else {
              setRepairmen(
                region
                  ? data.users.sort(
                      (u1: Repairman, u2: Repairman) =>
                        (u2.regions?.includes(region) ? 1 : 0) -
                        (u1.regions?.includes(region) ? 1 : 0)
                    )
                  : data.users
              );
            }
          }
        })
        .catch((err) => {
          if (err.message === 'Unauthorized') {
            dispatch({ type: ActionType.LOGOUT });
          }
          dispatch({
            type: ActionType.SET_NOTIFICATION,
            message: err.message,
            severity: SnackbarSeverity.ERROR,
          });
        });
    }
  }, [authToken, dispatch, isCaretaker, region]);

  return (
    <>
      <Grid container direction="column" justify="space-between">
        {!hideTitle && <Typography variant="h5">Izberi serviserja</Typography>}
        <SelectDropdown
          selectDropdown={
            selectRepairman as (select: DropdownSelect | null) => void
          }
          placeholder="Izberi serviserja"
          type={SelectType.REPAIRMAN}
          items={repairmen}
          required={required}
          disabled={disabled}
          refreshInput={refreshInput}
          initialValue={initialValue}
        />
      </Grid>
    </>
  );
};

export default SelectRepairman;
