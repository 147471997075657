import { Theme } from '@material-ui/core';

export const searchInputStyles = (theme: Theme) => {
  return {
    search: {
      '& .MuiInputBase-root': {
        borderRadius: '.5rem',
      },
    },
  };
};
