import { Theme } from '@material-ui/core';
import { colors } from '../../theme';

const matertialItemsStyles = (theme: Theme) => {
  return {
    label: {
      color: 'rgba(250, 250, 250, 0.9)',
      opacity: 0.5,
      marginBottom: '.5em',
      marginRight: '.5em',
      fontSize: '14px',
    },
    data: {
      color: 'white',
      marginBottom: '.5em',
      fontSize: '14px',
    },
    dataMargin: {
      margin: '.5em .5em .5em 0',
    },
    textarea: {
      width: '100%',
      wordBreak: 'break-word' as const,
      backgroundColor: '#2D2D35',
      padding: '1em',
      borderRadius: '8px',
      fontSize: '16px',
      marginTop: '.5em',
    },
    header: {
      backgroundColor: colors.lightGray,
      color: colors.dark,
      padding: '.85rem 1.1rem',
      fontSize: '.75rem',
      fontWeight: 700,
    },
  };
};

export default matertialItemsStyles;
