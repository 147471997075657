import React, { FC } from 'react';
import {
  makeStyles,
  Theme,
  Grid,
  Typography,
  Box,
  useMediaQuery,
} from '@material-ui/core';
import { furnaceItemStyles } from './furnaceItemStyes';
import { FurnaceItemInterface } from './FurnaceItem.interface';
import { ReactComponent as Flame } from '../../../assets/flame.svg';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { colors } from '../../../theme';
import classNames from 'classnames';
import { useHistory } from 'react-router';

const useClasses = makeStyles((theme: Theme) => furnaceItemStyles(theme));

const FurnaceItem: FC<FurnaceItemInterface> = ({
  furnace,
}: FurnaceItemInterface) => {
  const classes = useClasses();
  const history = useHistory();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Grid
      container
      direction="row"
      className={classes.wrapper}
      onClick={() => history.push(`/furnace/${furnace.furnaceId}`)}
    >
      {isDesktop && (
        <Grid item xs={12} md={1} className={classes.value}>
          <Flame />
        </Grid>
      )}
      <Grid item xs={12} md={2} className={classes.value}>
        {!isDesktop && (
          <Typography variant="body1" className={classes.header}>
            ID peči:
          </Typography>
        )}
        <Typography variant="body1">{furnace.furnaceId || '/'}</Typography>
      </Grid>
      <Grid item xs={12} md={2} className={classes.value}>
        {!isDesktop && (
          <Typography variant="body1" className={classes.header}>
            Tip peči:
          </Typography>
        )}
        <Typography variant="body1">{furnace.name || '/'}</Typography>
      </Grid>
      <Grid item xs={12} md={2} className={classes.value}>
        {!isDesktop && (
          <Typography variant="body1" className={classes.header}>
            Naročnik:
          </Typography>
        )}
        <Typography variant="body1">{furnace.owner || '/'}</Typography>
      </Grid>
      <Grid item xs={12} md={3} className={classes.value}>
        {!isDesktop && (
          <Typography variant="body1" className={classes.header}>
            Uporabnik:
          </Typography>
        )}
        <Typography variant="body1">
          {(furnace.renter ?? furnace.owner) || '/'}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        className={classNames(classes.value, classes.status)}
      >
        {!isDesktop && (
          <Typography variant="body1" className={classes.header}>
            Status:
          </Typography>
        )}
        {furnace.lastSeenStatus ? (
          <CheckCircleIcon htmlColor={colors.mainSuccess} />
        ) : (
          <HighlightOffIcon htmlColor={colors.mainRed} />
        )}
        <Box ml={'.5rem'}>
          <Typography variant="body1" className={classes.statusText}>
            {furnace.lastSeenStatus ? 'Vključeno' : 'Izključeno'}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FurnaceItem;
