import React, {
  FC,
  useState,
  ChangeEvent,
  useEffect,
  useCallback,
} from 'react';
import {
  makeStyles,
  Theme,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { searchInputStyles } from './searchInputStyles';
import { SearchInputProps } from './SearchInput.interface';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router';
import { debounce } from 'lodash';

const useClasses = makeStyles((theme: Theme) => searchInputStyles(theme));

const SearchInput: FC<SearchInputProps> = ({
  label,
  initialValue,
  pathname,
  disabled = false,
}) => {
  const classes = useClasses();
  const history = useHistory();

  const [text, setText] = useState(initialValue);

  const delayed = useCallback(
    debounce(() => {
      if (text !== '') {
        history.push(`${pathname}?page=1&search=${text}`);
      } else {
        history.push(pathname);
      }
    }, 500),
    [text]
  );

  useEffect(() => {
    delayed();
    return delayed.cancel;
  }, [text, delayed]);

  useEffect(() => {
    setText(initialValue);
  }, [initialValue]);

  const handleSearch = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { value } = event.target;
    setText(value);
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      size="small"
      name={'search'}
      label={label}
      value={text}
      disabled={disabled}
      onChange={(e) => handleSearch(e)}
      className={classes.search}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchInput;
