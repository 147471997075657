import { Theme } from '@material-ui/core';
import { colors } from '../../../theme';

export const furnaceDetailStyles = (theme: Theme) => {
  return {
    subtitle: {
      fontWeight: 700,
      marginBottom: '1.5rem',
    },
    alarmName: {
      color: colors.lightGray,
      opacity: 0.6,
    },
    textField: {
      '& .MuiInputBase-root': {
        borderRadius: '8px',
      },
    },
    text: {
      color: colors.lightGray,
    },
    switchWrapper: {
      paddingLeft: '.5rem',
    },
    furnaceStatus: {
      fontWeight: 700,
    },
    switch: {
      marginLeft: '1rem',
    },
    repairmanWrapper: {
      margin: '2rem 0',
      paddingLeft: '.5rem',
    },
  };
};
