import { Theme } from '@material-ui/core';
import { colors } from '../../theme';

export const listItemTableStyles = (theme: Theme) => {
  return {
    repairHeader: {
      padding: '.5rem .85rem',
      color: colors.lightGray,
      opacity: 0.6,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '0rem',
        margin: '0',
      },
    },
    padding: {
      padding: '0 .25rem',
    },
  };
};
