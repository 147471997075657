import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Grid, Theme, makeStyles, Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DashboardCard from '../../../components/Layout/Dashboard/DashboardCard/DashboardCard';
import { ActionType } from '../../../state/types/actions';
import { useAppState } from '../../../state/state.context';
import AlarmsApi from '../../../api/alarms';
import { WODProps } from './workorderDetails.interface';
import {
  AssignedWorkordersResponse,
  Repair,
  Alarm,
} from '../../../api/api.interface';
import { Workorder } from '../workorder.interface';
import workOrderDetailStyles from './workOrderDetailsStyles';
import Container from '../../../components/Container/Container';
import { colors } from '../../../theme';
import ErrorIcon from '@material-ui/icons/Error';
import FurnaceRepairs from '../../../components/Furnace/FurnaceRepairs/FurnaceRepairs';
import Loading from '../../../components/Loading/Loading';
import { SnackbarSeverity } from '../../../components/Layout/Snackbar/snackbar.interface';

const api = new AlarmsApi();
const useStyles = makeStyles((theme: Theme) => workOrderDetailStyles(theme));

const WorkOrderDetails: FC<WODProps> = ({ match, location }) => {
  const {
    state: { authToken },
    dispatch,
  } = useAppState();
  const history = useHistory();
  const [repairs, setRepairs] = useState<Repair[]>([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { workorder } = location.state as { workorder: Workorder };

  useEffect(() => {
    if (authToken) {
      setLoading(true);
      api
        .fetchAssignedAlarms(-1, authToken, workorder.device)
        .then((data: AssignedWorkordersResponse) => {
          setRepairs(data.repairs as Repair[]);
        })
        .catch((err) => {
          if (err.message === 'Unauthorized') {
            dispatch({ type: ActionType.LOGOUT });
          }
          dispatch({
            type: ActionType.SET_NOTIFICATION,
            message: err.message,
            severity: SnackbarSeverity.ERROR,
          });
        })
        .finally(() => {
          setLoading(false);
        });
      dispatch({
        type: ActionType.SET_TITLE,
        title: `Napake na peči ${workorder.device}`,
      });
    }
  }, [workorder.device, dispatch, authToken, match, setRepairs, setLoading]);

  const assignHandler = () => {
    history.push({
      pathname: `/workorders/new/${workorder.device}`,
      state: { workorder },
    });
  };

  const showAlarms = workorder?.alarms?.map((alarm: Alarm, i: number) => (
    <Grid container direction="row" wrap="nowrap" key={i}>
      <Box mr={'1rem'}>
        <ErrorIcon htmlColor={colors.mainRed} />
      </Box>
      <Typography variant="body1" className={classes.alarmName}>
        {`${i + 1}. ${alarm.name}`}
      </Typography>
    </Grid>
  ));

  const openRepairDetails = (repair: Repair) => {
    history.push({
      pathname: `/workorders/details/${repair.id}`,
      state: { repair },
    });
  };

  return (
    <>
      <Container>
        <DashboardCard loading={false}>
          <Typography variant="h6" className={classes.subtitle}>
            Zadnje napake na peči
          </Typography>
          {workorder?.alarms.length ? (
            showAlarms
          ) : (
            <Typography variant="body1" className={classes.text}>
              Brez napak na peči
            </Typography>
          )}
          <Box mt={'1.5rem'}>
            <Grid container direction="row" justify="flex-end">
              <Grid item xs={12} md={'auto'}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => assignHandler()}
                >
                  Oddaj v reševanje
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DashboardCard>
        <DashboardCard loading={false}>
          <Typography variant="h6" className={classes.subtitle}>
            Pretekli servisi na objektu
          </Typography>
          {loading ? (
            <Box mt={'2rem'}>
              <Loading />
            </Box>
          ) : repairs.length ? (
            <FurnaceRepairs onClick={openRepairDetails} repairs={repairs} />
          ) : (
            <Typography variant="body1" className={classes.text}>
              Ni preteklih servisov
            </Typography>
          )}
        </DashboardCard>
      </Container>
    </>
  );
};

export default WorkOrderDetails;
