import {
  Repairman,
  FurnaceItem,
  FurnaceType,
  RegularListItem,
  InvestorListItem,
} from '../../api/api.interface';
import { Workorder } from '../../containers/Workorder/workorder.interface';

export type DropdownSelect =
  | Repairman
  | Workorder
  | FurnaceItem
  | FurnaceType
  | string
  | RegularListItem
  | InvestorListItem;

export type DropdownItems =
  | Repairman[]
  | Workorder[]
  | FurnaceItem[]
  | FurnaceType[]
  | string[]
  | RegularListItem[]
  | InvestorListItem[];

export enum SelectType {
  REPAIRMAN = 'repairman',
  WORKORDER = 'workorder',
  FURNACE = 'furnace',
  FURNACE_TYPE = 'furnaceType',
  REGION = 'region',
  USER = 'user',
  INVESTOR = 'investor',
}

export enum DropdownSize {
  SMALL = 'small',
  MEDIUM = 'medium',
}

export interface SelectDropdownProps {
  selectDropdown: (select: DropdownSelect | null) => void;
  items: DropdownItems;
  placeholder: string;
  type: SelectType;
  size?: DropdownSize;
  required?: boolean;
  focusPlaceholder?: string;
  loading?: boolean;
  disabled?: boolean;
  refreshInput?: string;
  initialValue?: DropdownSelect;
}
