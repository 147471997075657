import { UserType } from '../../../api/api.interface';

export interface UserDetailProps {
  userType?: UserType;
}

export enum UserInput {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  COMPANY = 'company',
  ADDRESS = 'address',
  POST = 'post',
  LOCATION = 'location',
  PHONE = 'phone',
  EMAIL = 'email',
  TAX_NUMBER = 'taxNumber',
}
