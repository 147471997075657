import { Theme } from '@material-ui/core';
import { colors } from '../../../theme';

export const addFurnaceStyles = (theme: Theme) => {
  return {
    container: {
      maxWidth: theme.breakpoints.values.lg,
    },
    wrapper: {
      marginTop: '2rem',
      [theme.breakpoints.down('xs')]: {
        marginTop: '1rem',
      },
    },
    subtitle: {
      fontWeight: 700,
      marginBottom: '1rem',
    },
    alarmName: {
      color: colors.lightGray,
      opacity: 0.6,
    },
    textField: {
      '& .MuiInputBase-root': {
        borderRadius: '8px',
      },
    },
    text: {
      color: colors.lightGray,
    },
    switchWrapper: {
      paddingLeft: '.5rem',
      marginTop: '1.5rem',
    },
    furnaceStatus: {
      fontWeight: 700,
    },
    switch: {
      marginLeft: '1rem',
    },
  };
};
