import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core';

import errorMessageStyles from './errorMessageStyles';
import { ErrorMessageProps } from './error.interface';

const useClasses = makeStyles((theme: Theme) => errorMessageStyles(theme));

const ErrorMessage: FC<ErrorMessageProps> = ({ message }) => {
  const classes = useClasses();
  return (
    <Box className={classes.zIndex}>
      <Typography className={classes.error}>{message}</Typography>
    </Box>
  );
};

export default ErrorMessage;
