import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import loadingStyles from './loadingStyles';

const useClasses = makeStyles(() => loadingStyles());

const Loading: FC = () => {
  const classes = useClasses();

  return (
    <Box className={classes.loading}>
      <CircularProgress />
    </Box>
  );
};

export default Loading;
