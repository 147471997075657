import { Theme } from '@material-ui/core';
import sharedStyles from '../../shared/styles';
import { colors } from '../../theme';

export const listItemStyles = (theme: Theme) => {
  const common = sharedStyles(theme);
  return {
    itemWrapper: common.listItem,
    itemWrapperSmall: common.listItemSmall,
    value: {
      margin: 'auto 0',
      wordWrap: 'break-word' as const,
      padding: '0 .25rem',
      display: 'inline-flex' as const,
    },
    itemHeader: {
      color: colors.lightGray,
      opacity: 0.6,
      marginRight: '.25rem',
    },
    itemShadow: {
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
    },
    disableHover: {
      '&:hover': {
        borderColor: colors.lightDark,
        cursor: 'initial',
      },
    },
    smallHover: {
      '&:hover': {
        borderColor: colors.lightDark,
        backgroundColor: colors.hoverBackground,
        cursor: 'pointer',
      },
    },
    text: {
      wordBreak: 'break-all' as const,
    },
  };
};
