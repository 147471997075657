import { Theme } from '@material-ui/core/styles';

const errorMessageStyles = (theme: Theme) => {
  return {
    error: {
      color: theme.palette.secondary.main,
    },
    zIndex: {
      zIndex: 1,
    },
  };
};

export default errorMessageStyles;
