import { Theme } from '@material-ui/core';
import { colors } from '../../../theme';

const newWorkOrderStyles = (theme: Theme) => {
  return {
    note: {
      borderRadius: 8,
      backgroundColor: colors.fieldBackground,
      border: 'none',
      color: colors.mainText,
      padding: '1em',
      fontSize: '1rem',
    },
    subtitle: {
      fontWeight: 700,
      marginBottom: '1rem',
    },
    text: {
      color: colors.lightGray,
    },
    alarmName: {
      color: colors.lightGray,
      opacity: 0.6,
    },
  };
};

export default newWorkOrderStyles;
