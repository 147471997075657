import { Theme } from '@material-ui/core';
import { colors } from '../../../theme';

export const alarmItemHeaderStyles = (theme: Theme) => {
  return {
    header: {
      margin: '.5rem 0 .5rem .25rem',
      color: colors.lightGray,
      opacity: 0.6,
    },
  };
};
