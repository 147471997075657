import React, { FC, useEffect, useState, useCallback } from 'react';
import { ActionType } from '../../state/types/actions';
import { useAppState } from '../../state/state.context';
import { makeStyles, Theme, Grid, Box, Button } from '@material-ui/core';
import { investorsStyles } from './investorsStyles';
import { useHistory } from 'react-router-dom';
import {
  InvestorListItem,
  UsersApiResponse,
  UserType,
} from '../../api/api.interface';
import WorkIcon from '@material-ui/icons/Work';
import Container from '../../components/Container/Container';
import SearchInput from '../../components/SearchInput/SearchInput';
import Loading from '../../components/Loading/Loading';
import Pagination from '../../components/Pagination/Pagination';
import UsersApi from '../../api/users';
import { ListItemInterface } from '../../components/ListItem/ListItem.interface';
import ListItem from '../../components/ListItem/ListItem';
import { SnackbarSeverity } from '../../components/Layout/Snackbar/snackbar.interface';

const useClasses = makeStyles((theme: Theme) => investorsStyles(theme));
const api = new UsersApi();

const Investors: FC = () => {
  const {
    state: { authToken },
    dispatch,
  } = useAppState();
  const history = useHistory();
  const classes = useClasses();

  const [page, setPage] = useState('');
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [investors, setInvestors] = useState<InvestorListItem[]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    setPage(queryParams.get('page') ?? '');
    setSearch(queryParams.get('search') ?? '');
  }, [history.location.search]);

  useEffect(() => {
    dispatch({ type: ActionType.SET_TITLE, title: 'Seznam investitorjev' });
  }, [dispatch]);

  const fetchUsersApi = useCallback(
    (token: string | undefined, page: string, search: string) => {
      if (token) {
        setLoading(true);
        api
          .fetchUsers(UserType.INVESTOR, { page, search, limit: 10 }, token)
          .then((data: UsersApiResponse) => {
            setInvestors(data.users as InvestorListItem[]);
            setTotalPages(data.totalPages);
            setCurrentPage(data.currentPage);
          })
          .catch((err) => {
            setInvestors([]);
            if (err.message === 'Unauthorized') {
              dispatch({ type: ActionType.LOGOUT });
            } else {
              dispatch({
                type: ActionType.SET_NOTIFICATION,
                message: err.message,
                severity: SnackbarSeverity.ERROR,
              });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    fetchUsersApi(authToken, page ?? '1', search ?? '');
  }, [authToken, page, search, fetchUsersApi]);

  const userListItem = (investor: InvestorListItem, key: number) => {
    const items: ListItemInterface[] = [
      {
        value: investor.name,
        title: 'Investitor',
        flex: 3,
        hideTitle: true,
      },
      {
        value: investor.company,
        title: 'Podjetje',
        flex: 3,
        hideTitle: false,
      },
      {
        value: investor.clientCount,
        title: 'Št. uporabnikov',
        flex: 6,
        hideTitle: false,
        align: 'flex-end',
      },
    ];
    return (
      <ListItem
        onClick={() => history.push(`/investor/${investor.email}`)}
        items={items}
        icon={<WorkIcon />}
        key={key}
      />
    );
  };

  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={12} md={4}>
            <SearchInput
              label="Išči investitorja"
              initialValue={search ?? ''}
              pathname="/investors"
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={5} />
          <Grid item xs={12} md={4} lg={3} className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              size="medium"
              disabled={loading}
              onClick={() => history.push('/investors/add')}
            >
              Dodaj investitorja
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Container>
        {loading ? (
          <Box mt={'2rem'}>
            <Loading />
          </Box>
        ) : (
          <>
            <Grid container direction="column">
              {investors.map((investor: InvestorListItem, i: number) =>
                userListItem(investor, i)
              )}
            </Grid>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              disabled={loading}
              pathname="/investors"
              search={search ?? ''}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default Investors;
