import { Theme } from '@material-ui/core';
import { colors } from '../../../theme';
import sharedStyles from '../../../shared/styles';

export const furnaceItemStyles = (theme: Theme) => {
  const common = sharedStyles(theme);
  return {
    wrapper: common.listItem,
    value: {
      margin: 'auto 0',
      wordWrap: 'break-word' as const,
      padding: '0 .25rem',
      display: 'inline-flex' as const,
    },
    status: {
      display: 'flex' as const,
      flexDirection: 'row' as const,
      flexWrap: 'wrap' as const,
    },
    statusText: {
      wordBreak: 'break-all' as const,
    },
    header: {
      color: colors.lightGray,
      opacity: 0.6,
      marginRight: '.3rem',
    },
  };
};
