import React, { FC, useEffect, useState, FormEvent, ChangeEvent } from 'react';
import {
  makeStyles,
  Theme,
  Box,
  Typography,
  Grid,
  TextField,
  useMediaQuery,
  Button,
} from '@material-ui/core';
import { userDetailStyles } from './userDetailStyles';
import { useParams, useHistory } from 'react-router';
import { ActionType } from '../../../state/types/actions';
import { useAppState } from '../../../state/state.context';
import UsersApi from '../../../api/users';
import {
  UserDetailResponse,
  UserType,
  FurnaceItem,
  FurnacePatch,
} from '../../../api/api.interface';
import Container from '../../../components/Container/Container';
import Loading from '../../../components/Loading/Loading';
import DashboardCard from '../../../components/Layout/Dashboard/DashboardCard/DashboardCard';
import UserFurnaces from '../../../components/Furnace/UserFurnaces/UserFurnaces';
import { v4 as uuidv4 } from 'uuid';
import { UserDetailProps, UserInput } from './UserDetail.interface';
import sharedStyles from '../../../shared/styles';
import { DashboardSize } from '../../../components/Layout/Dashboard/DashboardCard/dashboardCard.interface';
import { SnackbarSeverity } from '../../../components/Layout/Snackbar/snackbar.interface';

const useClasses = makeStyles((theme: Theme) => {
  return { ...userDetailStyles(theme), ...sharedStyles(theme) };
});
const api = new UsersApi();

const UserDetail: FC<UserDetailProps> = ({ userType }) => {
  const {
    state: { authToken },
    dispatch,
  } = useAppState();
  const classes = useClasses();
  const history = useHistory();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const { email } = useParams();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({} as UserDetailResponse);
  const [changed, setChanged] = useState(false);
  const [originalFurnaces, setOriginalFurnaces] = useState<FurnaceItem[]>([]);
  const [furnaces, setFurnaces] = useState<FurnaceItem[]>([]);
  const [refreshDropdown, setRefreshDropdown] = useState(uuidv4());

  const resetState = () => {
    const resetUser = userType ? { type: userType } : {};
    setUser(resetUser as UserDetailResponse);
    setChanged(false);
    setOriginalFurnaces([]);
    setFurnaces([]);
  };

  useEffect(() => {
    if (!userType) {
      dispatch({ type: ActionType.SET_TITLE, title: '' });
      if (authToken) {
        setLoading(true);
        api
          .fetchUserDetail(email, authToken)
          .then((data: UserDetailResponse) => {
            setUser(data);
            setOriginalFurnaces(data.furnaces);
            setFurnaces(data.furnaces);
            dispatch({
              type: ActionType.SET_TITLE,
              title: `${
                data.type === UserType.REGULAR ? 'Uporabnik' : 'Investitor'
              } ${data.firstName} ${data.lastName}`,
            });
          })
          .catch((err) => {
            setUser({} as UserDetailResponse);
            if (err.message === 'Unauthorized') {
              dispatch({ type: ActionType.LOGOUT });
            } else {
              dispatch({
                type: ActionType.SET_NOTIFICATION,
                message: err.message,
                severity: SnackbarSeverity.ERROR,
              });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setUser((prevState) => ({ ...prevState, type: userType }));
      dispatch({
        type: ActionType.SET_TITLE,
        title: `Dodaj ${
          userType === UserType.REGULAR ? 'uporabnika' : 'investitorja'
        }`,
      });
    }
  }, [email, authToken, dispatch, userType]);

  const handleTextFieldChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const textField = (
    label: string,
    value: string | undefined,
    name: string,
    textLength?: number,
    type: string = 'text'
  ) => (
    <Grid item xs={12} md={4}>
      <TextField
        key={label}
        variant="outlined"
        fullWidth
        label={label}
        name={name}
        type={type}
        value={value ?? ''}
        inputProps={
          textLength
            ? {
                maxLength: textLength,
                minLength: textLength,
              }
            : {}
        }
        className={classes.textField}
        disabled={!userType}
        required={setRequired(name, userType)}
        onChange={userType ? handleTextFieldChange : undefined}
      />
    </Grid>
  );

  const apiFurnaces = (newEmail?: string) => {
    const removedFurnaces = originalFurnaces.filter(
      (furnace: FurnaceItem) =>
        !furnaces.some(
          (fur: FurnaceItem) => furnace.furnaceId === fur.furnaceId
        )
    );
    const newFurnaces = furnaces.filter(
      (furnace: FurnaceItem) =>
        !originalFurnaces.some(
          (fur: FurnaceItem) => furnace.furnaceId === fur.furnaceId
        )
    );

    return Promise.all([
      ...removedFurnaces.map((furnace: FurnaceItem) => {
        return api.patchUserFurnace(
          newEmail ?? email,
          furnace.furnaceId,
          FurnacePatch.REMOVE_FURNACE,
          authToken
        );
      }),
      ...newFurnaces.map((furnace: FurnaceItem) => {
        return api.patchUserFurnace(
          newEmail ?? email,
          furnace.furnaceId,
          FurnacePatch.ADD_FURNACE,
          authToken
        );
      }),
    ]);
  };

  const handleFurnaceChange = (furnaces: FurnaceItem[]) => {
    setChanged(true);
    setFurnaces(furnaces);
  };

  const saveUser = (e: FormEvent) => {
    e.preventDefault();

    if (authToken) {
      dispatch({
        type: ActionType.START_LOADING,
      });
      Promise.resolve(
        userType
          ? api.addUser(
              userType,
              {
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                ...(user.type === UserType.REGULAR
                  ? {
                      location: user.location,
                      address: user.address,
                      post: user.post,
                      phone: user.phone,
                    }
                  : {}),
                ...(user.company ? { company: user.company } : {}),
                ...(user.taxNumber ? { taxNumber: user.taxNumber } : {}),
              },
              authToken
            )
          : null
      )
        .then(() => {
          return apiFurnaces(userType ? user.email : undefined);
        })
        .then(() => {
          dispatch({
            type: ActionType.SET_NOTIFICATION,
            message: userType
              ? `${
                  user.type === UserType.REGULAR ? 'Uporabnik' : 'Investitor'
                } dodan`
              : `Podatki o ${
                  user.type === UserType.REGULAR ? 'uporabniku' : 'investitorju'
                } posodobljeni`,
            severity: SnackbarSeverity.SUCCESS,
          });
          setRefreshDropdown(uuidv4());
          setOriginalFurnaces(furnaces);
          setChanged(false);
          if (userType) {
            resetState();
          }
        })
        .catch((err) => {
          if (err.message === 'Unauthorized') {
            dispatch({ type: ActionType.LOGOUT });
          } else {
            dispatch({
              type: ActionType.SET_NOTIFICATION,
              message: err.message,
              severity: SnackbarSeverity.ERROR,
            });
          }
        })
        .finally(() => {
          dispatch({
            type: ActionType.END_LOADING,
          });
        });
    }
  };

  const setRequired = (name: string, type: UserType | undefined) => {
    if (!type) {
      return false;
    }
    if (type === UserType.REGULAR) {
      return true;
    }
    switch (name) {
      case UserInput.FIRST_NAME:
      case UserInput.LAST_NAME:
      case UserInput.EMAIL:
        return true;
      default:
        return false;
    }
  };

  const disabledSave = !userType && (loading || !changed);

  return (
    <>
      <Container>
        {loading ? (
          <Box mt={'2rem'}>
            <Loading />
          </Box>
        ) : (
          <form onSubmit={saveUser}>
            <DashboardCard loading={false} size={DashboardSize.MEDIUM}>
              <Typography variant="h6" className={classes.subtitle}>
                {`Podatki o ${
                  user.type === UserType.REGULAR ? 'uporabniku' : 'investitorju'
                }`}
              </Typography>
              <Grid container spacing={2}>
                {textField('Ime', user.firstName, UserInput.FIRST_NAME)}
                {textField('Priimek', user.lastName, UserInput.LAST_NAME)}
                {isDesktop && <Grid item xs={12} md={4} />}
                {user.type === UserType.INVESTOR && (
                  <>
                    {textField('Podjetje', user.company, UserInput.COMPANY)}
                    {textField(
                      'Davčna',
                      user.taxNumber,
                      UserInput.TAX_NUMBER,
                      10
                    )}
                    {isDesktop && <Grid item xs={12} md={4} />}
                  </>
                )}
                {textField('Naslov', user.address, UserInput.ADDRESS)}
                {textField('Pošta', user.post, UserInput.POST)}
                {textField('Kraj', user.location, UserInput.LOCATION)}
                {textField('Telefonska številka', user.phone, UserInput.PHONE)}
                {textField(
                  'E-pošta',
                  user.email,
                  UserInput.EMAIL,
                  undefined,
                  'email'
                )}
                {isDesktop && <Grid item xs={12} md={4} />}
              </Grid>
            </DashboardCard>
            {userType !== UserType.REGULAR && (
              <UserFurnaces
                furnaces={furnaces}
                userType={user.type}
                setFurnaces={handleFurnaceChange}
                key={refreshDropdown}
              />
            )}
            <Box mt={'2.5rem'}>
              <Grid container spacing={2}>
                {isDesktop && <Grid item md={6} lg={8} />}
                <Grid item xs={12} md={3} lg={2}>
                  <Button
                    variant="contained"
                    fullWidth
                    size="medium"
                    disabled={loading}
                    className={classes.whiteButton}
                    onClick={() => history.goBack()}
                  >
                    Nazaj
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    size="medium"
                    type="submit"
                    disabled={disabledSave}
                  >
                    Shrani
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
      </Container>
    </>
  );
};

export default UserDetail;
