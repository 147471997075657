import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const Terms: FC = () => {
  return (
    <Grid container justify="center">
      <Grid
        item
        xs={6}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography variant="h5">MOBILE APP PRIVACY POLICY</Typography>
        <br />
        <Typography variant="body1">[Bioeneryunit]</Typography>
        <br />
        <Typography variant="body1">Last updated [2 December 2020]</Typography>
        <br />
        <Typography variant="body1">
          Valtis ogrevanje, družba za proizvodnjo, storitve in trgovine d.o.o.
          (“we” or “us” or “our”) respects the privacy of our users (“user” or
          “you”). This Privacy Policy explains how we collect, use, disclose,
          and safeguard your information when you visit our mobile application
          (the “Application”).
        </Typography>
        <br />
        <Typography variant="body1">
          Please read this Privacy Policy carefully. IF YOU DO NOT AGREE WITH
          THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE
          APPLICATION.
        </Typography>
        <br />
        <Typography variant="body1">
          We reserve the right to make changes to this Privacy Policy at any
          time and for any reason. We will alert you about any changes by
          updating the “Last updated” date of this Privacy Policy. You are
          encouraged to periodically review this Privacy Policy to stay informed
          of updates. You will be deemed to have been made aware of, will be
          subject to, and will be deemed to have accepted the changes in any
          revised Privacy Policy by your continued use of the Application after
          the date such revised Privacy Policy is posted.{' '}
        </Typography>
        <br />
        <Typography variant="body1">
          This Privacy Policy does not apply to the third-party online/mobile
          store from which you install the Application or make payments,
          including any in-game virtual items, which may also collect and use
          data about you. We are not responsible for any of the data collected
          by any such third party
        </Typography>
        <br />
        <Typography variant="h5">COLLECTION OF YOUR INFORMATION</Typography>
        <Typography variant="body1">
          We may collect information about you in a variety of ways. The
          information we may collect via the Application depends on the content
          and materials you use, and includes:{' '}
        </Typography>
        <br />
        <Typography variant="h6">Personal Data</Typography>
        <Typography variant="body1">
          Demographic and other personally identifiable information (such as
          your name and email address) that you voluntarily give to us when
          choosing to participate in various activities related to the
          Application, such as chat, posting messages in comment sections or in
          our forums, liking posts, sending feedback, and responding to surveys.
          If you choose to share data about yourself via your profile, online
          chat, or other interactive areas of the Application, please be advised
          that all data you disclose in these areas is public and your data will
          be accessible to anyone who accesses the Application.
        </Typography>
        <br />
        <Typography variant="h6">Derivative Data</Typography>
        <Typography variant="body1">
          Information our servers automatically collect when you access the
          Application, such as your native actions that are integral to the
          Application, including liking, re-blogging, or replying to a post, as
          well as other interactions with the Application and other users via
          server log files.{' '}
        </Typography>
        <br />
        <Typography variant="h6">Mobile Device Access</Typography>
        <Typography variant="body1">
          We may request access or permission to certain features from your
          mobile device, including your mobile device’s [bluetooth, calendar,
          camera, contacts, microphone, reminders, sensors, SMS messages, social
          media accounts, storage,] and other features. If you wish to change
          our access or permissions, you may do so in your device’s settings.
        </Typography>
        <br />
        <Typography variant="h6">Mobile Device Data</Typography>
        <Typography variant="body1">
          Device information such as your mobile device ID number, model, and
          manufacturer, version of your operating system, phone number, country,
          location, and any other data you choose to provide.
        </Typography>
        <br />
        <Typography variant="h6">Push Notifications</Typography>
        <Typography variant="body1">
          We may request to send you push notifications regarding your account
          or the Application. If you wish to opt-out from receiving these types
          of communications, you may turn them off in your device’s settings.
        </Typography>
        <br />
        <Typography variant="h5">USE OF YOUR INFORMATION</Typography>
        <Typography variant="body1">
          Having accurate information about you permits us to provide you with a
          smooth, efficient, and customized experience. Specifically, we may use
          information collected about you via the Application to:
          <li>Administer sweepstakes, promotions, and contests.</li>
          <li>Assist law enforcement and respond to subpoena.</li>
          <li>Create and manage your account.</li>
          <li>Email you regarding your account or order.</li>
          <li>
            Generate a personal profile about you to make future visits to the
            Application more personalized.
          </li>
          <li>Increase the efficiency and operation of the Application.</li>
          <li>
            Monitor and analyze usage and trends to improve your experience with
            the Application.
          </li>
          <li>Notify you of updates to the Application.</li>
          <li>Perform other business activities as needed.</li>
          <li>
            Request feedback and contact you about your use of the Application.
          </li>
          <li>Resolve disputes and troubleshoot problems.</li>
          <li>Respond to product and customer service requests.</li>
          <li>Send you a newsletter.</li>
          <li>Solicit support for the Application.</li>
        </Typography>
        <br />
        <Typography variant="h5">DISCLOSURE OF YOUR INFORMATION</Typography>
        <Typography variant="body1">
          We may share information we have collected about you in certain
          situations. Your information may be disclosed as follows:
        </Typography>
        <br />
        <Typography variant="h6">By Law or to Protect Rights</Typography>
        <Typography variant="body1">
          If we believe the release of information about you is necessary to
          respond to legal process, to investigate or remedy potential
          violations of our policies, or to protect the rights, property, and
          safety of others, we may share your information as permitted or
          required by any applicable law, rule, or regulation. This includes
          exchanging information with other entities for fraud protection and
          credit risk reduction.
        </Typography>
        <br />
        <Typography variant="h6">Third-Party Service Providers</Typography>
        <Typography variant="body1">
          We may share your information with third parties that perform services
          for us or on our behalf, including payment processing, data analysis,
          email delivery, hosting services, customer service, and marketing
          assistance.
        </Typography>
        <br />
        <Typography variant="h6">Marketing Communications</Typography>
        <Typography variant="body1">
          With your consent, or with an opportunity for you to withdraw consent,
          we may share your information with third parties for marketing
          purposes, as permitted by law.
        </Typography>
        <br />
        <Typography variant="h6">Affiliates</Typography>
        <Typography variant="body1">
          We may share your information with our affiliates, in which case we
          will require those affiliates to honor this Privacy Policy. Affiliates
          include our parent company and any subsidiaries, joint venture
          partners or other companies that we control or that are under common
          control with us.
        </Typography>
        <br />
        <Typography variant="h6">Business Partners</Typography>
        <Typography variant="body1">
          We may share your information with our business partners to offer you
          certain products, services or promotions.{' '}
        </Typography>
        <br />
        <Typography variant="h6">Other Third Parties</Typography>
        <Typography variant="body1">
          We may share your information with advertisers and investors for the
          purpose of conducting general business analysis. We may also share
          your information with such third parties for marketing purposes, as
          permitted by law.
        </Typography>
        <br />
        <Typography variant="h6">Sale or Bankruptcy</Typography>
        <Typography variant="body1">
          If we reorganize or sell all or a portion of our assets, undergo a
          merger, or are acquired by another entity, we may transfer your
          information to the successor entity. If we go out of business or enter
          bankruptcy, your information would be an asset transferred or acquired
          by a third party. You acknowledge that such transfers may occur and
          that the transferee may decline honor commitments we made in this
          Privacy Policy.{' '}
        </Typography>
        <br />
        <Typography variant="body1">
          We are not responsible for the actions of third parties with whom you
          share personal or sensitive data, and we have no authority to manage
          or control third-party solicitations. If you no longer wish to receive
          correspondence, emails or other communications from third parties, you
          are responsible for contacting the third party directly.
        </Typography>
        <br />
        <Typography variant="h5">TRACKING TECHNOLOGIES</Typography>
        <Typography variant="h6">Mobile application Analytics</Typography>
        <Typography variant="body1">
          We may also partner with selected third-party vendors, such as Google
          Firebase and others, to allow tracking technologies and remarketing
          services on the Application through the use of first party cookies and
          third-party cookies, to, among other things, analyze and track users’
          use of the Application, determine the popularity of certain content,
          and better understand online activity. By accessing the Application,
          you consent to the collection and use of your information by these
          third-party vendors. You are encouraged to review their privacy policy
          and contact them directly for responses to your questions. We do not
          transfer personal information to these third-party vendors.{' '}
        </Typography>
        <br />
        <Typography variant="h5">THIRD-PARTY WEBSITES</Typography>
        <Typography variant="body1">
          The Application may contain links to third-party websites and
          applications of interest, including advertisements and external
          services, that are not affiliated with us. Once you have used these
          links to leave the Application, any information you provide to these
          third parties is not covered by this Privacy Policy, and we cannot
          guarantee the safety and privacy of your information. Before visiting
          and providing any information to any third-party websites, you should
          inform yourself of the privacy policies and practices (if any) of the
          third party responsible for that website, and should take those steps
          necessary to, in your discretion, protect the privacy of your
          information. We are not responsible for the content or privacy and
          security practices and policies of any third parties, including other
          sites, services or applications that may be linked to or from the
          Application.
        </Typography>
        <br />
        <Typography variant="h5">SECURITY OF YOUR INFORMATION</Typography>
        <Typography variant="body1">
          We use administrative, technical, and physical security measures to
          help protect your personal information. While we have taken reasonable
          steps to secure the personal information you provide to us, please be
          aware that despite our efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other type of misuse. Any information
          disclosed online is vulnerable to interception and misuse by
          unauthorized parties. Therefore, we cannot guarantee complete security
          if you provide personal information.
        </Typography>
        <br />
        <Typography variant="h5">POLICY FOR CHILDREN</Typography>
        <Typography variant="body1">
          We do not knowingly solicit information from or market to children
          under the age of 13. If you become aware of any data we have collected
          from children under age 13, please contact us using the contact
          information provided below.
        </Typography>
        <br />
        <Typography variant="h5">OPTIONS REGARDING YOUR INFORMATION</Typography>
        <Typography variant="h6">Account Information</Typography>
        <Typography variant="body1">
          You may at any time review or change the information in your account
          or terminate your account by:
          <li>Logging into your account settings and updating your account</li>
          <li>Contacting us using the contact information provided below</li>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, some information may be retained in our files to prevent
          fraud, troubleshoot problems, assist with any investigations and/or
          comply with legal requirements.
        </Typography>
        <br />
        <Typography variant="h6">Emails and Communications</Typography>
        <Typography variant="body1">
          If you no longer wish to receive correspondence, emails, or other
          communications from us, you may opt-out by:
          <li>Contacting us using the contact information provided below</li>
          If you no longer wish to receive correspondence, emails, or other
          communications from third parties, you are responsible for contacting
          the third party directly.
        </Typography>
        <br />
        <Typography variant="h5">SLOVENIA PRIVACY RIGHTS</Typography>
        <Typography variant="body1">
          Under Regulation (EU) 2016/679 of the European Parliament and of the
          Council of 27 April 2016 on the protection of natural persons with
          regard to the processing of personal data and on the free movement of
          such data, and repealing Directive 95/46/EC (General Data Protection
          Regulation) and Slovenian law “Zakon o varstvu osebnih podatkov”
          (Uradni list RS, št. 94/07), users have the right to obtain from us
          without undue delay the rectification of inaccurate personal data
          concerning him or her. Taking into account the purposes of the
          processing, you shall have the right to have incomplete personal data
          completed, including by means of providing a supplementary statement.
          Users also have the right to obtain from us the erasure of personal
          data concerning him or her without undue delay. If you would like to
          make such a request, please submit your request in writing to us using
          the contact information provided below.
        </Typography>
        <br />
        <Typography variant="h5">ALEXA SKILLS</Typography>
        <Typography variant="body1">
          This privacy policy describes how Valtis ogrevanje, družba za
          proizvodnjo, storitve in trgovine d.o.o.. (“us” or “we”) protects your
          (“you”, “the user”) privacy and your data. Before using any of our
          Alexa Skills (“our skills”), please read this policy and our Alexa
          Skills Terms of Use, as well as the Alexa Terms of Use and the Privacy
          Policies by the Amazon Digital Services LLC (with its affiliates,
          “Amazon”). Regarding Amazon, “Alexa” means their Alexa Voice Service
          which includes third party services (like our skills) and other
          related Software. If you use one of our skills you fully agree to this
          privacy policy.
        </Typography>
        <br />
        <Typography variant="h6">General</Typography>
        <Typography variant="body1">
          When you use our skills you have to talk to Alexa. This voice input is
          sent to Amazon and us where we use it to understand what our skill
          should do for you. This is absolutely necessary for our service to
          give you an appropriate answer.
        </Typography>
        <br />
        <Typography variant="h6">Data</Typography>
        <Typography variant="body1">
          We never collect or share personal data with our skills.
        </Typography>
        <br />
        <Typography variant="body1">
          To improve our services we analyze automatically how often utterances
          are spoken and other analytics. This is done automatically by Amazon
          in the Amazon Developer Portal.
        </Typography>
        <br />
        <Typography variant="body1">
          For some of our skills it is necessary to save data for you in our
          databases. This data is only connected to your Alexa-UserId but never
          to your name or any other personal information. In the case of our
          “sittmacher” skill we save how many milliliters you drank per day.
          Otherwise we could not provide you this service.
        </Typography>
        <br />
        <Typography variant="h6">Changes</Typography>
        <Typography variant="body1">
          Our skills or parts of it may change or be updated at any time.
          Further, this Privacy Policy might change. You can find a link to this
          policy on the description page of the skill in the Alexa App or in the
          Alexa Store. Your continued use of our skill after changes of the
          Privacy Policy or the skill itself will be seen as your acceptance of
          both.
        </Typography>
        <br />
        <Typography variant="h5">CONTACT US</Typography>
        <Typography variant="body1">
          If you have questions or comments about this Privacy Policy, please
          contact us at:
        </Typography>
        <br />
        <Typography variant="body1">
          Valtis ogrevanje, družba za proizvodnjo, storitve in trgovine d.o.o.
        </Typography>
        <br />
        <Typography variant="body1">Cesta k Tamu 61 </Typography>
        <Typography variant="body1">2000 Maribor</Typography>
        <Typography variant="body1">
          Email: no-reply-bioenergyunit@drugivid.si{' '}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Terms;
