import { ReactNode } from 'react';

export enum DashboardSize {
  SMALL = 7,
  MEDIUM = 10,
  LARGE = 12,
}

export interface DashboardCardProps {
  children: ReactNode;
  label?: string;
  loading: boolean;
  isHidden?: boolean;
  size?: DashboardSize;
}
