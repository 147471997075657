const loadingStyles = () => {
  return {
    loading: {
      justifyContent: 'center',
      display: 'flex',
    },
  };
};

export default loadingStyles;
