import React, { FC } from 'react';
import {
  makeStyles,
  Theme,
  Grid,
  Divider,
  Typography,
} from '@material-ui/core';
import { alarmItemHeaderStyles } from './alarmItemHeaderStyles';

const useClasses = makeStyles((theme: Theme) => alarmItemHeaderStyles(theme));

const AlarmItemHeader: FC = () => {
  const classes = useClasses();

  return (
    <>
      <Grid container direction="row" spacing={1} className={classes.header}>
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <Typography variant="body2">ID peči</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">Št. napak</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">Kontaktna oseba</Typography>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

export default AlarmItemHeader;
