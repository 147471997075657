import { Theme, fade } from '@material-ui/core';
import { colors } from '../../../theme';

export const userFurnacesStyles = (theme: Theme) => {
  return {
    subtitle: {
      fontWeight: 700,
      marginBottom: '1.5rem',
    },
    deleteButton: {
      borderRadius: '12px',
      padding: '.25rem .75rem',
      color: colors.mainRed,
      fontWeight: 600,
      backgroundColor: colors.mainText,
      '&:hover': {
        backgroundColor: fade(colors.mainText, 0.8),
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '1rem',
      },
    },
  };
};
