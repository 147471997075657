import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { Box, Collapse } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { makeStyles, Theme } from '@material-ui/core/styles';

import drawerStyles from './drawerStyles';
import { sidebarItems } from '../../../constants/sidebarItems';
import {
  SidebarItemInterface,
  SidebarSubItemInterface,
} from '../../../constants/sidebarItemInterface';

const useClasses = makeStyles((theme: Theme) => drawerStyles(theme));

const DrawerItems: React.FC = () => {
  const classes = useClasses();
  const history = useHistory();
  const location = useLocation();
  const [items, setItems] = useState(sidebarItems);

  const logoutHandler = () => {
    history.push('/logout');
  };

  useEffect(() => {
    setItems((prevItems) =>
      prevItems.map((item: SidebarItemInterface) => {
        item.expanded = item.subitems?.some(
          (subitem: SidebarSubItemInterface) =>
            subitem.href ? location.pathname.includes(subitem.href) : false
        );
        return item;
      })
    );
  }, [location.pathname]);

  const listItemOnClick = (clickedItem: SidebarItemInterface) => {
    if (clickedItem.href) {
      changeLocation(clickedItem);
    } else {
      setItems((prevItems) =>
        prevItems.map((item: SidebarItemInterface) => {
          if (clickedItem.id === item.id) {
            item.expanded = !item.expanded;
          }
          return item;
        })
      );
    }
  };

  const changeLocation = (
    item: SidebarItemInterface | SidebarSubItemInterface
  ) => {
    if (item.href) {
      history.push(item.href);
    }
  };

  const sidebarItemsMapped = items.map((item: SidebarItemInterface) => (
    <Fragment key={item.id}>
      <ListItem
        button
        className={classes.listItem}
        onClick={() => listItemOnClick(item)}
        selected={item.href ? location.pathname.includes(item.href) : false}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.name} />
        {item.expanded ? <ExpandMoreIcon /> : <NavigateNextIcon />}
      </ListItem>
      <Collapse in={item.expanded}>
        {item.subitems?.map((subitem: SidebarSubItemInterface, i: number) => (
          <ListItem
            button
            key={i}
            className={classes.subListItem}
            onClick={() => changeLocation(subitem)}
            selected={
              subitem.href ? location.pathname.includes(subitem.href) : false
            }
          >
            <ListItemIcon>{subitem.icon}</ListItemIcon>
            <ListItemText primary={subitem.name} />
            <NavigateNextIcon />
          </ListItem>
        ))}
      </Collapse>
    </Fragment>
  ));

  return (
    <Box className={classes.list}>
      <Box className={classes.toolbar}>
        <Box ml={2} mt={5} mb={5}>
          <Typography
            variant="h6"
            align="center"
            style={{ fontWeight: 'bold' }}
          >
            BioEnergyUnit
          </Typography>
        </Box>
      </Box>
      <List>{sidebarItemsMapped}</List>
      <ListItem
        button
        key="Logout"
        onClick={logoutHandler}
        className={classes.listItem}
      >
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Odjava" />
      </ListItem>
    </Box>
  );
};

export default DrawerItems;
