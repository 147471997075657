import React from 'react';
import 'fontsource-roboto';
import { Redirect, Route, Switch } from 'react-router-dom';

import Layout from './containers/Layout/Layout';
import { StateProvider } from './state/StateProvider';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import Login from './containers/Login/Login';
import Logout from './routes/Logout';
import WorkOrder from './containers/Workorder/Workorder';
import Repairman from './containers/Repairmans/Repairman';
import FurnaceList from './containers/Furnaces/FurnaceList';
import WorkOrderDetails from './containers/Workorder/WorkorderDetails/WorkorderDetails';
import NewWorkOrder from './containers/Workorder/NewWorkOrder/NewWorkOrder';
import RepairmanDetails from './components/Repairman/RepairmanDetails/RepairmanDetails';
import Terms from './components/Terms&Privacy/Terms';
import Privacy from './components/Terms&Privacy/Privacy';
import FinishedWorkorderDetails from './containers/Workorder/WorkorderDetails/FinishedWorkorderDetails/FinishedWorkorderDetails';
import CreateWorkOrder from './containers/Workorder/CreateWorkOrder/CreateWorkOrder';
import FurnaceDetail from './containers/Furnaces/FurnaceDetail/FurnaceDetail';
import Investors from './containers/Investors/Investors';
import Users from './containers/Users/Users';
import AddFurnace from './containers/Furnaces/AddFurnace/AddFurnace';
import UserDetail from './containers/Users/UserDetail/UserDetail';
import GlobalLoading from './containers/GlobalLoading/GlobalLoading';
import { UserType } from './api/api.interface';
import WorkordersList from './containers/WorkordersList/WorkordersList';
import SnackbarMessage from './components/Layout/Snackbar/Snackbar';

const App: React.FC = () => {
  return (
    <div className="App">
      <StateProvider>
        <Layout>
          <GlobalLoading />
          <SnackbarMessage />
          <Switch>
            <AuthenticatedRoute
              path="/new-workorder"
              component={CreateWorkOrder}
            />
            <AuthenticatedRoute
              path="/workorders/new/:id"
              component={NewWorkOrder}
            />
            <AuthenticatedRoute
              path="/workorders/:state(alarms|in-progress|finished)"
              component={WorkordersList}
              exact
            />
            <AuthenticatedRoute
              path="/workorders/unattended/details/:id"
              component={WorkOrderDetails}
            />
            <AuthenticatedRoute
              path="/workorders/details/:id"
              component={FinishedWorkorderDetails}
            />
            <AuthenticatedRoute
              path="/workorders"
              component={WorkOrder}
              exact
            />
            <AuthenticatedRoute
              path="/repairman/:userEmail"
              component={RepairmanDetails}
            />
            <AuthenticatedRoute
              path="/repairmen/add"
              component={RepairmanDetails}
            />
            <AuthenticatedRoute path="/repairmen" component={Repairman} exact />
            <AuthenticatedRoute path="/users" component={Users} exact />
            <AuthenticatedRoute
              path="/users/add"
              render={(props) => (
                <UserDetail {...props} userType={UserType.REGULAR} />
              )}
            />
            <AuthenticatedRoute path="/user/:email" component={UserDetail} />
            <AuthenticatedRoute path="/investors" component={Investors} exact />
            <AuthenticatedRoute
              path="/investors/add"
              render={(props) => (
                <UserDetail {...props} userType={UserType.INVESTOR} />
              )}
            />
            <AuthenticatedRoute
              path="/investor/:email"
              component={UserDetail}
            />
            <AuthenticatedRoute
              path="/furnaces"
              component={FurnaceList}
              exact
            />
            <AuthenticatedRoute path="/furnace/:id" component={FurnaceDetail} />
            <AuthenticatedRoute path="/furnaces/add" component={AddFurnace} />
            <AuthenticatedRoute path="/logout" exact component={Logout} />
            <Route path="/terms" exact component={Terms} />
            <Route path="/privacy" exact component={Privacy} />
            <Route path="/login" exact component={Login} />
            <Route
              exact
              path="/"
              render={() => <Redirect to="/workorders" />}
            />
            <Route render={() => <h1>Not found</h1>} />
          </Switch>
        </Layout>
      </StateProvider>
    </div>
  );
};

export default App;
