import BaseApi from './base';
import {
  Method,
  UserType,
  UsersApiResponse,
  PaginationParams,
  UserDetailResponse,
  FurnacePatch,
  AddUserBody,
  RepairmenApiResponse,
} from './api.interface';
import { cleanQueryParams } from '../shared/utility';

export default class UsersApi extends BaseApi {
  async updateRepairman(
    repairman: RepairmenApiResponse,
    email: string,
    token?: string
  ): Promise<void> {
    return this.makeRequest(
      Method.POST,
      `profile/admin/${email}`,
      JSON.stringify(repairman),
      {
        Authorization: token ? `Bearer ${token}` : '',
      }
    );
  }

  async fetchUsers(
    userType: UserType,
    pagination: PaginationParams,
    token?: string
  ): Promise<UsersApiResponse> {
    return this.makeRequest(
      Method.GET,
      `users${cleanQueryParams({
        ...pagination,
        userType,
      })}`,
      null,
      {
        Authorization: token ? `Bearer ${token}` : '',
      }
    );
  }

  async fetchUserDetail(
    email: string,
    token?: string
  ): Promise<UserDetailResponse> {
    return this.makeRequest(Method.GET, `users/${email}`, null, {
      Authorization: token ? `Bearer ${token}` : '',
    });
  }

  async patchUserFurnace(
    email: string,
    furnaceId: string,
    furnacePatch: FurnacePatch,
    token?: string
  ): Promise<UserDetailResponse> {
    return this.makeRequest(
      Method.PATCH,
      `users/${email}/${furnacePatch}/${furnaceId}`,
      null,
      {
        Authorization: token ? `Bearer ${token}` : '',
      }
    );
  }

  async addUser(
    type: UserType,
    body: AddUserBody,
    token: string
  ): Promise<void> {
    return this.makeRequest(
      Method.POST,
      `users`,
      JSON.stringify({ ...body, type }),
      {
        Authorization: token ? `Bearer ${token}` : '',
      }
    );
  }
}
