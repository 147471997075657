import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAppState } from '../state/state.context';

const AuthenticatedRoute: React.FC<RouteProps> = React.memo((props) => {
  const {
    state: { isLoggedIn },
  } = useAppState();

  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }

  return <Route {...props} />;
});

export default AuthenticatedRoute;
