import React, { FC, useEffect, useState, useCallback } from 'react';
import { Grid, Theme, makeStyles, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router';
import { ActionType } from '../../state/types/actions';
import { useAppState } from '../../state/state.context';
import Api from '../../api/repairmans';
import {
  Repairman as RepairmanI,
  RepairmanResponse,
} from '../../api/api.interface';
import Loading from '../../components/Loading/Loading';
import Container from '../../components/Container/Container';
import SearchInput from '../../components/SearchInput/SearchInput';
import { usersStyles } from '../Users/usersStyles';
import { ListItemInterface } from '../../components/ListItem/ListItem.interface';
import ListItem from '../../components/ListItem/ListItem';
import PersonIcon from '@material-ui/icons/Person';
import Pagination from '../../components/Pagination/Pagination';
import { SnackbarSeverity } from '../../components/Layout/Snackbar/snackbar.interface';

const api = new Api();
const useClasses = makeStyles((theme: Theme) => usersStyles(theme));

const Repairman: FC = () => {
  const {
    state: { authToken },
    dispatch,
  } = useAppState();
  const classes = useClasses();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState('');
  const [search, setSearch] = useState('');
  const [users, setUsers] = useState<RepairmanI[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    setPage(queryParams.get('page') ?? '');
    setSearch(queryParams.get('search') ?? '');
  }, [history.location.search]);

  useEffect(() => {
    dispatch({ type: ActionType.SET_TITLE, title: 'Seznam serviserjev' });
  }, [dispatch]);

  const fetchRepairmenApi = useCallback(
    (token: string | undefined, page: string, search: string) => {
      if (token) {
        setLoading(true);
        api
          .fetchRepairman({ page, search, limit: 10 }, token)
          .then((data: RepairmanResponse) => {
            if (data?.users) {
              setTotalPages(data.totalPages);
              setCurrentPage(data.currentPage);
              setUsers(data.users);
            }
          })
          .catch((err) => {
            setUsers([]);
            if (err.message === 'Unauthorized') {
              dispatch({ type: ActionType.LOGOUT });
            } else {
              dispatch({
                type: ActionType.SET_NOTIFICATION,
                message: err.message,
                severity: SnackbarSeverity.ERROR,
              });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    fetchRepairmenApi(authToken, page ?? '1', search ?? '');
  }, [authToken, page, search, fetchRepairmenApi]);

  const repairmanListItem = (repairman: RepairmanI, key: number) => {
    const items: ListItemInterface[] = [
      {
        value: `${repairman.firstName} ${repairman.lastName}`,
        title: 'Serviser',
        flex: 6,
        hideTitle: true,
      },
      {
        value: repairman.completedWorkOrders?.toString() ?? '',
        title: 'Število opravljenih servisov',
        flex: 6,
        hideTitle: false,
        align: 'flex-end',
      },
    ];
    return (
      <ListItem
        onClick={() => history.push(`/repairman/${repairman.email}`)}
        items={items}
        icon={<PersonIcon />}
        key={key}
      />
    );
  };

  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={12} md={4}>
            <SearchInput
              label="Išči serviserja"
              initialValue={search ?? ''}
              pathname="/repairmen"
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={5} />
          <Grid item xs={12} md={4} lg={3} className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              size="medium"
              disabled={loading}
              onClick={() => history.push('/repairmen/add')}
            >
              Dodaj serviserja
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Container>
        {loading ? (
          <Box mt={'2rem'}>
            <Loading />
          </Box>
        ) : (
          <>
            <Grid container direction="column">
              {users.map((user: RepairmanI, i: number) =>
                repairmanListItem(user, i)
              )}
            </Grid>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              disabled={loading}
              pathname="/repairmen"
              search={search ?? ''}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default Repairman;
