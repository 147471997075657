import { Theme } from '@material-ui/core';
import { colors } from '../../../theme';

export const createWorkOrderStyles = (theme: Theme) => {
  return {
    textField: {
      '& .MuiInputBase-root': {
        borderRadius: '8px',
      },
    },
    selectWrapper: {
      flexGrow: 1,
    },
    text: {
      color: colors.lightGray,
    },
    note: {
      borderRadius: 8,
      backgroundColor: colors.fieldBackground,
      border: 'none',
      color: 'white',
      padding: '1em',
      fontSize: '1rem',
    },
    buttonWrapper: {
      marginTop: '2rem',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '2rem',
      },
    },
  };
};
