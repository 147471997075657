import React, { Fragment } from 'react';
import { MaterialItemsProps } from './MaterialItems.interface';
import {
  makeStyles,
  Theme,
  Grid,
  Divider,
  useMediaQuery,
} from '@material-ui/core';
import materialItemsStyles from './materialItemsStyles';
import { RepairMaterial } from '../../api/api.interface';
import {
  ListItemInterface,
  ListItemSize,
} from '../ListItem/ListItem.interface';
import ListItem from '../ListItem/ListItem';

const useStyles = makeStyles((theme: Theme) => materialItemsStyles(theme));

const MaterialItems: React.FC<MaterialItemsProps> = ({ materials }) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const header = (
    <Grid container direction="row" className={classes.header}>
      <Grid item xs={1}>
        Z. št.
      </Grid>
      <Grid item xs={2}>
        Šifra
      </Grid>
      <Grid item xs={4}>
        Naziv
      </Grid>
      <Grid item xs={2}>
        Količina
      </Grid>
      <Grid item xs={2}>
        EM
      </Grid>
      <Grid item xs={1}>
        Garancija
      </Grid>
    </Grid>
  );

  const materialItem = (material: RepairMaterial, key: number) => {
    const items: ListItemInterface[] = [
      {
        value: (key + 1).toString(),
        title: 'Z. št.',
        flex: 1,
        hideTitle: true,
      },
      {
        value: material.id,
        title: 'Šifra',
        flex: 2,
        hideTitle: true,
      },
      {
        value: material.name,
        title: 'Naziv',
        flex: 4,
        hideTitle: true,
      },
      {
        value: material.amount.toString(),
        title: 'Količina',
        flex: 2,
        hideTitle: true,
      },
      {
        value: material.units,
        title: 'EM',
        flex: 2,
        hideTitle: true,
      },
      {
        value: material.guarantee ? 'DA' : 'NE',
        title: 'Garancija',
        flex: 1,
        hideTitle: true,
      },
    ];
    return (
      <Fragment key={key}>
        <Divider />
        <ListItem items={items} disableHover size={ListItemSize.SMALL} />
      </Fragment>
    );
  };

  return (
    <>
      {isDesktop && header}
      {materials.map((material: RepairMaterial, i: number) =>
        materialItem(material, i)
      )}
    </>
  );
};

export default MaterialItems;
