import React, { FC, useEffect } from 'react';
import { Redirect } from 'react-router';

import { useAppState } from '../state/state.context';
import { ActionType } from '../state/types/actions';

const Logout: FC = () => {
  const { dispatch } = useAppState();

  useEffect(() => {
    dispatch({ type: ActionType.LOGOUT });
  }, [dispatch]);

  return <Redirect to="/login" />;
};

export default Logout;
