import { Theme } from '@material-ui/core/styles';

const dashboardCardStyles = (theme: Theme) => {
  return {
    box: {
      borderRadius: '15px',
      height: 'auto',
      maxWidth: theme.breakpoints.values.lg,
      backgroundColor: theme.palette.background.paper,
      marginTop: theme.spacing(2),
      padding: '1.5rem',
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
        marginBottom: theme.spacing(2),
        padding: '1rem',
      },
    },
    boxTitle: {
      padding: '2em 3em 1em 3em',
    },
    titleAndLoading: {
      display: 'flex',
    },
    loading: {
      paddingLeft: '20px',
      marginTop: '10px',
    },
  };
};

export default dashboardCardStyles;
