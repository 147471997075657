import React from 'react';

import AssignmentIcon from '@material-ui/icons/Assignment';
import PersonIcon from '@material-ui/icons/Person';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import WorkIcon from '@material-ui/icons/Work';

import { SidebarItemInterface } from './sidebarItemInterface';

export const sidebarItems: SidebarItemInterface[] = [
  {
    id: 1,
    name: 'Nalogi',
    icon: <AssignmentIcon />,
    href: '/workorders',
    expanded: false,
  },
  {
    id: 2,
    name: 'Serviserji',
    icon: <PersonIcon />,
    href: '/repairmen',
    expanded: false,
  },
  {
    id: 3,
    name: 'Stranke',
    icon: <PeopleAltIcon />,
    expanded: false,
    subitems: [
      {
        name: 'Uporabniki',
        icon: <AccessibilityIcon />,
        href: '/users',
      },
      {
        name: 'Investitorji',
        icon: <WorkIcon />,
        href: '/investors',
      },
    ],
  },
  {
    id: 4,
    name: 'Peči',
    icon: <WhatshotIcon />,
    href: '/furnaces',
    expanded: false,
  },
];
