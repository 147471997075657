import { useContext, createContext } from 'react';

import { StateContextType } from './types/state';

export const StateContext = createContext<StateContextType>({
  isLoggedIn: false,
  title: '',
  subTitle: '',
  globalLoading: false,
  notificationState: null,
} as any);

export function useAppState(): StateContextType {
  return useContext(StateContext);
}
