import { createMuiTheme } from '@material-ui/core/styles';

export const colors = {
  dark: '#242429',
  lightDark: '#3A3A42',
  mainBlue: '#4F6999',
  mainRed: '#D42936',
  mainText: '#FAFAF9',
  mainWarning: '#DDAF4D',
  mainSuccess: '#3CC080',
  fontSubLabel: '#8E8E93',
  fieldBackground: '#2D2D35',
  errorListItem: '#F57865',
  errorRed: '#F44335',
  successGreen: '#4CAF50',
  lightGray: '#E0E0DF',
  hoverBackground: '#4b4c55',
};

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    background: {
      default: colors.dark,
      paper: colors.lightDark,
    },
    primary: {
      main: colors.mainBlue,
    },
    secondary: {
      main: colors.mainRed,
      contrastText: colors.mainText,
    },
    error: {
      main: colors.errorRed,
      contrastText: colors.mainText,
    },
    warning: {
      main: colors.mainWarning,
      contrastText: colors.mainText,
    },
    success: {
      main: colors.successGreen,
    },
    text: {
      primary: colors.mainText,
      secondary: colors.fontSubLabel,
    },
  },
  typography: {
    subtitle2: {
      fontWeight: 400,
      fontSize: '0.7rem',
    },
  },
  overrides: {
    MuiTableRow: {
      root: {
        '&:last-child td': {
          borderBottom: 0,
        },
      },
    },
  },
});

export default theme;
