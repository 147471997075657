import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box, Theme, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import dashboardCardStyles from './dashboardCardStyles';
import Loading from '../../../Loading/Loading';
import { DashboardCardProps, DashboardSize } from './dashboardCard.interface';

const useStyles = makeStyles((theme: Theme) => dashboardCardStyles(theme));

const DashboardCard: React.FC<DashboardCardProps> = ({
  label,
  loading,
  isHidden,
  children,
  size = DashboardSize.LARGE,
}) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} md={size}>
        <Box className={classes.box}>
          <Box className={label && classes.boxTitle}>
            <Box className={classes.titleAndLoading}>
              {label && (
                <Typography variant="h5" hidden={isHidden}>
                  {label}
                </Typography>
              )}
              {loading && (
                <Box className={classes.loading}>
                  <Loading />
                </Box>
              )}
            </Box>
          </Box>
          <Box>{children}</Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DashboardCard;
