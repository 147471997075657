import { Workorder } from '../containers/Workorder/workorder.interface';

export enum UserType {
  ADMIN = 'admin',
  REPAIRMAN = 'repairman',
  REGULAR = 'regular',
  INVESTOR = 'investor',
}

export enum Method {
  POST = 'POST',
  PATCH = 'PATCH',
  GET = 'GET',
  PUT = 'PUT',
}

export enum FurnacePatch {
  ADD_FURNACE = 'addFurnace',
  REMOVE_FURNACE = 'removeFurnace',
}

export enum FurnacePower {
  ON = 'on',
  OFF = 'off',
}

export enum ErrorMessage {
  GENERIC = 'Prišlo je do napake',
  USER_EXISTS = 'Uporabnik s tem elektronskim naslovom že obstaja',
  FURNACE_EXISTS = 'Peč s tem id-jem že obstaja',
}

export interface LoginBody {
  email: string;
  password?: string;
}

export interface Roles {
  isAdmin: boolean;
  hisOwnFurnaces: boolean;
  slovenianFurnaces: boolean;
}

export interface RegisterBody extends LoginBody {
  firstName: string;
  lastName: string;
  roles?: Roles;
  regions?: string[];
}

export interface Header {
  Authorization: string;
}

export interface AssignAlarmResponse {
  message: string;
}

interface BaseAlarm {
  id: string;
  name: string;
  severity: number;
}

export interface Alarm extends BaseAlarm {
  receivedTime: number;
  receivedTimeStr: string;
  fixTime: number;
  assigned: boolean;
  techNotes?: string;
}

export interface AlarmApiResponse {
  device: string;
  alarms: Alarm[];
  limit: number;
  total: number;
}

export interface BaseFurnace {
  createdAt: string;
  description: string;
  furnaceId: string;
  income: number;
  lastSeenStatus: boolean;
  location: string;
  name: string;
  power: string;
  updatedAt: string;
  region?: string;
  address?: string;
}

export interface FurnaceItem extends BaseFurnace {
  owner?: string;
  renter?: string;
}

export interface Client {
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  location: string;
  post: string;
  phone: string;
}

export interface UserDetailResponse extends Client {
  type: UserType;
  furnaces: FurnaceItem[];
  company?: string;
  taxNumber?: string;
}

export interface BaseUser {
  firstName: string;
  lastName: string;
  email: string;
  type?: UserType;
  password?: string;
  createdAt?: string;
  phone?: string;
  firebaseToken?: string;
  updatedAt?: string;
}

export interface Repairman extends BaseUser {
  completedWorkOrders?: number;
  password?: string;
  roles: {
    isAdmin: boolean;
    hisOwnFurnaces: boolean;
    slovenianFurnaces: boolean;
  };
  regions?: string[];
  isCaretaker?: boolean;
}

export interface FurnaceDetailResponse extends BaseFurnace {
  owner?: UserDetailResponse;
  renter?: UserDetailResponse;
  repairman?: Repairman;
  alarms: Alarm[];
}

export interface AddFurnaceBody {
  type: string;
  id: string;
  manufactureDate: string;
  region: string;
  owner?: string;
  renter?: string;
  repairman?: string;
}

export interface UpdateFurnaceBody {
  region?: string;
  income?: number;
  repairman?: string | null;
  owner?: string;
  renter?: string;
}

export interface UpdateFurnaceResponse {
  region?: string;
  repairman?: Repairman;
  owner?: UserDetailResponse;
  renter?: UserDetailResponse;
}

export interface ListApiResponse {
  currentPage: number;
  limit: number;
  total: number;
  totalPages: number;
}

export interface FurnacesApiResponse extends ListApiResponse {
  furnaces: FurnaceItem[];
}

export interface UserListItem {
  email: string;
  name: string;
}

export interface RegularListItem extends UserListItem {
  furnaceId: string;
}

export interface InvestorListItem extends UserListItem {
  clientCount: string;
  company: string;
}

export interface UsersApiResponse extends ListApiResponse {
  users: RegularListItem[] | InvestorListItem[];
}

export interface FurnaceType {
  type: string;
  power: string;
  description: string;
}

export interface AddUserBody {
  email: string;
  firstName: string;
  lastName: string;
  company?: string;
  location?: string;
  address?: string;
  post?: string;
  phone?: string;
  taxNumber?: string;
  isCaretaker?: boolean;
  regions?: string[];
}

export interface RepairmenApiResponse {
  firstName: string;
  lastName: string;
  email: string;
  type: string;
  company?: string;
  regions?: string[];
  isCaretaker?: boolean;
}

export interface WorkorderApiResponse {
  data: Workorder[];
  total: number;
}

export interface AssignedAlarm extends BaseAlarm {
  receivedTime: number;
  receivedTimeStr: string;
  deviceId: string;
}

export interface StatusAlarm extends AssignedAlarm {
  status: string;
}

export interface AssignedAlarmApiResponse {
  alarms: AssignedAlarm[] | StatusAlarm[];
}

export interface RepairmanResponse extends ListApiResponse {
  users: Repairman[];
}

interface FinishAlarm extends BaseAlarm {
  date: string;
  deviceId: string;
  status: Method;
  createdAt: string;
  receivedTimeStr: string;
  fixedDate: string;
  updatedAt: string;
}

export interface FinishAlarmResponse {
  alarm: FinishAlarm;
}

export interface RepairMaterial {
  order: number;
  id: string;
  name: string;
  amount: number;
  units: string;
  guarantee: boolean;
}

export interface RepairAlarm {
  name: string;
  id: string;
}

export interface Repair {
  alarms: RepairAlarm[];
  createdAt: string;
  date: string;
  deviceId: string;
  fixedDate: string;
  id: string;
  note: string;
  receivedTimeStr: string;
  repairman: BaseUser;
  status: string;
  updatedAt: string;
  paymentMethod?: string;
  number?: string;
  type?: string;
  materials?: RepairMaterial[];
  workDescription?: string;
  isApproved: boolean;
  signatureCustomer?: string;
  signatureRepairman?: string;
  fuelType?: string;
  finalTestWithLaunch?: boolean;
}

export interface AssignedWorkordersResponse {
  limit: number;
  repairs: Repair[];
  total: number;
}

export interface MappedRepairWithUser extends Repair {
  furnaceType: string;
  userName: string;
  address: string;
  post?: string;
  city: string;
  phone: string;
}

export interface MappedWorkorderData {
  id: string;
  date: string;
  deviceId: string;
  repairman: string;
  fixedDate: string;
  updatedAt: string;
}

export interface MappedUnattendedWorkorderData {
  deviceId: string;
  alarmCount: number;
  contactPerson: string;
}

export interface PaginationParams {
  page?: string;
  search?: string;
  limit: number;
}

export interface ListItemTableData {
  [index: string]: string;
}

export interface CurrentAlarmsInterface extends ListItemTableData {
  furnaceId: string;
  alarmsCount: string;
  contactPerson: string;
}

export interface CurrentRepairsInterface extends ListItemTableData {
  number: string;
  date: string;
  furnaceId: string;
  repairman: string;
  fixedDate: string;
}
