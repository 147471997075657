import React from 'react';
import { FootnoteProps } from './Footnote.interface';
import { Box, makeStyles, Theme } from '@material-ui/core';
import footNoteStyles from './footNoteStyles';

const useStyles = makeStyles((theme: Theme) => footNoteStyles(theme));

const Footnote: React.FC<FootnoteProps> = ({ footnote, label }) => {
  const classes = useStyles();

  return (
    <Box>
      <label className={classes.label}>{label}</label>
      <div className={classes.textarea}>
        <span className={!footnote ? classes.label : undefined}>
          {footnote}
        </span>
      </div>
    </Box>
  );
};

export default Footnote;
