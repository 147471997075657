import { Theme } from '@material-ui/core';
import { colors } from '../../../theme';

export const alarmItemStyles = (theme: Theme) => {
  return {
    item: {
      padding: '.5rem 0',
      marginLeft: '.25rem',
    },
    header: {
      color: colors.lightGray,
      opacity: 0.6,
    },
  };
};
