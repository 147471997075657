import React, { FC, ChangeEvent } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { paginationStyles } from './paginationStyles';
import { PaginationProps } from './Pagination.interface';
import { default as MaterialPagination } from '@material-ui/lab/Pagination';
import { useHistory } from 'react-router';

const useClasses = makeStyles((theme: Theme) => paginationStyles(theme));

const Pagination: FC<PaginationProps> = ({
  currentPage,
  totalPages,
  pathname,
  search,
  disabled = false,
}: PaginationProps) => {
  const classes = useClasses();
  const history = useHistory();

  const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
    if (page !== currentPage) {
      history.push(
        `${pathname}?page=${page}${search ? `&search=${search}` : ''}`
      );
    }
  };

  return (
    <MaterialPagination
      page={currentPage}
      size="large"
      count={totalPages}
      shape="rounded"
      classes={{
        root: classes.pagination,
        ul: classes.page,
      }}
      disabled={disabled}
      onChange={handlePageChange}
    />
  );
};

export default Pagination;
