import { Reducer } from 'react';

import { ActionType, StateAction } from './types/actions';
import { AppState } from './types/state';

export const stateReducer: Reducer<AppState, StateAction> = (state, action) => {
  switch (action.type) {
    case ActionType.LOGIN:
      localStorage.setItem('jwt', action.jwtToken);
      return {
        ...state,
        isLoggedIn: true,
        authToken: action.jwtToken,
      };
    case ActionType.LOGOUT:
      localStorage.removeItem('jwt');
      return {
        ...state,
        isLoggedIn: false,
        authToken: undefined,
      };
    case ActionType.SET_TITLE:
      return {
        ...state,
        title: action.title,
        subTitle: action.subTitle,
      };
    case ActionType.START_LOADING:
      return {
        ...state,
        globalLoading: true,
      };
    case ActionType.END_LOADING:
      return {
        ...state,
        globalLoading: false,
      };
    case ActionType.SET_NOTIFICATION:
      return {
        ...state,
        notification: {
          message: action.message,
          severity: action.severity,
        },
      };
    default:
      return state;
  }
};
