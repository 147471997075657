import { RouteComponentProps } from 'react-router';

interface MatchParams {
  mode: string;
}

export type RepairmanDetailsProps = RouteComponentProps<MatchParams>;

export enum Mode {
  NEW = 'new',
  UPDATE = 'update',
}

export const repairmanRegions: string[] = [
  'Pomurska',
  'Podravska',
  'Koroška',
  'Savinjska',
  'Posavska',
  'Jugovzhodna Slovenija',
  'Zasavska',
  'Gorenjska',
  'Osrednje slovenska',
  'Goriška',
  'Primorsko - notranjska',
  'Obalno - kraška',
];
